@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-Italic.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-Italic.woff') format('woff'),
         url('../fonts/poppins/Poppins-Italic.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-Italic.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-MediumItalic.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-MediumItalic.woff') format('woff'),
         url('../fonts/poppins/Poppins-MediumItalic.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-MediumItalic.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-SemiBoldItalic.woff') format('woff'),
         url('../fonts/poppins/Poppins-SemiBoldItalic.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-SemiBoldItalic.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-BoldItalic.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-BoldItalic.woff') format('woff'),
         url('../fonts/poppins/Poppins-BoldItalic.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-BoldItalic.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-ExtraBoldItalic.woff') format('woff'),
         url('../fonts/poppins/Poppins-ExtraBoldItalic.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-ExtraBoldItalic.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-BlackItalic.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-BlackItalic.woff') format('woff'),
         url('../fonts/poppins/Poppins-BlackItalic.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-BlackItalic.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-Regular.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-Regular.woff') format('woff'),
         url('../fonts/poppins/Poppins-Regular.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-Regular.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-Medium.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-Medium.woff') format('woff'),
         url('../fonts/poppins/Poppins-Medium.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-Medium.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-SemiBold.woff') format('woff'),
         url('../fonts/poppins/Poppins-SemiBold.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-SemiBold.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-Bold.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-Bold.woff') format('woff'),
         url('../fonts/poppins/Poppins-Bold.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-Bold.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-ExtraBold.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-ExtraBold.woff') format('woff'),
         url('../fonts/poppins/Poppins-ExtraBold.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-ExtraBold.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/poppins/Poppins-Black.woff2') format('woff2'),
         url('../fonts/poppins/Poppins-Black.woff') format('woff'),
         url('../fonts/poppins/Poppins-Black.ttf') format('truetype'),
         url('../fonts/poppins/Poppins-Black.eot') format('embedded-opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}