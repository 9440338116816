@charset "UTF-8";

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Common
# Topbar
# Main Menu
# Slider
# Banner
# Feature
# About
# Tour Types
# Testimonials
# Propertis
# Popular Propertis
# Activity Details
# Reviews
# Call To Action (CTA)
# Blog
	## Blog Details
	## Comments
# Subscribe
# Category
# Breadcumb
# Agents
# Location
# FAQ
# Gallery
# Login, Register Modal
# Contact
# Footer
# Booking

--------------------------------------------------------------*/

/*** 

====================================================================
	Common
====================================================================

***/

.one-lines {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.three-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.seven-lines {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #dc212f;
    border-color: #dc212f;
}

.btn-primary:focus {
    background-color: #dc212f;
    border-color: #dc212f;
    box-shadow: 0 0 0 0.25rem rgb(220 33 47 / 50%);
}

.color-primary {
    color: var(--thm-primary);
}

.bg-primary-solid {
    background-color: var(--thm-primary) !important;
}

.bg-light-blue {
    background-color: #ecf0fa !important;
}

.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.text-justify {
    text-align: justify;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.rounded-8 {
    border-radius: 0.8rem !important;
}

:root {
    --thm-font: "Poppins", sans-serif;
    --thm-headline-font: "Poppins", sans-serif;
    --thm-base: #ffffff;
    --thm-base-rgb: 255, 255, 255;
    --thm-primary: #f42534;
    --thm-primary-rgb: 244, 37, 52;
    --thm-secondary: #000;
    --thm-secondary-rgb: 0, 0, 254;
    --thm-border: #ebf2f3;
}

@keyframes fadeFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeFromRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeFromUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeFromUp {
    animation-name: fadeFromUp;
}

.fadeFromRight {
    animation-name: fadeFromRight;
}

.fadeFromLeft {
    animation-name: fadeFromLeft;
}

/*global area*/
/*----------------------------------------------------*/
body {
    color: #555555;
    font-family: var(--thm-font);
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

.container {
    max-width: 1200px;
}

.ul-li ul {
    margin: 0;
    padding: 0;
}

.ul-li ul li {
    list-style: none;
    display: inline-block;
}

.ul-li-block ul {
    margin: 0;
    padding: 0;
}

.ul-li-block ul li {
    list-style: none;
    display: block;
}

div#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background-color: #fff;
    background: #fff url("../images/preloader.svg") no-repeat center center;
}

[data-background] {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

button {
    cursor: pointer;
}

.form-control:focus,
button:visited,
button.active,
button:hover,
button:focus,
input:visited,
input.active,
input:hover,
input:focus,
textarea:hover,
textarea:focus,
a:hover,
a:focus,
a:visited,
a.active,
select,
select:hover,
select:focus,
select:visited {
    outline: none;
    box-shadow: none;
    text-decoration: none;
    color: inherit;
}

.form-control {
    box-shadow: none;
}

.relative-position {
    position: relative;
}

.pera-content p {
    margin-bottom: 0;
}

@keyframes zooming {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.05, 1.05);
    }

    100% {
        transform: scale(1, 1);
    }
}

.zooming {
    animation: zooming 18s infinite both;
}

.headline h1,
.headline h2,
.headline h3,
.headline h4,
.headline h5,
.headline h6 {
    margin: 0;
    font-family: var(--thm-headline-font);
}

.section-title span {
    font-size: 18px;
    font-weight: 700;
    color: var(--thm-primary);
    position: relative;
}

.section-title span:before {
    left: 0;
}

.section-title span:after {
    right: 0;
}

.section-title h2,
.section-title h3 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.125;
    padding: 15px 0px 20px;
}

.section-title h3 {
    font-size: 24px;
}

.section-title.title-style-two {
    max-width: 590px;
    margin: 0 auto;
}

.section-title.title-style-two span {
    color: var(--thm-primary);
}

.section-title.title-style-two span:after,
.section-title.title-style-two span:before {
    background-color: var(--thm-primary);
}

.section-title.title-style-two .title-watermark {
    left: 0;
    right: 0;
    top: -60px;
    opacity: 0.04;
    color: #53536c;
    line-height: 1;
    font-size: 100px;
    font-weight: 900;
    margin-bottom: 0;
    text-align: center;
    position: absolute;
}

.scrollup {
    width: 45px;
    right: 15px;
    z-index: 5;
    height: 45px;
    bottom: 20px;
    display: none;
    position: fixed;
    line-height: 45px;
    background-color: var(--thm-primary);
}

.scrollup i {
    color: #fff;
}

.footer-widget .footer-logo-widget .footer-logo-btn,
.subscribe-widget-content .subscribe-form button,
.registration-content-2 .registration-text-2 a,
.cta-section-text a,
.activity-item-section .activity-item-btn,
.activity-more-btn,
.activity-type-section .activity-type-btn,
.banner-search-section button,
.banner-text .banner-btn a,
.slider-main-item .slider-main-text .slider-main-btn a {
    z-index: 1;
    overflow: hidden;
    position: relative;
}

.footer-widget .footer-logo-widget .footer-logo-btn:after,
.subscribe-widget-content .subscribe-form button:after,
.registration-content-2 .registration-text-2 a:after,
.cta-section-text a:after,
.activity-item-section .activity-item-btn:after,
.activity-more-btn:after,
.activity-type-section .activity-type-btn:after,
.banner-search-section button:after,
.banner-text .banner-btn a:after,
.slider-main-item .slider-main-text .slider-main-btn a:after {
    top: 0;
    width: 0;
    left: -40%;
    content: "";
    z-index: -1;
    height: 100%;
    position: absolute;
    transform: skew(50deg);
    transition-duration: 0.6s;
    transform-origin: top left;
    background-color: var(--thm-primary);
}

.footer-widget .footer-logo-widget .footer-logo-btn:hover:after,
.subscribe-widget-content .subscribe-form button:hover:after,
.registration-content-2 .registration-text-2 a:hover:after,
.cta-section-text a:hover:after,
.activity-item-section .activity-item-btn:hover:after,
.activity-more-btn:hover:after,
.activity-type-section .activity-type-btn:hover:after,
.banner-search-section button:hover:after,
.banner-text .banner-btn a:hover:after,
.slider-main-item .slider-main-text .slider-main-btn a:hover:after {
    height: 100%;
    width: 150%;
}

@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }

    80% {
        transform: translate(-40px, 72px) rotate(104deg) scale(1.1);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}

@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        transform: translate(111px, 72px) rotate(72deg);
    }

    60% {
        transform: translate(93px, 122px) rotate(108deg);
    }

    80% {
        transform: translate(-70px, 72px) rotate(124deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

/*---------------------------------------------------- */
/*Header area*/
/*----------------------------------------------------*/

/*
.header-main {
  padding-top: 15px;
}
*/

.header-top {
    padding: 10px 0;
    border-bottom: 1px dashed #dadada;
}

.header-top-menu {
    background-image: linear-gradient(45deg, #dc2a4c 0%, #f34237 100%) !important;
    padding: 5px 0;
}

.header-top-menu-social span {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    border-right: 1px solid #ffffff;
    padding-right: 10px;
    text-transform: uppercase;
}

.header-top-menu-social span:last-child {
    border-right: none;
}

.header-top-menu-social a {
    color: #fff;
}

.header-top-menu {
    padding: 5px 0;
}

.header-top-cta li {
    padding-left: 65px;
}

.header-top-cta li {
    position: relative;
}

.header-top-cta li:after {
    top: 15px;
    left: 30px;
    content: "";
    width: 1px;
    height: 33px;
    position: absolute;
    border: 1px dashed #dadada;
}

.header-top-cta li:nth-child(1):after {
    display: none;
}

.header-top-content .top-content-icon {
    top: 4px;
    margin-right: 10px;
    position: relative;
    padding: 10px;
    background: rgb(245 245 245 / 86%);
    /*#eff5f4*/
    border-radius: 24px;
}

.header-top-content .top-content-icon i {
    font-size: 24px;
    color: var(--thm-primary);
    line-height: 0;
    vertical-align: middle;
}

.header-top-content .top-content-text a {
    font-size: 13px;
}

.header-top-content .top-content-text h3 {
    color: #3d404c;
    font-size: 19px;
    font-weight: 700;
}

.header-menu-wrap {
    padding: 15px 0px 13px;
}

.main-nav {
    padding-top: 5px;
}

.main-nav .navbar-nav {
    display: inherit;
}

.main-nav li {
    margin-right: 50px;
}

.main-nav li a {
    color: #1f2230;
    font-weight: 600;
    padding-bottom: 19px;
    position: relative;
}

.main-nav li > a:before {
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    content: "";
    width: 0%;
    position: absolute;
    background-color: var(--thm-primary);
    transition: 0.3s all ease-in-out;
}

.main-nav li:hover a:before {
    width: 100%;
}

.main-nav .dropdown {
    position: relative;
}

.main-nav .dropdown:after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: var(--thm-primary);
    top: 0;
    right: -18px;
    content: "\f107";
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    transition: 0.3s all ease-in-out;
}

.main-nav .dropdown .dropdown-menu {
    top: 65px;
    left: 0;
    opacity: 0;
    z-index: 2;
    margin: 0px;
    padding: 0px;
    height: auto;
    width: 200px;
    border: none;
    display: block;
    border-radius: 0;
    visibility: hidden;
    position: absolute;
    background-color: #fff;
    transition: all 0.4s ease-in-out;
    border-bottom: 2px solid var(--thm-primary);
    box-shadow: 0 5px 10px 0 rgba(83, 82, 82, 0.1);
}

.main-nav .dropdown .dropdown-menu li {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid #e5e5e5;
}

.main-nav .dropdown .dropdown-menu li a {
    width: 100%;
    color: #343434;
    display: block;
    font-size: 14px;
    padding: 10px 25px;
    position: relative;
    transition: 0.3s all ease-in-out;
}

.main-nav .dropdown .dropdown-menu li a img.flag,
#m-main-nav .dropdown .dropdown-menu li a img.flag {
    width: 1.6rem;
    display: inline;
    object-fit: cover;
    margin-right: 5px;
}

.main-nav .dropdown .dropdown-menu li a:before {
    display: none;
}

.main-nav .dropdown .dropdown-menu li a:hover {
    background-color: var(--thm-primary);
    color: #fff;
}

.main-nav .dropdown .dropdown-menu li a:hover:after {
    transform: scale(1);
}

.main-nav .dropdown .dropdown-menu li:last-child {
    border-bottom: none;
}

.main-nav .dropdown.first-level:hover .dropdown-menu.first-level {
    top: 48px;
    opacity: 1 !important;
    visibility: visible !important;
}

.main-nav .dropdown.second-level:hover .dropdown-menu.second-level {
    top: 0px;
    opacity: 1;
    visibility: visible;
}

/* Third level menu */
.main-nav .dropdown .dropdown-menu li .dropdown-menu {
    top: 0;
    left: 100%;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
    visibility: hidden;
    opacity: 0;
    overflow: auto !important;
}

.main-nav .dropdown .dropdown-menu .dropdown:hover .dropdown-menu {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    visibility: visible;
    opacity: 1;
    overflow: auto !important;
}

.main-nav .dropdown .dropdown-menu .dropdown:hover .dropdown-menu li {
    padding: 0;
    margin: 0;
}

.main-nav .dropdown .dropdown-menu .dropdown:after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: var(--thm-primary);
    top: 8px;
    right: 10px;
    content: "\f105";
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    transition: 0.3s all ease-in-out;
}

.header-cart-login {
    padding-top: 20px;
}

.header-cart-login .top-cart-login {
    position: relative;
    display: inline-block;
}

.header-cart-login .top-submit {
    position: relative;
    display: inline-block;
}

.header-cart-login .top-submit a {
    display: inline-block;
    height: 40px;
    line-height: 38px;
    text-align: center;
    transition: 0.3s all ease-in-out;
    color: var(--thm-primary);
    padding: 0 20px;
    border: 1px dashed var(--thm-primary);
    border-radius: 24px;
    margin: 0px 15px;
}

.header-cart-login .top-submit a:hover {
    color: #fff;
    border: 1px solid var(--thm-primary);
    background: var(--thm-primary);
}

.header-cart-login .top-submit a i {
    vertical-align: middle;
    line-height: 1;
}

.header-cart-login .top-cart-login:after {
    top: 3px;
    right: -3px;
    width: 2px;
    content: "";
    height: 18px;
    position: absolute;
    background-color: #d9d9d9;
}

.header-cart-login .top-cart-login:last-child:after {
    display: none;
}

.header-cart-login .top-cart-login button {
    padding: 0;
    border: none;
    color: #5a5a5a;
    margin: 0px 15px;
    background-color: transparent;
    transition: 0.3s all ease-in-out;
}

.header-cart-login .top-cart-login button:hover {
    color: var(--thm-primary);
}

.header-social {
    margin-left: 40px;
}

.header-social li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transition: 0.3s all ease-in-out;
}

.header-social li a {
    width: 100%;
    display: block;
}

.header-social li:hover {
    color: #fff;
    background-color: var(--thm-primary);
}

.header-style-two {
    top: 0;
    z-index: 10;
    width: 100%;
    padding: 20px 0px;
    position: absolute;
    background-color: #fff;
}

.header-style-two .brand-logo {
    margin-right: 85px;
}

.header-style-two .main-nav {
    padding-top: 15px;
}

.header-style-two .main-nav .dropdown:after {
    color: #ff5520;
}

.header-style-two .main-nav li a:before {
    display: none;
}

.header-style-two .main-nav li {
    position: relative;
}

.header-style-two .main-nav .dropdown .dropdown-menu li:before {
    display: none;
}

.header-style-two .main-nav .dropdown .dropdown-menu li a:after {
    display: none;
}

.sticky-menu {
    top: -80px;
    width: 100%;
    z-index: 10;
    position: fixed;
    background: #fff;
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-timing-function: ease;
    transition: 0.3s all ease-in-out;
    box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
}

.header-style-two.sticky-menu {
    top: 0;
}

.header-style-one.sticky-menu {
    top: -110px;
}

.brand-logo img {
    max-height: 50px;
}

.header-style-three {
    top: 0;
    width: 100%;
    z-index: 10;
    padding-top: 30px;
    position: absolute;
}

.header-style-three .main-nav {
    padding-top: 15px;
}

.header-style-three .main-nav li a:before {
    background-color: #ff5520;
}

.header-style-three .main-nav .dropdown:after {
    color: #ff5520;
}

.header-style-three .main-nav .dropdown .dropdown-menu {
    border-bottom: 2px solid #ff5520;
}

.header-style-three .main-nav .dropdown .dropdown-menu li a:hover {
    background-color: #ff5520;
}

.header-style-three .header-cart-login {
    padding-top: 15px;
}

.header-style-three.sticky-menu {
    position: fixed;
    padding: 15px 0px;
    background-color: #fff;
}

.header-style-three.sticky-menu .main-nav,
.header-style-three.sticky-menu .header-cart-login {
    padding-top: 10px;
}

.header-style-four {
    padding-top: 0;
}

.header-style-four .header-top {
    background-color: #07173a;
    border-bottom: none;
}

.header-style-four .header-top .header-top-cta {
    padding: 7px 0px;
}

.header-style-four .header-top .header-top-social {
    padding: 6px 0px;
}

.header-style-four .header-top .header-top-cta span {
    color: #bcc4d7;
    font-size: 14px;
    margin-right: 35px;
}

.header-style-four .header-top .header-top-cta span i {
    color: #ff3614;
    margin-right: 10px;
}

.header-style-four .header-btn {
    float: right;
    padding: 0;
    margin-left: 25px;
}

.header-style-four .header-btn a {
    line-height: 40px;
    border-radius: 0;
}

.header-style-four .header-btn a {
    position: relative;
    display: inline-block;
    background: var(--thm-primary);
    padding: 0 20px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 500ms ease;
}

.header-style-four .header-top-social a {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #07173a;
    background-color: rgb(255 255 255 / 65%);
    font-size: 13px;
    border-radius: 24px;
    transition: 0.3s all ease-in-out;
}

.header-style-four .header-top-social a:hover {
    background-color: var(--thm-primary);
    color: #fff;
}

.header-style-four .header-menu-wrap {
    padding: 25px 0px;
}

.header-style-four .main-nav {
    padding-top: 15px;
}

.header-style-four .main-nav .dropdown {
    position: relative;
}

.header-style-four .main-nav .dropdown:after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: var(--thm-primary);
    top: 0;
    right: -18px;
    content: "\f107";
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    transition: 0.3s all ease-in-out;
}

.header-style-four .main-nav .dropdown .dropdown-menu {
    top: 65px;
    left: 0;
    opacity: 0;
    z-index: 2;
    margin: 0px;
    padding: 0px;
    height: auto;
    width: 200px;
    border: none;
    display: block;
    border-radius: 0;
    visibility: hidden;
    position: absolute;
    background-color: #fff;
    transition: all 0.4s ease-in-out;
    border-bottom: 2px solid #ff3614;
    box-shadow: 0 5px 10px 0 rgba(83, 82, 82, 0.1);
}

.header-style-four .main-nav .dropdown .dropdown-menu li {
    width: 100%;
    margin-left: 0;
    border-bottom: 1px solid #e5e5e5;
}

.header-style-four .main-nav .dropdown .dropdown-menu li a {
    width: 100%;
    color: #343434;
    display: block;
    font-size: 14px;
    padding: 10px 25px;
    position: relative;
    transition: 0.3s all ease-in-out;
}

.header-style-four .main-nav .dropdown .dropdown-menu li a:before {
    display: none;
}

.header-style-four .main-nav .dropdown .dropdown-menu li a:hover {
    background-color: #ff3614;
    color: #fff;
}

.header-style-four .main-nav .dropdown .dropdown-menu li a:hover:after {
    transform: scale(1);
}

.header-style-four .main-nav .dropdown .dropdown-menu li:last-child {
    border-bottom: none;
}

.header-style-four .main-nav .dropdown:hover .dropdown-menu {
    top: 48px;
    opacity: 1;
    visibility: visible;
}

.header-style-four .main-nav .dropdown .dropdown-menu li .dropdown-menu {
    top: 0;
    left: 100%;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
    visibility: hidden;
    opacity: 0;
    overflow: auto !important;
}

.header-style-four .main-nav .dropdown .dropdown-menu .dropdown:hover .dropdown-menu li {
    padding: 0;
    margin: 0;
}

.header-style-four .main-nav .navbar-nav {
    display: inherit;
}

.header-style-four .main-nav li {
    margin-right: 0;
    margin-left: 70px;
}

.header-style-four .main-nav li a {
    color: #1f2230;
    font-weight: 700;
    padding-bottom: 30px;
}

.header-search {
    margin-top: 15px;
}

.header-search button {
    border: none;
    color: #ff3614;
    background-color: transparent;
}

/*Search popup Page*/
/*-------------------*/

.search-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    -webkit-transition: -webkit-transform 0.7s ease;
    transition: -webkit-transform 0.7s ease;
    transition: transform 0.7s ease;
    transition: transform 0.7s ease, -webkit-transform 0.7s ease;
}

.search-popup.active {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.search-popup-overlay {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    opacity: 0.7;
    cursor: none;
}

.search-popup-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.search-popup-form {
    position: relative;
    z-index: 9991;
    width: 100%;
    padding: 15px;
    max-width: 600px;
    position: relative;
}

.search-popup-form input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 60px;
    color: #000;
    font-size: 18px;
    background-color: white;
    padding-left: 30px;
}

.search-popup-form input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000;
}

.search-popup-form input::-moz-placeholder {
    /* Firefox 19+ */
    color: #000;
}

.search-popup-form input:-ms-input-placeholder {
    /* IE 10+ */
    color: #000;
}

.search-popup-form input:-moz-placeholder {
    /* Firefox 18- */
    color: #000;
}

.search-popup-form button[type="submit"] {
    border: none;
    outline: none;
    width: 60px;
    height: 60px;
    color: #fff;
    background-color: var(--thm-primary);
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.search-popup-form button[type="submit"]:hover {
    background-color: var(--thm-primary);
    color: var(--thm-base);
}

/* Cursor Style */
.cursor {
    position: absolute;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    z-index: 1;
    -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    -webkit-transform: scale(1);
    transform: scale(1);
    visibility: hidden;
}

.cursor {
    visibility: visible;
}

.cursor.active {
    opacity: 0.5;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.cursor.hovered {
    opacity: 0.08;
}

.cursor-follower {
    position: absolute;
    background-color: RGBA(255, 255, 255, 0.3);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 1;
    -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    visibility: hidden;
}

.cursor-follower {
    visibility: visible;
}

.cursor-follower.active {
    opacity: 0.7;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.cursor-follower.hovered {
    opacity: 0.08;
}

.cursor-follower.close-cursor:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 2px;
    background: #fff;
    left: 48%;
    top: 12px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: inline-block;
}

.cursor-follower.close-cursor:after {
    position: absolute;
    content: "";
    height: 25px;
    width: 2px;
    background: #fff;
    right: 48%;
    top: 12px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*Mobile Menu area*/
/*----------------------------------------------------*/
.mobile-menu-content {
    top: 0px;
    bottom: 0;
    right: -350px;
    height: 100vh;
    z-index: 101;
    position: fixed;
    width: 310px;
    overflow-y: scroll;
    background-color: #000;
    padding: 50px 35px 35px 35px;
    box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.19);
    transition: all 0.5s ease-in;
}

.mobile-menu-content .mobile-main-nav {
    width: 100%;
}

.mobile-menu-content .mobile-main-nav .navbar-nav {
    width: 100%;
}

.mobile-menu-content .dropdown:after {
    display: none;
}

.mobile-menu-content .navbar-nav .dropdown-menu {
    position: static !important;
    transform: none !important;
}

.mobile-menu-content .mobile-main-nav .navbar-nav li {
    width: 100%;
    display: block;
    transition: 0.3s all ease-in-out;
}

.mobile-menu-content .mobile-main-nav .navbar-nav li a {
    padding: 0;
    width: 100%;
    color: #fff;
    display: block;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 5px 10px 0;
    text-transform: capitalize;
    border-bottom: 1px solid rgba(78, 78, 78, 0.41);
}

.mobile-menu-content .m-brand-logo {
    width: 160px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.mobile-menu-navbar.mobile-menu-on .mobile-menu-content {
    right: 0px;
    transition: all 0.7s ease-out;
}

.mobile-menu-overlay {
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    right: 0%;
    height: 120vh;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.9);
    transition: all 0.5s ease-in-out;
}

.mobile-menu-overlay-on {
    overflow: hidden;
}

.mobile-menu-navbar.mobile-menu-on .mobile-menu-overlay {
    opacity: 1;
    visibility: visible;
}

.mobile-menu-button {
    right: 0;
    top: 5px;
    z-index: 5;
    display: none;
    cursor: pointer;
    font-size: 30px;
    line-height: 40px;
    position: absolute;
    text-align: center;
    color: var(--thm-primary);
}

.mobile-menu .mobile-main-nav .navbar-nav li a:after {
    display: none;
}

.mobile-menu .mobile-main-nav .dropdown > .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.mobile-menu .mobile-menu-content .mobile-main-nav .navbar-nav .dropdown-menu {
    border: none;
    display: none;
    transition: none;
    box-shadow: none;
    padding: 5px 0px;
    width: 100%;
    background-color: transparent;
}

.mobile-menu .mobile-menu-content .mobile-main-nav .navbar-nav .dropdown-menu li {
    border: none;
    padding: 0 20px;
    line-height: 1;
}

.mobile-menu .mobile-menu-content .mobile-main-nav .navbar-nav .dropdown-menu li a:hover {
    color: var(--thm-primary);
    background-color: transparent;
}

.mobile-menu .dropdown {
    position: relative;
}

.mobile-menu .dropdown .dropdown-btn {
    color: #9397a7;
    position: absolute;
    top: 3px;
    right: 0;
    height: 30px;
    padding: 5px 10px;
}

.mobile-menu .dropdown .dropdown-btn.toggle-open {
    transform: rotate(90deg);
}

.mobile-menu .close-mobile-menu {
    color: #d60606;
    cursor: pointer;
    top: 15px;
    left: 15px;
    font-size: 20px;
    position: absolute;
}

.header-style-four.sticky-menu {
    top: -46px;
}

/*---------------------------------------------------- */
/*Slider area*/
/*----------------------------------------------------*/
.headline.scale b {
    opacity: 0;
}

.headline.scale i {
    display: inline-block;
    opacity: 0;
    transform: scale(0);
    font-style: normal;
}

.is-visible .headline.scale i {
    opacity: 1;
}

.headline.scale i.in {
    animation: scale-up 0.6s forwards;
}

.headline.scale i.out {
    animation: scale-down 0.6s forwards;
}

.no-csstransitions .headline.scale i {
    transform: scale(1);
    opacity: 0;
}

.no-csstransitions .headline.scale .is-visible i {
    opacity: 1;
}

@keyframes scale-up {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    60% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-down {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    60% {
        transform: scale(0);
        opacity: 0;
    }
}

.slider-main-img,
.slider-overlay {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
}

.slider-main-item {
    z-index: 1;
}

.slider-main-item .slider-overlay {
    background-color: black;
    background: -webkit-linear-gradient(0deg, #061a38 20%, rgba(0, 0, 0, 0) 100%);
}

.slider-main-item .img-zooming {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: scale(1);
}

.slider-main-item .slider-main-text {
    max-width: 620px;
    padding: 115px 0px 125px;
}

.slider-main-item .slider-main-text span {
    color: #677283;
    /*#ffffff8a*/
    display: block;
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 8px;
    opacity: 0;
    transform: translateY(50px);
    background: #fff;
    display: inline-block;
    padding: 4px 16px;
    border-radius: 24px;
    margin-bottom: 10px;
}

.slider-main-item .slider-main-text h1 {
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.2;
    padding-bottom: 20px;
    opacity: 0;
    transform: translateY(50px);
}

.slider-main-item .slider-main-text p {
    opacity: 0;
    color: #eee;
    font-size: 18px;
    padding-bottom: 30px;
    transform: translateY(50px);
}

.slider-main-item .slider-main-text .slider-main-btn {
    opacity: 0;
    transform: translateY(50px);
}

.slider-main-item .slider-main-text .slider-main-btn a {
    color: #fff;
    height: 55px;
    min-width: 150px;
    padding: 0 20px;
    float: left;
    font-weight: 700;
    line-height: 55px;
    border-radius: 50px;
    text-align: center;
    margin-right: 20px;
    display: inline-block;
}

.slider-main-item .slider-main-text .slider-main-btn a i {
    margin-right: 8px;
}

.slider-main-item .slider-main-text .slider-main-btn a:nth-child(1) {
    background-color: var(--thm-primary);
}

.slider-main-item .slider-main-text .slider-main-btn a:nth-child(2) {
    background-color: #000;
}

#main-slider .owl-nav {
    display: none;
}

#main-slider .owl-dots {
    left: 13%;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}

#main-slider .owl-dots .owl-dot {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    border-radius: 100%;
    margin-bottom: 10px;
    border: 2px solid #eaeaea;
    transition: 0.3s all ease-in-out;
}

#main-slider .owl-dots .owl-dot:before {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 9px;
    content: "";
    position: absolute;
    border-radius: 100%;
    background-color: #1f2230;
    transition: 0.3s all ease-in-out;
}

#main-slider .owl-dots .owl-dot.active {
    border: 3px solid #ccc;
}

#main-slider .owl-dots .owl-dot.active:before {
    background-color: var(--thm-primary);
}

#main-slider .owl-item.active .img-zooming {
    transform: scale(1.15);
    transition: all 7000ms linear;
}

#main-slider .owl-item.active .slider-main-text span {
    opacity: 1;
    transform: translateY(0);
    transition: all 1500ms ease;
    transition-delay: 900ms;
}

#main-slider .owl-item.active .slider-main-text h1 {
    opacity: 1;
    transform: translateY(0);
    transition: all 1500ms ease;
    transition-delay: 1200ms;
}

#main-slider .owl-item.active .slider-main-text p {
    opacity: 1;
    transform: translateY(0);
    transition: all 1500ms ease;
    transition-delay: 1500ms;
}

#main-slider .owl-item.active .slider-main-text .slider-main-btn {
    opacity: 1;
    transform: translateY(0);
    transition: all 1500ms ease;
    transition-delay: 1800ms;
}

#main-slider-2 .owl-nav .owl-next,
#main-slider-2 .owl-nav .owl-prev,
#activity-slider-2 .owl-nav .owl-next,
#activity-slider-2 .owl-nav .owl-prev {
    top: 50%;
    opacity: 0;
    width: 40px;
    color: #fff;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    position: absolute;
    text-align: center;
    visibility: hidden;
    background-color: #ff5520;
    transform: translateY(-50%);
    transition: 0.4s all ease-in-out;
}

#main-slider-2 .owl-nav .owl-next,
#activity-slider-2 .owl-nav .owl-next {
    right: 0px;
}

#main-slider-2 .owl-nav .owl-prev,
#activity-slider-2 .owl-nav .owl-prev {
    left: 0px;
}

#main-slider-2:hover .owl-next,
#activity-slider-2:hover .owl-next {
    opacity: 1;
    right: 30px;
    visibility: visible;
}

#main-slider-2:hover .owl-prev,
#activity-slider-2:hover .owl-prev {
    opacity: 1;
    left: 30px;
    visibility: visible;
}

.slider-activity-item-2 {
    padding: 150px 0px;
}

.slider-main-item-2 {
    padding: 250px 0px;
}

.slider-main-item-2 .slider-overlay {
    background-color: rgb(236 236 236 / 60%);
}

.slider-main-item-2 .slider-main-text {
    color: #fff;
    margin: 0 auto;
    max-width: 760px;
}

.slider-main-item-2 .slider-main-text span {
    font-size: 18px;
    font-weight: 700;
    color: var(--thm-primary);
    padding-bottom: 30px;
    display: inline-block;
    text-transform: uppercase;
}

.slider-main-item-2 .slider-main-text h1 {
    font-size: 60px;
    font-weight: 700;
    color: #000;
    text-transform: capitalize;
}

.slider-main-item-2 .slider-main-text p {
    padding-top: 20px;
    color: #4f4f4f;
    font-size: 18px;
    font-weight: 400;
}

.slider-main-item-2 .slider-main-text a {
    height: 60px;
    width: 180px;
    line-height: 60px;
    border-radius: 5px;
    font-weight: 700;
    display: inline-block;
    background-color: #ff5520;
    margin-top: 50px;
}

.banner-water-mark {
    left: 0;
    bottom: -40px;
    opacity: 0.05;
    z-index: -1;
    line-height: 1;
    color: #00003d;
    font-weight: 700;
    font-size: 265px;
    position: absolute;
    font-family: var(--thm-headline-font);
}

/*
.banner-section {
  padding-bottom: 132px;
}
*/
/*
.banner-content {
  background-color: #f6eeeb;
}
*/
.banner-content .banner-img {
    top: 15px;
    right: -30px;
    position: absolute;
}

.banner-content .b-deco-shape {
    bottom: -74px;
}

.banner-text {
    z-index: 1;
    max-width: 690px;
    overflow: hidden;
    padding: 50px 0px 120px;
}

.banner-text.banner-full-width {
    max-width: 100%;
}

.banner-text .banner-tag {
    padding-right: 30px;
    border-radius: 40px;
    display: inline-block;
    margin-bottom: 45px;
    background-color: #efeff0;
}

.banner-text .banner-tag span {
    color: #fff;
    font-weight: 500;
    padding: 12px 30px;
    margin-right: 10px;
    border-radius: 40px;
    display: inline-block;
    background-color: var(--thm-primary);
}

.banner-text .banner-tag p {
    color: #1f2230;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 0;
    display: inline-block;
}

.banner-small-text {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 30px;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 40px;
    display: inline-block;
    background-color: var(--thm-primary);
}

.banner-text h1 {
    color: #000;
    font-size: 58px;
    font-weight: 700;
    line-height: 1.2;
    padding-bottom: 35px;
}

.banner-text h1 span {
    color: var(--thm-primary);
}

.banner-text p {
    color: #4f4f4f;
    font-size: 20px;
    line-height: 1.6;
    padding-bottom: 40px;
}

.banner-text .banner-btn {
    float: left;
    margin-right: 35px;
}

.banner-text .banner-btn a {
    color: #fff;
    width: 160px;
    height: 55px;
    display: block;
    overflow: hidden;
    font-weight: 700;
    line-height: 55px;
    border-radius: 40px;
    background-color: #ff5520;
}

.banner-text .banner-btn a i {
    margin-left: 5px;
}

.banner-text .banner-play {
    float: left;
    width: 50px;
    height: 50px;
    margin-top: 5px;
    line-height: 50px;
    border-radius: 100%;
    transition: 0.3s all ease-in-out;
    background-color: var(--thm-primary);
}

.banner-text .banner-play a {
    color: #fff;
    width: 100%;
    display: block;
}

.banner-text .banner-play:hover {
    background-color: var(--thm-primary);
}

.banner-text .banner-play .video-btn-border {
    border: 2px solid rgba(0, 0, 0, 0.15);
}

.banner-search-section {
    top: -45px;
    z-index: 1;
    position: relative;
    /*margin-bottom: -175px;*/
}

.banner-search-section .banner-search-form {
    background-color: #fff;
    display: inline-block;
    border-radius: 10px;
    padding: 20px 20px 12px;
    box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.05);
}

.banner-search-section.style-two .banner-search-form {
    background-color: #eee;
}

.banner-search-section.style-two .search-form-field {
    width: auto;
    padding-right: 20px;
    text-align: left;
}

.banner-search-section .search-form-fields {
    float: left;
    margin-top: 5px;
}

.banner-search-section .search-form-field {
    display: inline-block;
    width: auto;
    padding-right: 20px;
}

.banner-search-section .search-form-field input {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #e8e8e8;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    line-height: 38px;
    outline: none;
    padding-left: 18px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
}

.banner-search-section .search-form-field .nice-select,
.banner-search-section .search-form-field input {
    color: #000;
    border: none;
    height: auto;
    font-size: 18px;
    font-weight: 500;
    line-height: auto;
    font-family: var(--thm-headline-font);
}

.banner-search-section .search-form-field input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000;
}

.banner-search-section .search-form-field input::-moz-placeholder {
    /* Firefox 19+ */
    color: #000;
}

.banner-search-section .search-form-field input:-ms-input-placeholder {
    /* IE 10+ */
    color: #000;
}

.banner-search-section .search-form-field input:-moz-placeholder {
    /* Firefox 18- */
    color: #000;
}

.banner-search-section .search-form-field i {
    position: absolute;
}

.banner-search-section .search-form-field .icon {
    padding: 12px;
    min-width: 40px;
    z-index: 1;
}

.banner-search-section .search-form-field .input-field {
    width: 100%;
    text-align: center;
    padding-left: 40px;
}

.banner-search-section.style-two .search-form-field .nice-select {
    font-weight: 500;
}

.banner-search-section .search-form-field .nice-select:after {
    top: 0px;
    right: 20px;
    height: auto;
    border: none;
    margin-top: 0;
    font-size: 12px;
    content: "";
    font-weight: 900;
    transform: rotate(0);
    font-family: "Font Awesome 5 Pro";
}

.banner-search-section button {
    color: #fff;
    height: 45px;
    width: 160px;
    border: none;
    font-weight: 700;
    border-radius: 5px;
    overflow: hidden;
    background-color: var(--thm-primary);
}

.banner-search-section button:after {
    background-color: #ff5520;
}

.banner-search-section button i {
    margin-right: 5px;
}

.select-apply-btn {
    margin-top: 20px;
}

.select-apply-btn p {
    color: #000;
    font-size: 15px;
}

.select-apply-btn p a {
    font-weight: 700;
    margin-left: 8px;
    color: var(--thm-primary);
    text-decoration: underline;
}

@keyframes animateBubble {
    0% {
        margin-top: 20%;
    }

    100% {
        margin-top: -30%;
    }
}

@keyframes sideWays {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 25px;
    }
}

.slider-main-item-3 {
    padding: 150px 0px 250px;
}

.slider-main-item-3 .slider-overlay {
    background-color: rgba(7, 23, 58, 0.65);
}

.slider-main-item-3 .slider-main-img,
.slider-main-item-3 .slider-overlay {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
}

.slider-main-item-3 .slider-main-text {
    margin: 0 auto;
    max-width: 810px;
    padding-bottom: 40px;
}

.slider-main-item-3 .slider-main-text span {
    color: #ff3614;
    font-size: 18px;
    font-weight: 700;
}

.slider-main-item-3 .slider-main-text h1 {
    color: #fff;
    font-weight: 700;
    font-size: 60px;
    padding: 10px 0px;
}

.slider-main-item-3 .slider-main-text p {
    color: #e5e5e5;
    margin: 0 auto;
    font-size: 18px;
    max-width: 450px;
    margin-bottom: 35px;
}

.slider-main-item-3 .slider-main-text a {
    color: #fff;
    height: 55px;
    padding: 0 20px;
    font-weight: 700;
    line-height: 55px;
    border-radius: 5px;
    display: inline-block;
    background-color: #ff3614;
    border: 2px solid #ff3614;
    transition: 0.3s all ease-in-out;
}

.slider-main-item-3 .slider-main-text a:hover {
    border: 2px solid #ff3614;
    background-color: transparent;
}

#main-slider-3 .owl-nav .owl-next,
#main-slider-3 .owl-nav .owl-prev {
    top: 50%;
    opacity: 0;
    width: 40px;
    color: #fff;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    position: absolute;
    text-align: center;
    visibility: hidden;
    background-color: var(--thm-primary);
    transform: translateY(-50%);
    transition: 0.4s all ease-in-out;
}

#main-slider-3 .owl-nav .owl-next {
    right: 0px;
}

#main-slider-3 .owl-nav .owl-prev {
    left: 0px;
}

#main-slider-3:hover .owl-next {
    opacity: 1;
    right: 30px;
    visibility: visible;
}

#main-slider-3:hover .owl-prev {
    opacity: 1;
    left: 30px;
    visibility: visible;
}

.slider-features {
    position: relative;
    margin-top: -245px;
    z-index: 1;
}

.slider-features .features-wrap {
    border-radius: 5px 5px 5px 5px;
    background: #1a2847;
    padding: 25px 40px 25px;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}

.slider-features .features-wrap .icon-part img {
    width: 50px;
    margin: 0 35px 8px 0;
}

.slider-features .features-wrap .icon-part i {
    font-size: 40px;
    width: 50px;
    margin: 0 35px 8px 0;
    color: #ff3614;
}

.slider-features .features-wrap .content-part .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
}

.slider-features .features-wrap .content-part .title .watermark {
    color: #ffffff;
}

.slider-features .features-wrap .content-part .dese {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
}

.slider-features .features-wrap:hover {
    transform: translateY(-10px);
}

/* Slider 4 */

.home-slider-container {
    position: relative;
    height: 100%;
    max-width: 450px;
    display: block;
    padding: 2rem;
    background: #fff;
    border-radius: 0.1rem;
    -webkit-box-shadow: 0 0 0 7px rgb(158 158 158 / 30%);
    box-shadow: 0 0 0 7px rgb(158 158 158 / 30%);
}

.home-slider .item:before {
    content: "";
    position: absolute;
    background: linear-gradient(to bottom, transparent 10%, #111c52);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
}

.home-slider-price {
    display: inline-block;
    margin-bottom: 10px;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}

.home-slider-container .listing-price-with-compare {
    position: relative;
    margin: 1rem 0;
    bottom: auto;
    padding: 0;
}

.home-slider-container .listing-price-with-compare h4 {
    font-weight: 700;
}

.home-slider-container .listing-price-with-compare .lpc-right a {
    background: #f8f7f7;
    color: #e06f84;
}

.home-slider-container .modern-pro-wrap {
    position: relative;
    top: 0;
    margin-bottom: 15px;
    left: auto;
}

.home-slider-container .modern-pro-wrap span {
    position: relative;
    padding: 8px 20px;
    z-index: 1;
    margin-right: 10px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    box-shadow: none;
    text-transform: capitalize;
}

.home-slider-container .modern-pro-wrap .activity-type {
    background: #323d50;
}

.home-slider-price i {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
}

.home-slider-title {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}

.home-slider-title a {
    font-size: 1.2rem;
}

.home-slider-title h3 {
    margin-bottom: 5px;
}

.home-slider-title span i {
    margin-right: 5px;
}

.simple-search-wrap {
    max-width: 580px;
    margin: 0;
}

.home-slider-desc .read-more {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background: #fd5332;
    border-radius: 4px;
}

.slide-activity-info {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
}

.slide-activity-info ul {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.slide-activity-info ul li {
    display: inline-block;
    flex: 0 0 50%;
    width: 50%;
    list-style: none;
    border-left: 1px solid #ddd;
    padding-left: 10px;
    font-size: 14px;
}

.slide-activity-info ul li:first-child {
    border-left: none;
    padding-left: 0;
}

.slide-activity-info ul li i {
    color: var(--thm-primary);
}

.slider-main-item-2 .slider-main-text .slider-title {
    color: var(--thm-primary);
    padding-bottom: 30px;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 8px 30px;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 40px;
    display: inline-block;
    background-color: var(--thm-primary);
}

/*---------------------------------------------------- */
/*Feature area*/
/*----------------------------------------------------*/
.feature-section {
    padding: 100px 0px;
    background: #fef9f3;
}

/*
.feature-innerbox {
  z-index: 1;
  padding: 35px 25px;
  transition: 0.3s all ease-in-out;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
}
.feature-innerbox:after {
  top: 0;
  left: 0;
  width: 0;
  z-index: -1;
  content: "";
  height: 100%;
  position: absolute;
  border-bottom: 4px solid var(--thm-primary);
  transition: all 500ms ease-in-out;
}
.feature-innerbox .feature-icon {
  line-height: 1;
  margin-right: 15px;
}
.feature-innerbox .feature-icon i {
  font-size: 35px;
  color: var(--thm-primary);
  width: 75px;
  height: 75px;
  line-height: 75px;
  border-radius: 100%;
  background-color: #f7f0f2;
  padding: 10px 15px;
  transition: 0.3s all ease-in-out;
}
.feature-innerbox .feature-text {
  overflow: hidden;
  border-left: 2px solid #f5f5f5;
  padding-left: 20px;
}
.feature-innerbox .feature-text h3 {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;
  transition: 0.3s all ease-in-out;
}
.feature-innerbox .feature-text p {
  font-size: 15px;
  color: #555555;
  transition: 0.3s all ease-in-out;
}
.feature-innerbox:hover {
  transform: translateY(-15px);
}
.feature-innerbox:hover:after {
  width: 100%;
}
*/

/** feature-section **/

.feature-section {
    position: relative;
}

.why-us-item .inner-box {
    position: relative;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.08);
}

.why-us-item .inner-box .image-box {
    position: relative;
    display: block;
    margin: 0;
    overflow: hidden;
}

.why-us-item .inner-box:hover .image-box::before {
    -webkit-animation: circle 0.95s;
    animation: circle 0.95s;
}

@-webkit-keyframes circle {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}

@keyframes circle {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}

.why-us-item .inner-box .image-box img {
    width: 100%;
    transform: scale(1);
    transition: 0.5s all ease-in-out;
}

.why-us-item .inner-box:hover .image-box img {
    transform: scale(1.05);
    transform: scale(1.2) rotate(5deg);
}

.why-us-item .inner-box .why-us-content {
    position: relative;
    display: block;
    padding: 60px 15px 25px 15px;
}

.why-us-item .inner-box .why-us-content .icon-box {
    position: absolute;
    left: 50%;
    top: -40px;
    padding: 0;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;
    line-height: 80px;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    font-size: 40px;
    color: var(--thm-primary);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.06);
}

.why-us-item .inner-box .why-us-content h4 {
    display: block;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #061a3a;
    text-align: center;
}

/*---------------------------------------------------- */
/*About area*/
/*----------------------------------------------------*/
.about-section {
    padding: 100px 0;
}

.about-img-text {
    position: relative;
}

.about-img-text .about-img {
    position: relative;
}

.about-img-text .about-img {
    border: 8px solid var(--thm-primary);
    background-color: #fff;
    padding: 5px;
    border-radius: 50%;
}

.about-img-text .about-img:before {
    content: "";
    position: absolute;
    bottom: -15px;
    left: -15px;
    border-left: 120px solid var(--thm-primary);
    border-top: 120px solid transparent;
    border-right: 100px solid transparent;
    z-index: -1;
}

.about-img-text .about-img {
    border-radius: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
}

.about-img-text .about-img img {
    width: 100%;
}

.about-media {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.about-img-text .about-media span {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    font-family: var(--thm-font);
    background-color: var(--thm-primary);
    padding: 20px;
    font-size: 20px;
    line-height: 1;
    color: #fff;
    border-radius: 24px;
}

.about-img-text .about-media span {
    top: 60px;
    right: -80px;
}

.about-img-text .about-media span b {
    font-size: 78px;
    margin-right: 20px;
    font-weight: 700;
    min-width: 100px;
}

.about-img-text .about-info {
    padding: 0;
}

.about-info {
    box-shadow: none;
    padding: 0;
    display: flex;
}

.about-info .about-info-title {
    align-items: flex-start;
    margin-bottom: 0;
}

.about-info .about-info-title .about-info-icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    margin-right: 20px;
}

.about-info .about-info-title .about-info-icon {
    width: 40px;
    height: 40px;
    background-color: var(--thm-primary);
}

.about-img-text .about-info .about-info-description h5 {
    font-size: 18px;
    font-weight: 700;
}

.about-info .about-info-description h5 {
    margin: 0 0 14px;
}

.about-info .about-info-description p {
    margin-bottom: 0;
}

.about-img-text-content .about-btn {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: var(--thm-primary);
    border: 0;
    color: #fff;
    font-size: 18px;
    padding: 12px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-radius: 30px;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
}

.btn-rounded .about-btn:before {
    border-radius: 6px;
}

.about-img-text .video-box {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
}

.about-video-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    color: var(--thm-primary);
    font-size: 20px;
    transition: 0.3s;
}

.about-img-text .video-box .about-video-btn {
    background-color: #232230;
    color: #fff;
    width: 60px;
    height: 60px;
}

.about-img-text .video-box span {
    font-weight: 500;
    line-height: 1.2;
    margin-left: 15px;
}

/*---------------------------------------------------- */
/*Tour Type area*/
/*----------------------------------------------------*/
.activity-type-section {
    padding: 95px 0px 100px;
    background-color: #f9f8f8;
}

.activity-type-section .activity-type-content {
    padding-top: 35px;
}

.activity-type-section .activity-type-btn {
    height: 50px;
    width: 170px;
    color: #fff;
    margin: 0 auto;
    margin-top: 40px;
    font-weight: 700;
    line-height: 50px;
    background-color: var(--thm-primary);
}

.activity-type-section .activity-type-btn:hover i {
    margin-left: 5px;
}

.activity-type-section .activity-type-btn:after {
    background-color: #000;
}

.activity-type-section .activity-type-btn a {
    width: 100%;
    display: block;
}

.activity-type-section .activity-type-btn a i {
    transition: 0.3s all ease-in-out;
}

.activity-type-innerbox {
    z-index: 1;
    overflow: hidden;
    border: 15px solid #fff;
    transition: 0.3s all ease-in-out;
    box-shadow: 0px 0px 43px 0px rgba(12, 12, 12, 0.16);
}

.activity-type-innerbox:after {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    content: "";
    height: 100%;
    position: absolute;
    transition: 0.3s all ease-in-out;
    background: linear-gradient(to bottom, rgb(21 37 65 / 0%) 0%, rgb(0 1 4 / 66%) 90%);
}

.activity-type-innerbox:before {
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    content: "";
    height: 100%;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background-color: #000;
    transition: 0.4s all ease-in-out;
}

.activity-type-innerbox .activity-type-text-icon {
    left: 45px;
    z-index: 3;
    opacity: 1;
    bottom: 45px;
    position: absolute;
    visibility: visible;
    transition: 0.3s all ease-in-out;
}

.activity-type-innerbox .activity-type-text-icon .activity-type-icon {
    line-height: 1;
    margin-right: 15px;
}

.activity-type-innerbox .activity-type-text-icon .activity-type-icon i {
    font-size: 50px;
    color: var(--thm-primary);
}

.activity-type-innerbox .activity-type-text-icon .activity-type-text {
    overflow: hidden;
}

.activity-type-innerbox .activity-type-text-icon .activity-type-text h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
}

.activity-type-innerbox .activity-type-text-icon .activity-type-text span {
    color: #fff;
}

.activity-type-innerbox .activity-type-hover-text {
    top: 40%;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform: translateY(-50%);
    transition: 0.4s all ease-in-out;
}

.activity-type-innerbox .activity-type-hover-text .activity-type-icon {
    line-height: 1;
    margin-bottom: 15px;
}

.activity-type-innerbox .activity-type-hover-text .activity-type-icon i {
    font-size: 50px;
    color: var(--thm-primary);
}

.activity-type-innerbox .activity-type-hover-text .activity-type-text {
    overflow: hidden;
}

.activity-type-innerbox .activity-type-hover-text .activity-type-text h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 5px;
}

.activity-type-innerbox .activity-type-hover-text .activity-type-text span {
    color: #fff;
}

.activity-type-innerbox .activity-type-more-btn {
    right: 40px;
    z-index: 2;
    bottom: 0px;
    opacity: 0;
    line-height: 1;
    visibility: hidden;
    position: absolute;
    transition: 0.3s all ease-in-out;
}

.activity-type-innerbox .activity-type-more-btn a {
    color: #fff;
    font-size: 30px;
}

.activity-type-innerbox:hover {
    border: 15px solid #000;
    transform: translateY(-25px);
}

.activity-type-innerbox:hover:before {
    opacity: 1;
    visibility: visible;
}

.activity-type-innerbox:hover .activity-type-text-icon {
    left: 20px;
    opacity: 0;
    line-height: 1;
    visibility: hidden;
}

.activity-type-innerbox:hover .activity-type-hover-text {
    top: 50%;
    opacity: 1;
    visibility: visible;
}

.activity-type-innerbox:hover .activity-type-more-btn {
    right: 10px;
    opacity: 1;
    visibility: visible;
}

#activity-type-slider .owl-item {
    opacity: 0;
    transition: opacity 500ms;
}

#activity-type-slider .owl-item.active {
    opacity: 1;
}

#activity-type-slider .owl-nav {
    right: 0;
    top: -110px;
    position: absolute;
}

#activity-type-slider .owl-nav .owl-next,
#activity-type-slider .owl-nav .owl-prev {
    width: 60px;
    height: 60px;
    cursor: pointer;
    line-height: 60px;
    margin-left: 12px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    border: 2px solid var(--thm-primary);
    transition: 0.3s all ease-in-out;
}

#activity-type-slider .owl-nav .owl-next:hover,
#activity-type-slider .owl-nav .owl-prev:hover {
    color: #fff;
    background-color: var(--thm-primary);
}

/*---------------------------------------------------- */
/*Testimonial area*/
/*----------------------------------------------------*/

.testimonial-content {
    padding-top: 15px;
}

.testimonial-content h3 {
    font-family: var(--thm-headline-font);
    font-size: 20px;
    font-weight: 700;
    color: #000;
    padding-bottom: 15px;
}

.testimonial-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    padding: 40px 30px 25px;
    transition: 0.3s all ease-in-out;
    box-shadow: 0px 12px 35px 0px rgb(12 12 12 / 6%);
}

.testimonial-box:before,
.testimonial-box:after {
    position: absolute;
    content: "";
    background: #fafafa;
}

.testimonial-box:before {
    height: 100%;
    width: 10px;
    right: 0;
    top: 0;
}

.testimonial-box:after {
    height: 200px;
    width: 200px;
    right: -80px;
    bottom: -80px;
    border-radius: 200px;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.testimonial-box .testimonial-content p,
.testimonial-box .testimonial-info p {
    margin: 0;
    font-size: 14px;
}

.testimonial-box .testimonial-info h5 {
    font-family: var(--thm-headline-font);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    color: #000;
}

.testimonial-box .testimonial-info {
    margin-top: 15px;
}

.testimonial-box .testimonial-info:after {
    content: " ";
    position: absolute;
    color: var(--thm-primary);
    right: 25px;
    bottom: 10px;
    z-index: 9;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.testimonial-thumb {
    width: 80px;
    border-radius: 50px;
    overflow: hidden;
    border: 3px solid #fff;
    box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    z-index: 9;
}

.testimonial-box:hover .testimonial-info:after {
    opacity: 0;
}

.testimonial-box .testimonial-content p {
    color: #303030;
    font-size: 16px;
}

.testimonial-box .testimonial-pic {
    background: #fff;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 20px;
    border-radius: 100%;
    overflow: hidden;
    bottom: 20px;
    z-index: 10;
    border: 3px solid #ff6525;
    transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    display: block;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.testimonial-box:hover .testimonial-pic {
    right: 20px;
    bottom: 20px;
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

.testimonial-box:hover:after {
    height: 200px;
    width: 200px;
    right: -110px;
    background-color: #fafafa;
    bottom: -110px;
}

.testimonial-box .testimonial-icon-2 i {
    color: var(--thm-primary);
    line-height: 1;
    font-size: 36px;
    transition: 0.3s all ease-in-out;
}

.testimonial-box .testimonial-icon-2 {
    top: 15px;
    right: 25px;
    position: absolute;
}

/*Testimonial section 2*/
/*-----------------------------*/
.testimonial-featured-section-2 {
    padding: 105px 0px 100px;
}

.testimonial-wrap-2 {
    margin-top: 40px;
}

.testimonial-wrap-2 .owl-nav {
    display: none;
}

.testimonial-wrap-2 .owl-item {
    opacity: 0;
    transition: opacity 500ms;
}

.testimonial-wrap-2 .owl-item.active {
    opacity: 1;
}

.testimonial-wrap-2 .owl-dots {
    margin-top: 35px;
    text-align: center;
}

.testimonial-wrap-2 .owl-dots .owl-dot {
    width: 28px;
    height: 5px;
    margin: 0px 5px;
    cursor: pointer;
    border-radius: 30%;
    display: inline-block;
    background-color: #ececed;
    transition: 0.3s all ease-in-out;
}

.testimonial-wrap-2 .owl-dots .owl-dot.active {
    width: 38px;
    background-color: var(--thm-primary);
}

/* Testimonial 3 */

.testimonial-featured-section {
    padding: 95px 0px 35px;
}

.owl-carousel.testimonial-featured-slide .owl-nav {
    left: -76%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    bottom: 0;
    position: absolute;
}

#testimonial-featured-slider .owl-nav .owl-next,
#testimonial-featured-slider .owl-nav .owl-prev {
    width: 60px;
    height: 60px;
    cursor: pointer;
    line-height: 60px;
    margin-left: 12px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    border: 2px solid var(--thm-primary);
    transition: 0.3s all ease-in-out;
}

.testimonials-section {
    background: #f5f5f5;
    padding: 100px 0px;
}

.testimonials-section .owl-dots {
    display: block;
    text-align: center;
    margin-top: 20px;
}

.testimonials-section .owl-dots .owl-dot {
    position: relative;
    margin: 0px 5px;
    width: 10px;
    height: 8px;
    border-radius: 2.5px;
    display: inline-block;
    border-radius: 4px;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
}

.testimonials-section .owl-dots .owl-dot:hover,
.testimonials-section .owl-dots .owl-dot.active {
    width: 18px;
}

.testimonials-section .owl-dots .owl-dot {
    background: #cccccc;
}

.testimonial-block .inner-box {
    position: relative;
    padding: 30px;
    margin-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    background-color: #fff;
    border-bottom: 4px solid var(--thm-primary);
    border-radius: 10px;
}

.testimonial-block .author-info {
    position: relative;
    margin-right: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.testimonial-block .thumb img {
    margin: 0 auto 20px;
    width: auto;
    border-radius: 50%;
}

.testimonial-block h4 {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
}

.testimonial-block .location {
    position: relative;
    font-size: 15px;
    font-weight: 500;
}

.testimonial-block .content {
    position: relative;
    margin-bottom: 30px;
}

.testimonial-block .rating-info {
    position: relative;
    display: inline-block;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 7px;
}

.testimonial-block h5 {
    position: relative;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}

.testimonial-block .rating {
    font-size: 12px;
    display: inline-block;
    margin-left: 15px;
    color: #ffce4a;
}

.testimonial-block .text {
    position: relative;
    margin: 0;
}

/** Testimonial Section 4 **/

.testimonial-section-2 {
    padding: 100px 0;
}

.testimonial-featured-section {
    position: relative;
}

.testimonial-featured-section .owl-carousel .owl-stage-outer .owl-item {
    opacity: 0;
}

.testimonial-featured-section .owl-carousel .owl-stage-outer .owl-item.active {
    opacity: 1;
}

.testimonial-block-one .inner-box {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 48px 30px 71px 30px;
    border-radius: 10px;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
}

.testimonial-block-one .inner-box.style-two {
    border: 2px solid #eee;
    border-radius: 24px;
    box-shadow: none;
}

.testimonial-block-one .inner-box .rating-box li {
    position: relative;
    display: inline-block;
    font-size: 18px;
    color: #ffab01;
}

.testimonial-block-one .inner-box .rating-box {
    position: relative;
    margin-bottom: 23px;
    padding: 0;
}

.testimonial-block-one .inner-box .text {
    position: relative;
    margin-bottom: 23px;
}

.testimonial-block-one .inner-box .text p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
}

.testimonial-block-one .inner-box .author-box h4 {
    position: relative;
    display: block;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 0px;
}

.testimonial-block-one .inner-box .author-box .designation {
    position: relative;
    display: block;
    font-size: 15px;
    font-weight: 500;
}

.testimonial-block-one .inner-box .author-box .thumb-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -47px;
    width: 95px;
    height: 95px;
    background: #ffffff;
    padding: 7px;
    border-radius: 50%;
    box-shadow: 0px 20px 40px 0px rgba(6, 26, 58, 0.2);
}

.testimonial-block-one .inner-box.style-two .author-box .thumb-box {
    background: #eee;
    box-shadow: none;
}

.testimonial-block-one .inner-box .author-box .thumb-box img {
    width: 100%;
    border-radius: 50%;
}

.testimonial-featured-section .owl-dots {
    position: relative;
    margin-top: 100px;
}

.testimonial-featured-section .owl-theme .owl-dots .owl-dot span {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #dcdede;
    border-radius: 50%;
    margin: 0px 8px;
    cursor: pointer;
    background: transparent;
    transition: all 500ms ease;
}

.testimonial-block-one .inner-box:after {
    content: "\f102";
    position: absolute;
    font-family: "Flaticon";
    font-size: 90px;
    font-weight: 500;
    color: #e6dcdc;
    right: 30px;
    bottom: 30px;
    z-index: 9;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}

/*---------------------------------------------------- */
/*Activity area*/
/*----------------------------------------------------*/
@keyframes toBottomFromTop {
    49% {
        transform: translateY(100%);
    }

    50% {
        opacity: 0;
        transform: translateY(-100%);
    }

    51% {
        opacity: 1;
    }
}

@keyframes toTopFromBottom {
    49% {
        transform: translateY(-100%);
    }

    50% {
        opacity: 0;
        transform: translateY(100%);
    }

    51% {
        opacity: 1;
    }
}

@keyframes toBottomFromTop {
    49% {
        transform: translateY(100%);
    }

    50% {
        opacity: 0;
        transform: translateY(-100%);
    }

    51% {
        opacity: 1;
    }
}

@keyframes toLeftFromRight {
    49% {
        transform: translateX(-100%);
    }

    50% {
        opacity: 0;
        transform: translateX(100%);
    }

    51% {
        opacity: 1;
    }
}

.activity-section {
    padding: 95px 0px;
    background-color: #f9f8f8;
}

.activity-more-btn {
    color: #fff;
    height: 50px;
    width: 150px;
    margin: 0 auto;
    margin-top: 15px;
    font-weight: 700;
    line-height: 50px;
    border-radius: 50px;
    background: var(--thm-primary);
}

.activity-more-btn:after {
    background-color: #000;
}

.activity-more-btn i {
    margin-left: 5px;
}

.activity-more-btn a {
    width: 100%;
    display: block;
}

.activity-top {
    margin-bottom: 25px;
}

.activity-top .activity-title-text {
    margin-top: 30px;
    padding-left: 40px;
}

.activity-top .activity-title-text span {
    color: #353535;
    font-size: 17px;
}

.activity-filter-content .activity-filter-area {
    margin: 0 -15px;
}

.activity-filter-content .activity-filter-buttons {
    margin-bottom: 10px;
}

.activity-filter-content .activity-filter-buttons button {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    padding: 5px 15px;
    border: 1px solid #e7e6e6;
    border-radius: 50px;
    background-color: #fff;
    transition: 0.3s all ease-in-out;
}

.activity-filter-content .activity-filter-buttons button.is-checked {
    color: #fff;
    border: 1px solid var(--thm-primary);
    background-color: var(--thm-primary);
}

.activity-filter-content .grid-size-25 {
    width: 25%;
}

.activity-filter-content .grid-sizer {
    width: 25%;
}

.activity-filter-content .grid-item {
    float: left;
    padding: 15px;
    display: inline-block;
}

.activity-card {
    padding: 18px;
    margin-bottom: 5px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 15px 25px 0px rgb(0 0 0 / 5%);
}

.activity-card.style-two {
    border: 2px dashed #ddd;
    box-shadow: none;
}

.activity-card .activity-img {
    overflow: hidden;
    border-radius: 10px;
}

.activity-card .activity-img img {
    border-radius: 10px;
    transition: 0.4s all ease-in-out;
    height: 160px;
    width: 100%;
    object-fit: cover;
}

.activity-card .activity-img .price-badge {
    top: 15px;
    color: #fff;
    right: 0;
    z-index: 1;
    font-weight: 700;
    padding: 2px 15px;
    position: absolute;
    background-color: var(--thm-primary);
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}

.activity-card .activity-img .activity-hover-icon {
    left: 0;
    top: 40%;
    right: 0;
    opacity: 0;
    z-index: 1;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    line-height: 40px;
    visibility: hidden;
    text-align: center;
    position: absolute;
    border-radius: 100%;
    display: inline-block;
    transform: translateY(-50%);
    transition: 0.3s all ease-in-out;
    background-color: rgba(255, 255, 255, 0.8);
}

.activity-card .activity-img .activity-hover-icon i {
    font-size: 14px;
}

.activity-card .activity-img .activity-hover-icon:hover {
    background-color: var(--thm-primary);
    color: #fff;
}

.activity-card .activity-text {
    margin-top: 10px;
}

.activity-card .activity-contract {
    color: #fff;
    right: 20px;
    bottom: 15px;
    z-index: 2;
    font-size: 14px;
    padding: 5px 10px;
    position: absolute;
    border-radius: 50px;
    background-color: var(--thm-primary);
}

.activity-card .activity-title h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 12px;
    transition: 0.3s all ease-in-out;
}

.activity-card.d-flex .activity-text {
    margin-top: 0;
    padding: 0 0 0 20px;
    max-width: 300px;
}

.activity-card .activity-text .activity-meta {
    margin-bottom: 8px;
}

.activity-card .activity-text .activity-meta a {
    font-size: 14px;
    margin-right: 5px;
}

.activity-card .activity-text .activity-meta a i {
    margin-right: 5px;
    font-weight: 600;
    color: var(--thm-primary);
}

.activity-card .activity-text .activity-meta a:last-child {
    margin-right: 0;
}

.activity-card .activity-text .activity-tilte-head {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
}

.activity-card .activity-text .activity-tilte-head h3 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.556;
    transition: 0.3s all ease-in-out;
}

.activity-card .activity-text .activity-tilte-head h3:hover {
    color: var(--thm-primary);
}

.activity-card .activity-text .activity-tilte-head ul {
    display: inline-block;
}

.activity-card .activity-text .activity-tilte-head ul li {
    color: #ffc601;
    font-size: 13px;
    margin-right: 2px;
}

.activity-card .activity-text .activity-tilte-head span {
    font-size: 14px;
}

.activity-card .activity-features .icon-text,
.activity-item-card .activity-features .icon-text,
.flight-deal-card .activity-features .icon-text,
.activity-block .activity-features .icon-text {
    font-size: 13px;
    font-weight: 500;
    color: #677283;
    padding: 4px 12px 4px 6px;
    background-color: #eef1f5;
    /*#e9edf2 #f1f4f7*/
    display: inline-block;
    transition: all 500ms ease;
    margin: 3px;
    border-radius: 24px;
}

.activity-card .activity-text .activity-agent-text h4 {
    color: #000;
    font-family: var(--thm-headline-font);
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    margin: 0;
}

.activity-card .activity-text .activity-agent-text span {
    font-size: 13px;
}

.activity-card .activity-text .activity-agent-img {
    width: 35px;
    height: 35px;
    overflow: hidden;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 100%;
}

.activity-card:hover .activity-img:before {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
}

.activity-card:hover .activity-img img {
    transform: scale(1.2);
}

.activity-card:hover .activity-img .activity-hover-icon {
    top: 50%;
    opacity: 1;
    visibility: visible;
}

.activity-agent .activity-agent-text,
.activity-item-agent .activity-agent-text {
    display: inline-grid;
}

.activity-card .activity-text .activity-btn {
    height: 30px;
    min-width: 30px;
    margin-top: 5px;
    line-height: 30px;
    text-align: center;
    background-color: #fff;
    transition: 0.3s all ease-in-out;
    border: 2px solid var(--thm-primary);
    border-radius: 50px;
}

.activity-card .activity-text .activity-btn.btn-2 {
    height: 35px;
    padding: 0 10px;
}

.activity-card .activity-text .activity-btn.btn-2 a {
    line-height: 32px;
}

.activity-card .activity-text .activity-btn.act-rent {
    height: 30px;
    width: auto;
    padding: 0 10px;
}

.activity-card .activity-text .activity-btn a {
    color: var(--thm-primary);
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 28px;
}

.activity-card .activity-text .activity-btn i {
    color: var(--thm-primary);
    animation: toBottomFromTop 0.3s forwards;
}

.activity-card .activity-text .activity-btn:hover a,
.activity-card .activity-text .activity-btn:hover i,
.activity-card .activity-text:hover .activity-btn i {
    color: #fff;
}

.activity-card .activity-text:hover .activity-btn {
    background-color: var(--thm-primary);
}

/*Popular activity  area*/
/*-----------------------------*/
.activity-item-section {
    padding: 105px 0px 50px;
    background-color: #f7f7f7;
}

.activity-item-section .activity-item {
    margin-top: 25px;
}

.activity-item-section .section-title .title-watermark {
    opacity: 0.1;
}

.activity-item-section .activity-item-btn {
    color: #fff;
    height: 50px;
    width: 170px;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 700;
    line-height: 50px;
    overflow: hidden;
    border-radius: 40px;
    background-color: var(--thm-primary);
}

.activity-item-section .activity-item-btn:after {
    background-color: #ff5520;
}

.activity-item-section .activity-item-btn i {
    margin-left: 5px;
}

.activity-item-section .activity-item-btn:hover i {
    animation: toBottomFromTop 0.3s forwards;
}

.activity-item-card {
    padding: 18px;
    border-radius: 10px;
    margin-bottom: 40px;
    background-color: #fff;
    transition: 0.3s all ease-in-out;
    box-shadow: 0px 6px 38px 0px rgba(0, 0, 0, 0.04);
}

.activity-item-card .act-pl {
    padding-left: 0 !important;
}

.activity-item-card .act-pr {
    padding-right: 0 !important;
}

.activity-item-card .activity-item-img {
    border-radius: 5px;
    overflow: hidden;
}

.activity-item-card .activity-item-img img {
    transition: 0.5s all ease-in-out;
}

.activity-item-card .activity-item-fee {
    margin: 5px 0px;
}

.activity-item-card .activity-item-fee .flight-class {
    background: #e6e6e6;
    /*#e80405*/
    font-size: 13px;
    padding: 0 6px;
    color: #000;
    margin: 2px 5px 3px 10px;
    vertical-align: text-bottom;
    border-radius: 5px;
}

.activity-item-card .activity-item-fee .flight-class.eco {
    background: #ece0eb;
}

.activity-item-card .activity-item-fee .flight-class.first {
    background: #f1cfcf;
}

.activity-item-card .activity-item-fee .flight-class.basic {
    background: #ffddcf;
}

.activity-item-card .activity-item-fee .activity-fee-amount {
    float: right;
}

.activity-item-card .activity-item-fee span {
    margin-top: 10px;
    display: inline-block;
}

.activity-item-card .activity-item-fee span,
.activity-item-card .activity-item-fee del {
    color: #555555;
    font-size: 18px;
    margin-right: 12px;
}

.activity-item-card .activity-item-fee strong {
    color: #000;
    font-size: 28px;
    font-weight: 700;
}

.activity-item-card .activity-item-title {
    margin-bottom: 15px;
    padding-top: 1px;
    padding-bottom: 15px;
    border-bottom: 2px solid #e9e9e9;
}

.activity-item-card .activity-item-title h3 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.444;
    transition: 0.3s all ease-in-out;
}

.activity-item-card .activity-item-title .activity-meta {
    padding-top: 5px;
}

.activity-item-card .activity-item-title .activity-meta a {
    font-size: 14px;
    margin-right: 10px;
}

.activity-item-card .activity-item-title .activity-meta.large a {
    margin-right: 15px;
}

.activity-item-card .activity-item-title .activity-meta a:last-child {
    margin-right: 0;
}

.activity-item-card .activity-item-title .activity-meta a i {
    margin-right: 5px;
    color: var(--thm-primary);
}

.activity-item-card .activity-item-rate ul {
    margin-right: 5px;
    display: inline-block;
}

.activity-item-card .activity-item-rate ul li {
    color: #ffc601;
    font-size: 13px;
}

.activity-item-card .activity-item-rate span {
    font-size: 14px;
}

.activity-item-card .activity-item-rate .p-rate-vote {
    margin-top: 3px;
}

.activity-item-card .activity-rating ul {
    display: inline-block;
    padding: 0;
    margin: 0;
}

.activity-item-card .activity-rating span {
    font-weight: 700;
}

.activity-item-card .activity-rating .review {
    font-size: 14px;
}

.activity-item-card .p-activity-btn {
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 24px;
    background-color: #fff;
    border: 2px solid var(--thm-primary);
    color: var(--thm-primary);
    transition: 0.3s all ease-in-out;
}

.activity-item-card .p-activity-btn {
    margin-top: 8px;
}

.activity-item-card .p-activity-btn a {
    color: var(--thm-primary);
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 27px;
}

.activity-item-card:hover .activity-item-agent .p-activity-btn a {
    color: #fff;
}

.activity-item-card:hover {
    box-shadow: -3.285px 20.741px 51px 0px rgba(0, 0, 47, 0.2);
}

.activity-item-card:hover .activity-item-title h3 {
    color: #ff5520;
}

.activity-item-card:hover .p-activity-btn {
    background-color: #ff5520;
}

.activity-item-card:hover .p-activity-btn i {
    animation: toBottomFromTop 0.3s forwards;
}

.activity-item-card:hover .activity-item-img img {
    transform: scale(1.1);
}

.activity-item-card .activity-item-text .activity-agent-img {
    width: 35px;
    height: 35px;
    overflow: hidden;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 100%;
}

.activity-item-card .activity-item-text .activity-agent-text h4 {
    color: #000;
    font-family: var(--thm-headline-font);
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    margin: 0;
}

.activity-item-card .activity-item-text .activity-agent-text span {
    font-size: 13px;
}

/*Activity Page  area*/
/*-----------------------------*/
.activity-page-activity-section {
    padding: 100px 0px;
    background-color: #f2f2f4;
}

.activity-page-activity-content-top {
    margin-bottom: 70px;
}

.activity-page-activity-content-top .activity-grid-top-bar {
    height: 60px;
    line-height: 60px;
    border-radius: 3px;
    padding-left: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 25px 0px rgb(12 12 12 / 2%);
}

.activity-page-activity-content-top .activity-grid-top-bar .activity-grid-showing-result .activity-grid-list {
    margin-right: 40px;
}

.activity-page-activity-content-top .activity-grid-top-bar .activity-grid-showing-result .activity-grid-list a {
    color: #010101;
    font-size: 17px;
    position: relative;
    padding-right: 45px;
    transition: 0.3s all ease-in-out;
}

.activity-page-activity-content-top .activity-grid-top-bar .activity-grid-showing-result .activity-grid-list a:hover {
    color: #ff5520;
}

.activity-page-activity-content-top .activity-grid-top-bar .activity-grid-showing-result .activity-grid-list a:after {
    top: 0px;
    right: 20px;
    content: "";
    width: 2px;
    height: 20px;
    position: absolute;
    background-color: #d7d8da;
}

.activity-page-activity-content-top .activity-grid-top-bar .activity-grid-showing-result .activity-grid-list a:last-child {
    padding-right: 0;
}

.activity-page-activity-content-top .activity-grid-top-bar .activity-grid-showing-result .activity-grid-list a:last-child:after {
    display: none;
}

.activity-page-activity-content-top .activity-grid-top-bar .activity-grid-showing-result .show-result-text {
    font-size: 17px;
}

.activity-page-activity-content-top .activity-grid-top-bar .activity-search input {
    width: 100%;
    height: 100%;
    border: none;
}

.activity-page-activity-content-top .activity-grid-top-bar .activity-search button {
    top: 0;
    right: 0;
    color: #fff;
    border: none;
    height: 100%;
    width: 160px;
    font-weight: 700;
    position: absolute;
    background-color: #f4511f;
}

.activity-page-activities-item .activity-item-card .activity-item-text h3 {
    font-size: 22px;
}

.activity-pagination {
    margin-top: 15px;
}

.activity-pagination ul li a {
    color: #000;
    width: 45px;
    height: 45px;
    font-size: 18px;
    font-weight: 700;
    margin: 0px 5px;
    line-height: 45px;
    display: inline-block;
    font-family: var(--thm-headline-font);
    background-color: #ffffff;
    transition: 0.4s all ease-in-out;
}

.activity-pagination ul li a:hover {
    color: #fff;
    background-color: var(--thm-primary);
}

/*Activity Details Page  area*/
/*-----------------------------*/
.activity-details-section {
    padding: 100px 0px;
    background-color: #f2f2f4;
}

.activity-details-tab-area {
    background-color: #fff;
}

.activity-details-tab-wrapper {
    padding: 40px 40px;
}

.activity-details-tab-btn {
    background-color: #f8f9fd !important;
}

.activity-details-tab-btn .nav-tabs .nav-item.show .nav-link,
.activity-details-tab-btn .nav-tabs .nav-link.active,
.activity-details-tab-btn .nav-tabs,
.activity-details-tab-btn .nav-tabs .nav-link {
    border: none;
    border-radius: 0;
}

.activity-details-tab-btn .nav-tabs .nav-link {
    padding: 16px 30px;
    display: flex;
    line-height: 30px;
}

.activity-details-tab-btn .nav-tabs .nav-link i {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    color: #ff5520;
    font-size: 15px;
    border-radius: 50%;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    margin-right: 5px;
}

.activity-details-tab-btn .nav-tabs .nav-link.active {
    background-color: #ff5520;
    color: #fff;
}

.activity-details-tab-btn ul li a {
    font-size: 15px;
    font-weight: 500;
    color: #616e96;
    text-transform: uppercase !important;
}

.c-overview-title {
    color: #000;
    font-family: var(--thm-headline-font);
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 20px;
}

.overview-feature-list {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
}

.activity-details-overview-feature {
    margin-top: 40px;
}

.overview-feature-content {
    width: 50%;
    float: left;
    margin-bottom: 50px;
    border: 2px solid #eee;
    padding: 20px;
}

.overview-feature-content:first-child {
    border-right: none;
}

.overview-feature-content .overview-feature-icon-title {
    margin-bottom: 10px;
    display: inline-block;
    width: 30%;
}

.overview-feature-content .overview-feature-icon-title i {
    height: 80px;
    width: 80px;
    background: #eff1f6;
    border-radius: 50%;
    font-size: 45px;
    color: var(--thm-primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.overview-feature-content .overview-feature-icon-title img,
.overview-feature-content .overview-feature-icon-title i {
    margin-right: 10px;
}

.overview-feature-content .overview-feature-text {
    overflow: hidden;
    width: 70%;
    float: right;
}

.overview-feature-content .overview-feature-text p {
    font-size: 15px;
    line-height: 1.6;
    max-width: 330px;
}

.overview-feature-content .overview-feature-text h3 {
    color: #1f2230;
    font-size: 15px;
    display: inline-block;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.overview-feature-content .overview-feature-text a {
    color: #000;
    text-decoration: underline;
}

.agent-detail-btn {
    height: 48px;
    width: 150px;
    line-height: 44px;
    border-radius: 30px;
    border: 2px solid var(--thm-primary);
}

.agent-detail-btn a {
    color: #000;
    width: 100%;
    display: block;
    font-weight: 700;
    border-radius: 30px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.agent-detail-btn a:hover {
    color: #fff;
    background: var(--thm-primary);
}

.activity-agent-content {
    margin-top: 20px;
}

.activity-agent-content .activity-agent-img {
    width: 120px;
    height: 120px;
    margin-right: 25px;
    border-radius: 100%;
}

.activity-agent-content .activity-agent-img img {
    border-radius: 100%;
}

.activity-agent-content .activity-agent-text {
    padding-top: 15px;
}

.activity-agent-content .activity-agent-text h3 {
    color: #000;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
}

.activity-agent-content .activity-agent-text .activity-agent-rate {
    margin-bottom: 10px;
}

.activity-agent-content .activity-agent-text .activity-agent-rate ul {
    margin-right: 10px;
}

.activity-agent-content .activity-agent-text .activity-agent-rate ul li {
    color: #ebc90a;
}

.activity-agent-content .activity-agent-text .activity-agent-rate ul li.unchecked {
    color: #d3d3d3;
}

.activity-agent-content .activity-agent-text .activity-agent-rate .agent-review-rate {
    color: #000;
    font-weight: 500;
    margin-right: 10px;
}

.activity-agent-content .activity-agent-text .activity-agent-rate .agent-total-review {
    font-size: 14px;
}

.activity-agent-content .activity-agent-text .agent-activity-details span {
    margin-right: 20px;
}

.activity-agent-content .activity-agent-text .agent-activity-details span i {
    color: var(--thm-primary);
    margin-right: 6px;
}

.agent-details {
    margin-top: 25px;
}

.agent-details p {
    margin-bottom: 30px;
}

.activity-user-comment {
    margin-top: 15px;
}

.activity-review-comment {
    margin-bottom: 40px;
}

.activity-review-comment .activity-review-img {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 20px;
}

.activity-review-comment .activity-review-text {
    overflow: hidden;
}

.activity-review-comment .activity-review-rating .activity-review-author {
    margin-bottom: 15px;
}

.activity-review-comment .activity-review-rating .activity-review-author h3 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 5px;
}

.activity-review-comment .activity-review-rating .activity-review-author span {
    font-size: 14px;
}

.activity-review-comment .activity-review-rating .activity-review-rate ul {
    margin-right: 10px;
}

.activity-review-comment .activity-review-rating .activity-review-rate ul li {
    color: #ebc90a;
}

.activity-review-comment .activity-review-rating .activity-review-rate ul li.unchecked {
    color: #d3d3d3;
}

.activity-review-comment .activity-review-rating .activity-review-rate .agent-review-rate {
    color: #000;
    font-weight: 500;
    margin-right: 10px;
}

.activity-review-comment .activity-review-rating .activity-review-rate .agent-total-review {
    font-size: 14px;
}

.activity-review-comment p {
    margin-bottom: 25px;
}

.activity-review-comment .reply-btn {
    color: #fff;
    height: 40px;
    width: 100px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 30px;
    line-height: 40px;
    display: inline-block;
    background-color: #ff5520;
}

.review-form {
    padding-top: 100px;
    position: relative;
}

.review-form:before {
    top: 0;
    left: -45px;
    height: 40px;
    width: 111%;
    content: "";
    position: absolute;
    background-color: #f2f2f4;
}

.review-form .activity-form-input {
    flex-wrap: wrap;
    margin: 0px -7px;
}

.review-form .activity-form-input-field {
    padding: 0px 7px;
}

.review-form input {
    width: 254px;
    height: 45px;
    border: none;
    padding-left: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #efeff0;
}

.review-form textarea {
    width: 100%;
    border: none;
    height: 165px;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #efeff0;
}

.review-form button {
    color: #fff;
    width: 170px;
    height: 50px;
    border: none;
    font-weight: 700;
    border-radius: 40px;
    background-color: var(--thm-primary);
}

.review-form button i {
    margin-left: 5px;
}

.rating-overview {
    display: flex;
    margin: 35px 0 25px;
    background: #fff;
    padding: 30px;
    padding-right: 20px;
    border-radius: 8px;
}

.rating-overview-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: #ffdead;
    width: 135px;
    margin-right: 20px;
    text-align: center;
    border-radius: 0;
    border-right: 1px solid #e8e8e8;
    padding-right: 27px;
}

.rating-overview-box-total {
    font-size: 58px;
    font-weight: 700;
    line-height: 1em;
    display: block;
    color: #2a2f3a;
}

.rating-overview-box-percent {
    font-size: 15px;
    margin-bottom: 0;
    display: block;
}

.rating-overview-box .star-rating {
    font-size: 16px;
    margin: 0;
    display: block;
    letter-spacing: -0.5px;
}

.rating-overview-box .star-rating i {
    color: #ff9500;
}

.rating-bars {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 0;
    padding: 0;
    margin-top: 0;
    align-items: center;
}

.rating-bars-item {
    margin: 10px 15px;
    width: calc(50% - 30px);
    justify-content: flex-end;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.rating-bars-name {
    font-weight: 600;
    color: #4c4f5a;
    display: block;
    font-size: 14px;
    line-height: 22px;
}

.rating-bars-inner {
    display: flex;
    width: 100%;
}

.rating-bars-rating {
    display: inline-block;
    vertical-align: baseline;
    background: #f4f5f7;
    width: 100%;
    height: 6px;
    margin-right: 10px;
    border-radius: 3px;
    overflow: hidden;
    background-color: #f4f5f7;
    align-self: center;
}

.rating-bars-rating-inner {
    height: 6px;
    display: block;
    background-color: #f4f5f7;
    position: relative;
    width: 0;
    transition: width 0.5s;
}

.rating-bars-item strong {
    color: #4c4f5a;
    font-size: 12px;
    font-weight: 600;
    align-self: center;
    background: #f4f5f7;
    border-radius: 50px;
    width: 46px;
    height: 22px;
    text-align: center;
    line-height: 21px;
}

.rating-bars-rating.good .rating-bars-rating-inner {
    background-color: #83ce36;
}

.rating-bars-rating.mid .rating-bars-rating-inner {
    background-color: #cea636;
}

.rating-bars-rating.high .rating-bars-rating-inner {
    background-color: #00ba74;
}

.rating-bars-rating.poor .rating-bars-rating-inner {
    background-color: #e6453c;
}

.review-box {
    margin: 35px 0 25px;
    background: #fff;
    padding: 30px;
    padding-right: 20px;
    border-radius: 8px;
}

.offline-wrap .offline-download-btn {
    color: #fff;
    font-weight: 700;
    margin-top: 30px;
    padding: 15px 30px;
    border-radius: 40px;
    display: inline-block;
    background-color: #ff5520;
}

.activity-widget-item {
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 40px;
    background-color: #fff;
}

.activity-widget-item .widget-title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.activity-widget-item .video-widget a {
    top: 50%;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    color: var(--thm-primary);
    position: absolute;
    line-height: 50px;
    border-radius: 100%;
    background-color: #fff;
    transform: translateY(-50%);
}

.activity-widget-item .activity-table-widget {
    padding: 35px 30px;
}

.activity-widget-item .activity-table-widget .activity-table-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eef1fe;
}

.activity-widget-item .activity-table-widget .activity-table-item:last-child {
    border-bottom: none;
}

.activity-widget-item .activity-table-widget .activity-table-item .table-title,
.activity-widget-item .activity-table-widget .activity-table-item .table-valur {
    color: #000;
}

.activity-widget-item .activity-table-widget .activity-table-item .table-title i,
.activity-widget-item .activity-table-widget .activity-table-item .table-valur i {
    color: #ff5520;
    margin-right: 7px;
    width: 18px;
}

.activity-widget-item .activity-table-widget .activity-price span {
    float: left;
    display: block;
    width: 100%;
    color: #000;
    font-size: 24px;
    font-weight: 700;
}

.activity-widget-item .activity-table-widget .activity-price span strong {
    color: #ff5520;
    float: right;
}

.activity-widget-item .activity-table-widget .activity-price a.activity-action-btn {
    background: #000;
    color: #fff;
    float: left;
}

.activity-widget-item .activity-table-widget .activity-price a.activity-action-btn.print {
    background: var(--cyan);
}

.activity-widget-item .activity-table-widget .activity-price a.activity-action-btn.phone {
    background: var(--blue);
}

.activity-widget-item .activity-table-widget .activity-price a.activity-action-btn.mail {
    background: var(--teal);
}

.activity-widget-item .activity-table-widget .activity-price a {
    color: #fff;
    height: 30px;
    padding: 0 10px;
    float: right;
    font-size: 14px;
    margin-right: 5px;
    margin-top: 10px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    background-color: #ff5520;
    text-transform: uppercase;
    border-radius: 4px;
}

.activity-widget-item .activity-table-widget .activity-price a:last-child {
    margin-right: 0px;
}

.activity-widget-item .activity-latest-widget {
    padding: 35px 30px;
}

.activity-widget-item .activity-latest-item {
    margin-top: 28px;
}

.activity-latest-img-text {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef1fe;
}

.activity-latest-img-text:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.activity-latest-img-text .activity-latest-img {
    width: 65px;
    height: 65px;
    overflow: hidden;
    margin-right: 15px;
    border-radius: 100%;
}

.activity-latest-img-text .activity-latest-text {
    padding-top: 3px;
    overflow: hidden;
}

.activity-latest-img-text .activity-latest-text a {
    color: #000;
    display: block;
    font-weight: 700;
    line-height: 1.375;
    padding-bottom: 3px;
}

.activity-latest-img-text .activity-latest-text span {
    display: block;
    color: #ff5520;
    font-weight: 700;
    float: right;
}

.activity-latest-img-text .activity-latest-text i {
    width: 50px;
    height: 23px;
    font-size: 10px;
    margin-top: 5px;
    font-weight: 400;
    font-style: normal;
    line-height: 23px;
    text-align: center;
    display: inline-block;
    background-color: #eee;
    border-radius: 12px;
    text-transform: uppercase;
}

.activity-section-3 {
    padding: 100px 0px;
}

.activity-section-3 .activity-item-card,
.activity-featured-section .activity-item-card {
    box-shadow: none;
    border: 2px solid #eee;
}

.activity-content-3 {
    margin-top: 40px;
}

/*---------------------------------------------------- */
/* CTA area*/
/*----------------------------------------------------*/

.cta-section {
    z-index: 1;
    padding: 65px 0px;
    background-color: var(--thm-primary);
}

.cta-section:after {
    top: 0;
    right: 0px;
    width: 54%;
    content: "";
    height: 100%;
    z-index: -1;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/assets/images/banners/london-uk-1.webp);
    filter: brightness(30%);
    -webkit-filter: brightness(30%);
}

.cta-section-left {
    max-width: 500px;
}

.cta-section-left .section-title span {
    color: #fff;
}

.cta-section-left p {
    color: #fafafa;
    padding-bottom: 20px;
}

.cta-section-left .section-title span:before,
.cta-section-left .section-title span:after {
    background-color: #fff;
}

.cta-section-left .section-title h2 {
    color: #fff;
}

.cta-section-right {
    padding-top: 10px;
    padding-left: 90px;
}

.cta-section-right h3 {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 1.333;
}

.cta-section-right p {
    color: #cecece;
    padding-bottom: 40px;
}

.cta-section-right a,
.cta-section-left a {
    color: #fff;
    width: 150px;
    background-color: var(--thm-primary);
    border: 2px solid var(--thm-primary);
    display: block;
    border-radius: 50px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    transition: 0.3s all ease-in-out;
}

.cta-section-right a:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    transition: 0.3s all ease-in-out;
}

.cta-section-right a:last-child:hover {
    color: #fff;
}

.cta-section-right a:after {
    background-color: #fff;
}

.cta-section-right a:hover {
    color: #000;
}

.cta-section-right a {
    display: inline-block;
}

.cta-section-right a:last-child {
    background-color: transparent;
    margin-left: 10px;
}

.cta-section-3 {
    overflow: visible;
}

/* CTA Widget */

.cta-widget-content {
    text-align: center;
    padding: 55px 30px 45px 30px;
    position: relative;
}

.cta-widget-title,
.cta-widget-button,
.cta-widget-number {
    position: relative;
    z-index: 1;
}

.cta-widget-title {
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    text-transform: uppercase;
}

.cta-widget-button {
    height: 100px;
    width: 100px;
    background: var(--thm-primary);
    border-radius: 50%;
    font-size: 40px;
    line-height: 105px;
    display: inline-block;
    color: #ffffff;
}

.cta-widget-button:hover,
.cta-widget-number:hover {
    color: var(--thm-primary);
}

.cta-widget-number {
    font-size: 28px;
    font-weight: 700;
    display: inline-block;
}

.cta-widget-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #f1f1f1;
    left: 0;
    top: 0;
    opacity: 0.87;
}

/*---------------------------------------------------- */
/*CTA area*/
/*----------------------------------------------------*/
.cta-section-4 {
    overflow: visible;
}

.cta-content-4 {
    margin: 0 auto;
    max-width: 975px;
}

.cta-contact-4 {
    width: 50%;
    padding: 40px;
    background-color: #eaecf0;
}

.cta-contact-4 .cta-img-4 {
    bottom: 0;
    left: 10px;
    position: absolute;
}

.cta-contact-4 .cta-contact-text {
    float: right;
    max-width: 260px;
}

.cta-contact-4 .cta-contact-text h3 {
    color: #07173a;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.364;
}

.cta-contact-4 .cta-contact-text a {
    color: #fff;
    width: 130px;
    height: 45px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    line-height: 45px;
    border-radius: 5px;
    margin-top: 20px;
    display: inline-block;
    background-color: #07173a;
}

.cta-register-4 {
    width: 50%;
}

.cta-register-4 .cta-register-text {
    margin: 0 auto;
    max-width: 325px;
    padding-top: 65px;
}

.cta-register-4 .cta-register-text h3 {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.385;
    padding-bottom: 20px;
}

.cta-register-4 .cta-register-text a {
    font-weight: 700;
    color: #ff3614;
    font-size: 15px;
}

/* Blog area */
/*----------------------------------------------------*/
.blog-section {
    padding: 100px 0px;
}

.blog-section .blog-slide-content {
    margin-top: 35px;
}

.blog-content {
    padding: 25px;
    background-color: #fff;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
    box-shadow: 0px 14px 29px 0px rgba(12, 12, 12, 0.08);
}

.blog-content .blog-img img {
    transition: 0.5s all ease-in-out;
}

.blog-content .blog-category {
    color: #fff;
    left: 20px;
    top: 15px;
    z-index: 2;
    font-size: 14px;
    padding: 5px 10px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
}

.blog-content .blog-text {
    margin-top: 22px;
}

.blog-content .blog-text .blog-meta {
    margin-bottom: 10px;
}

.blog-content .blog-text .blog-meta a {
    font-weight: 500;
    font-size: 14px;
    margin-right: 16px;
    text-transform: uppercase;
    font-family: var(--thm-headline-font);
}

.blog-content .blog-text .blog-meta a i {
    color: #777777;
    color: var(--thm-primary);
}

.blog-content .blog-title h3 {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 12px;
    transition: 0.3s all ease-in-out;
}

.blog-content .blog-title p {
    color: #353535;
    padding-bottom: 20px;
}

.blog-content:hover {
    transform: translateY(-20px);
    box-shadow: 0px 29px 29px 0px rgba(12, 12, 12, 0.26);
}

.blog-content:hover h3 {
    color: var(--thm-primary);
}

.blog-content:hover .blog-img:before {
    height: 100%;
}

#blog-slider .owl-item {
    opacity: 0;
    transition: opacity 500ms;
}

#blog-slider .owl-item.active {
    opacity: 1;
}

#blog-slider .owl-nav {
    right: 0;
    top: -110px;
    position: absolute;
}

#blog-slider .owl-nav .owl-next,
#blog-slider .owl-nav .owl-prev {
    width: 60px;
    height: 60px;
    cursor: pointer;
    line-height: 60px;
    margin-left: 12px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    border: 2px solid var(--thm-primary);
    transition: 0.3s all ease-in-out;
}

#blog-slider .owl-nav .owl-next:hover,
#blog-slider .owl-nav .owl-prev:hover {
    color: #fff;
    background-color: var(--thm-primary);
}

/* Blog section 2*/
/*-----------------------------*/
.blog-section-2 {
    padding: 105px 0px 60px;
    background-color: #f7f7f7;
}

.blog-section-2 .section-title .title-watermark {
    opacity: 0.1;
}

.blog-section-2 .blog-content-2 {
    margin-top: 25px;
}

.blog-content-2 {
    margin: 0 auto;
    max-width: 370px;
    margin-bottom: 40px;
    background-color: #fff;
    transition: 0.3s all ease-in-out;
}

.blog-content-2 .blog-img-date-2 .blog-img-2 {
    overflow: hidden;
}

.blog-content-2 .blog-img-date-2 .blog-img-2 img {
    transition: 0.4s all ease-in-out;
}

.blog-content-2 .blog-img-date-2 .blog-img-2:after {
    top: 0;
    left: 0;
    width: 100%;
    content: "";
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);
}

.blog-content-2 .blog-img-date-2 .blog-date-2 {
    right: 25px;
    width: 80px;
    height: 80px;
    bottom: -30px;
    padding-top: 13px;
    border-radius: 3px;
    position: absolute;
    background-color: var(--thm-primary);
}

.blog-content-2 .blog-img-date-2 .blog-author {
    left: 30px;
    width: 80px;
    height: 80px;
    bottom: -30px;
    padding: 5px;
    border-radius: 50%;
    position: absolute;
    background-color: var(--thm-base);
    box-shadow: 0px 12px 35px 0px rgb(12 12 12 / 6%);
}

.blog-content-2 .blog-img-date-2 .blog-author img {
    border-radius: 50%;
}

.blog-content-2 .blog-img-date-2 .blog-date-2 a {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.1;
    display: inline-block;
    font-family: var(--thm-headline-font);
}

.blog-content-2 .blog-img-date-2 .blog-date-2 a span {
    display: block;
    font-size: 16px;
    font-weight: 400;
}

.blog-content-2 .blog-text-2 {
    padding: 25px 35px 35px;
    box-shadow: 0px 12px 35px 0px rgba(12, 12, 12, 0.06);
}

.blog-content-2 .blog-title-text-2 h3 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    position: relative;
    padding: 10px 0px 15px;
    transition: 0.3s all ease-in-out;
}

.blog-content-2 .blog-title-text-2 h3:hover {
    color: var(--thm-primary);
}

.blog-content-2 .blog-title-text-2 p {
    padding-bottom: 14px;
}

.blog-content-2 .blog-title-text-2 .blog-more {
    color: #000;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    transition: 0.3s all ease-in-out;
    position: relative;
}

.blog-content-2 .blog-title-text-2 .blog-more:after {
    left: 0;
    bottom: 0;
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    transition: 0.3s all ease-in-out;
    background-color: var(--thm-primary);
}

.blog-content-2 .blog-title-text-2 .blog-more:hover {
    color: var(--thm-primary);
}

.blog-content-2 .blog-title-text-2 .blog-more:hover:after {
    width: 100%;
}

.blog-content-2:hover {
    box-shadow: 0px 43px 35px 0px rgba(12, 12, 12, 0.1);
}

.blog-content-2:hover .blog-img-2 img {
    transform: scale(1.2) rotate(5deg);
}

.blog-meta-2 {
    display: inline-block;
    margin-bottom: 5px;
    position: relative;
    margin-left: 10px;
    transition: 0.3s all ease-in-out;
}

.blog-meta-2 a {
    font-size: 14px;
    margin-right: 25px;
    position: relative;
    color: var(--thm-primary);
    font-family: var(--thm-headline-font);
}

.blog-meta-2 a:after {
    top: -3px;
    width: 2px;
    content: "";
    right: -15px;
    height: 20px;
    position: absolute;
    background-color: #cdcdcd;
}

.blog-meta-2 a:last-child:after {
    display: none;
}

.blog-details-thumb-text.headline .blog-meta-2,
.blog-details-thumb-section .blog-meta-2 {
    border: none;
}

.blog-details-thumb-text.headline .blog-meta-2 a,
.blog-details-thumb-section .blog-meta-2 a {
    color: #fff;
}

.blog-content-2 .category {
    display: inline-block;
}

.blog-content-2 .category a {
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    padding: 1px 21px;
    text-align: center;
    border-radius: 25px;
    margin-bottom: 14px;
}

.blog-content-2 .category a {
    background: #ff7c5b;
}

#blog-slider-2 .owl-nav {
    display: none;
}

#blog-slider-2 .owl-item {
    opacity: 0;
    transition: opacity 500ms;
}

#blog-slider-2 .owl-item.active {
    opacity: 1;
}

#blog-slider-2 .owl-dots {
    margin-top: 35px;
    text-align: center;
}

#blog-slider-2 .owl-dots .owl-dot {
    width: 28px;
    height: 5px;
    margin: 0px 5px;
    cursor: pointer;
    border-radius: 30%;
    display: inline-block;
    background-color: #ececed;
    transition: 0.3s all ease-in-out;
}

#blog-slider-2 .owl-dots .owl-dot.active {
    width: 38px;
    background-color: var(--thm-primary);
}

.blog-feed-section,
.blog-feed-list-section {
    padding: 60px 0px;
    background-color: #f2f2f4;
}

.blog-feed-list-img-text .blog-content-2 .blog-img-date-2 .blog-img-2 {
    border-radius: 5px;
}

.blog-feed-list-img-text .blog-content-2 .blog-img-date-2 .blog-date-2 {
    right: 40px;
}

.blog-feed-list-img-text .blog-content-2 {
    max-width: 100%;
}

.blog-feed-list-img-text .blog-content-2 .blog-title-text-2 h3 {
    border: none;
    font-size: 40px;
    margin-bottom: 8px;
}

.blog-feed-list-img-text .blog-content-2 .blog-title-text-2 h3:before {
    display: none;
}

.blog-feed-list-img-text .blog-content-2 .blog-title-text-2 p {
    padding-bottom: 30px;
}

.blog-feed-list-img-text .blog-content-2 .blog-text-2 {
    padding: 48px 40px 40px;
}

.blog-feed-list-img-text .blog-content-2 .blog-more {
    border-radius: 4px;
    padding: 14px 28px 12px;
    border: 2px solid #ff5520;
}

.blog-feed-list-img-text .blog-content-2 .blog-more:after {
    display: none;
}

.blog-feed-list-img-text .blog-content-2 .blog-more:hover {
    color: #fff;
    background-color: #ff5520;
}

.blog-list-share .blog-share-slug {
    color: #000;
    font-weight: 700;
    margin-right: 10px;
}

.blog-list-share a {
    width: 42px;
    color: #fff;
    height: 42px;
    line-height: 42px;
    margin-left: 4px;
    text-align: center;
    display: inline-block;
    transition: 0.3s all ease-in-out;
}

.blog-list-share a:hover {
    transform: scale(1.1);
}

.blog-list-share a:nth-child(2) {
    background-color: #3b5998;
}

.blog-list-share a:nth-child(3) {
    background-color: #1da1f2;
}

.blog-list-share a:nth-child(4) {
    background-color: #0077b5;
}

.blog-widget-item {
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 40px;
    background-color: #fff;
}

.blog-widget-item .widget-title {
    color: #1f2230;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 14px;
    font-family: var(--thm-headline-font);
}

.blog-widget-item .search-widget input {
    width: 100%;
    height: 48px;
    border: none;
    padding-left: 15px;
    border-radius: 4px;
    background-color: #fff;
}

.blog-widget-item .search-widget button {
    top: 0;
    right: 0;
    width: 48px;
    color: #fff;
    border: none;
    height: 48px;
    position: absolute;
    background-color: var(--thm-primary);
}

.blog-widget-item .category-widget {
    padding: 35px 30px;
}

.blog-widget-item .category-widget li {
    margin-bottom: 5px;
}

.blog-widget-item .category-widget li a {
    color: #222;
    font-weight: 500;
    padding-left: 15px;
    position: relative;
    transition: 0.4s all ease-in-out;
}

.blog-widget-item .category-widget li a:after {
    left: 0;
    top: -1px;
    color: var(--thm-primary);
    font-size: 14px;
    content: "";
    position: absolute;
    font-weight: 900;
    font-family: "Font Awesome 5 Pro";
}

.blog-widget-item .category-widget li a:hover {
    margin-left: 15px;
}

.blog-widget-item .category-widget li:last-child {
    margin-bottom: 0;
}

.blog-widget-item .recent-blog-widget {
    padding: 35px 30px;
}

.blog-widget-item .recent-blog-widget .recent-blog-content {
    width: 100%;
    margin-bottom: 14px;
    padding-bottom: 14px;
    display: inline-block;
}

.blog-widget-item .recent-blog-widget .recent-blog-content:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.blog-widget-item .recent-blog-widget .recent-blog-content .recent-blog-img {
    width: 65px;
    height: 65px;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 4px;
}

.blog-widget-item .recent-blog-widget .recent-blog-content .recent-blog-text {
    overflow: hidden;
}

.blog-widget-item .recent-blog-widget .recent-blog-content .recent-blog-text a:hover {
    color: var(--thm-primary);
    transition: 0.3s;
}

.blog-widget-item .recent-blog-widget .recent-blog-content .recent-blog-text span {
    color: #888888;
    font-size: 14px;
    font-weight: 400;
    display: block;
    padding-top: 5px;
}

.blog-widget-item .recent-blog-widget .recent-blog-content .recent-blog-text h3 {
    color: #000;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 700;
}

.blog-widget-item .blog-category-widget {
    padding: 35px 30px;
}

.blog-widget-item .blog-category-widget .blog-category-tag a {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 10px 12px;
    border-radius: 30px;
    display: inline-block;
    border: 1px solid #e5e5e5;
}

.blog-widget-item .blog-category-widget .blog-category-tag a:hover {
    background: var(--thm-primary);
    border-color: var(--thm-primary);
    color: #fff;
    transition: 0.3s all ease-in-out;
}

.blog-details-section {
    padding: 70px 0px;
    background-color: #f2f2f4;
}

.blog-details-section .blog-details-wrap {
    background-color: #fff;
}

.blog-details-section .blog-details-wrap .blog-details-thumb {
    overflow: hidden;
    border-radius: 6px;
}

.blog-details-section .blog-details-wrap .blog-meta-2 {
    margin-bottom: 45px;
}

.blog-details-section .blog-details-wrap .blog-details-text {
    padding: 40px;
}

.blog-details-section .blog-details-wrap article {
    margin-bottom: 30px;
}

.blog-details-section .blog-details-wrap blockquote {
    color: #555;
    background: #fafafa;
    font-size: 21px;
    font-style: italic;
    margin-bottom: 35px;
    padding: 20px;
    border-left: 4px solid var(--thm-primary);
}

.blog-details-section .blog-details-wrap blockquote .blockquote-author {
    font-style: normal;
    padding-left: 45px;
    position: relative;
}

.blog-details-section .blog-details-wrap blockquote .blockquote-author:before {
    left: 0;
    top: 18px;
    height: 1px;
    width: 38px;
    content: "";
    position: absolute;
    background-color: #000;
}

.blog-details-section .blog-details-wrap blockquote .blockquote-author strong {
    color: #000;
    font-size: 16px;
    margin-right: 5px;
}

.blog-details-section .blog-details-wrap blockquote .blockquote-author span {
    font-size: 14px;
}

.blog-details-section .blog-details-wrap .post-cat-wrap.style-2 a {
    margin-right: 5px;
    padding: 10px 12px;
    border-radius: 30px;
    display: inline-block;
    border: 1px solid #e5e5e5;
}

.blog-details-section .blog-details-wrap .post-cat-wrap a {
    padding: 10px 12px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid #ddd;
    margin-right: 6px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
}

.blog-details-section .blog-details-comment-area {
    padding: 35px;
    margin-top: 40px;
    background-color: #fff;
}

.blog-details-section .blog-details-comment-area h3 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
}

.blog-details-section .blog-details-comment-area .comment-discuss {
    margin-top: 20px;
}

.blog-details-section .blog-details-comment-area .comment-innerbox {
    margin-bottom: 35px;
}

.blog-details-section .blog-details-comment-area .comment-innerbox .comment-img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-right: 20px;
    border-radius: 100%;
}

.blog-details-section .blog-details-comment-area .comment-innerbox .comment-text {
    overflow: hidden;
}

.blog-details-section .blog-details-comment-area .comment-innerbox .comment-text h4 {
    color: #000;
    font-weight: 700;
    font-size: 20px;
}

.blog-details-section .blog-details-comment-area .comment-innerbox .comment-text span {
    color: #7f7f7f;
    font-size: 14px;
}

.blog-details-section .blog-details-comment-area .comment-innerbox .comment-text p {
    margin-top: 15px;
}

.blog-details-section .blog-details-comment-area .comment-innerbox .comment-text .comment-reply-btn {
    top: 0;
    right: 0;
    color: #fff;
    height: 42px;
    width: 100px;
    font-weight: 700;
    position: absolute;
    line-height: 42px;
    border-radius: 30px;
    background-color: #ff5520;
}

.blog-details-section .blog-details-comment-area .comment-innerbox.reply-comment {
    margin-left: 100px;
}

.blog-details-title {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 25px;
}

.blog-details-thumb-text {
    position: relative;
    bottom: 220px;
    z-index: 1;
    max-width: 1200px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.blog-details-thumb-title {
    color: #fff;
    font-size: 60px;
    font-weight: 700;
}

.blog-section-4 {
    padding: 110px 0px;
}

.blog-btn-4 {
    margin-top: 40px;
}

.blog-btn-4 a {
    color: #fff;
    height: 50px;
    width: 150px;
    font-size: 15px;
    font-weight: 700;
    line-height: 50px;
    border-radius: 5px;
    display: inline-block;
    background-color: #ff3614;
}

.blog-date-box span {
    display: block;
}

.blog-content .blog-date {
    background-color: var(--thm-primary);
    box-shadow: 1px 1.732px 30px 0px rgb(254 131 102 / 40%);
    border-radius: 30px;
    display: inherit;
    padding: 6px 18px;
    color: #fff;
    margin: -20px auto;
    font-weight: 600;
    z-index: 1001;
    position: absolute;
    font-size: 14px;
    right: 0;
}

.blog-content .blog-author {
    background-color: var(--thm-primary);
    box-shadow: 1px 1.732px 30px 0px rgb(254 131 102 / 40%);
    border-radius: 30px;
    display: inherit;
    padding: 0 23px 0 0;
    color: #fff;
    margin: -20px auto;
    font-weight: 600;
    z-index: 1001;
    position: absolute;
    font-size: 13px;
    right: 0;
    height: 35px;
    vertical-align: middle;
    line-height: 35px;
    text-transform: uppercase;
}

.blog-content .blog-author-img {
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 100%;
    margin-right: 5px;
}

/* Blog Service */

.blog-details-image-and-list {
    padding-bottom: 25px;
}

.service-details-list h2 {
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 20px;
    color: #000;
}

.service-details-list img {
    margin-top: 10px;
}

.service-details-list ul {
    list-style: none;
    padding: 0;
}

.service-details-list li {
    position: relative;
    padding-left: 60px;
    margin-bottom: 15px;
}

.service-details-list li:last-child {
    margin-bottom: 0;
}

.service-details-list li i {
    background-color: #f4f5ef;
    color: var(--thm-primary);
    display: inline-block;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    position: absolute;
    left: 0;
    top: 8px;
    transition: 0.3s;
}

.service-details-list li:hover i {
    background: var(--thm-primary);
    color: #ffffff;
}

/*---------------------------------------------------- */
/*Comments area*/
/*----------------------------------------------------*/

.comments-list {
    padding: 40px 0;
}

.comments-list ul {
    padding: 0;
    margin: 0;
}

.comments-list .comment-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 30px;
    margin-top: 0;
}

.comments-list .comment-item + .comment-item {
    border-top: 1px solid var(--thm-border);
    padding-top: 30px;
}

.comments-list .comment-item:last-child {
    margin-bottom: 0;
}

.comments-list .comment-item img {
    width: 100px;
    margin-right: 20px;
    border-radius: 50%;
}

.comments-list .comment-item .comment-body {
    position: relative;
    flex: 1;
}

.comments-list .comment-item h5 {
    margin-bottom: 10px;
    font-weight: 700;
    color: #000;
}

.comments-list .comment-item span {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 15px;
    color: var(--thm-base);
    font-weight: 600;
}

.comments-list .comment-item p {
    margin-bottom: 0;
}

.comments-list .comment-item .btn-link {
    padding: 10px 25px;
    border: 2px solid var(--thm-border);
    border-radius: 35px;
    font-size: 14px;
    position: absolute;
    top: 0;
    color: #777;
    font-weight: 700;
    font-style: normal;
    cursor: pointer;
    right: 0;
}

.comments-list .comment-item .btn-link:hover {
    background-color: var(--thm-base);
    border-color: var(--thm-base);
    color: #fff;
}

.blog-details-content .comments-list .comment-item .btn-link {
    position: relative;
    display: inline-block;
    border: 0;
    padding: 0;
    margin-top: 18px;
    font-size: 16px;
}

.blog-details-content .comments-list .comment-item .btn-link:hover {
    color: var(--thm-base-hover);
    background-color: transparent;
}

.blog-details-content .comments-list .comment-item .date {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    margin-bottom: 0;
    color: var(--thm-primary);
    font-weight: 500;
}

.blog-details-content .comments-list .comment-item:last-child {
    margin-left: 70px;
}

.blog-details-content .comments-list .comment-item .btn-link {
    position: relative;
    border: 0;
    padding: 0;
    color: #00acb1;
    margin-top: 18px;
    font-size: 16px;
}

.blog-details-section .blog-details-wrap .post-cat-wrap a:hover {
    background: var(--thm-primary);
    color: #fff;
    border-color: var(--thm-primary);
}

/* Blog Share */

.share-this-post {
    background-color: #fff;
    padding: 40px;
    margin-top: 35px;
}

.share-post-title {
    margin-top: 0;
    margin-bottom: 25px;
    color: #000;
    font-size: 25px;
    font-weight: 700;
}

.share-this-post ul li a {
    color: #ffffff;
    background-color: #647589;
    display: block;
    padding: 8px 14px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 2px;
}

.share-this-post ul li a.fb-share {
    background-color: #3b5999;
}

.share-this-post ul li a.twitter-share {
    background-color: #3fbff5;
}

.share-this-post ul li a.linkedin-share {
    background-color: #0073b0;
}

.share-this-post ul li a.pinterest-share {
    background-color: #e60122;
}

.share-this-post ul li a.email-share {
    background-color: #d54b3d;
}

.share-this-post ul li a.fb-share,
.share-this-post ul li a.twitter-share,
.share-this-post ul li a.linkedin-share,
.share-this-post ul li a.whatsapp-share {
    padding-right: 14px;
    padding-left: 14px;
}

.fb-share i,
.twitter-share i,
.linkedin-share i,
.whatsapp-share i {
    margin-right: 10px;
    padding-right: 13px;
    position: relative;
}

.fb-share i:after,
.twitter-share i:after,
.linkedin-share i:after,
.whatsapp-share i:after {
    position: absolute;
    content: "";
    height: 15px;
    width: 1px;
    background-color: #fff;
    z-index: 1;
    right: 0;
    top: 2px;
}

.social-icons {
    margin: 0;
    padding: 0;
    list-style: none;
}

.social-icons li {
    display: inline-block;
}

/*---------------------------------------------------- */
/* Subscribe area*/
/*----------------------------------------------------*/

.subscribe-area {
    background-image: url(../img/subscribe-bg.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}

.subscribe-area:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #18253e;
    opacity: 0.8;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.subscribe-area .section-title {
    text-align: left;
}

.subscribe-title {
    margin-bottom: 35px;
    margin-top: -5px;
    position: relative;
}

.subscribe-title h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.3;
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
}

.subscribe-area .subscribe-title h2 {
    margin-left: 0;
    color: #fff;
}

.subscribe-area .subscribe-content {
    position: relative;
    z-index: 1;
}

.subscribe-area .subscribe-content .form-control {
    height: 55px;
    border: 0;
    border-radius: 30px;
    display: inline-block;
    max-width: 455px;
    padding-left: 20px;
}

.common-btn {
    display: inline-block;
    color: #fff;
    background-color: #f93e45;
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    border: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 1 !important;
    z-index: 1;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

.common-btn:hover {
    color: #fff;
    background-color: #18253e;
}

.subscribe-area .subscribe-content .common-btn {
    border-radius: 30px;
    height: 55px;
    margin-left: 15px;
}

.subscribe-area .subscribe-content img {
    position: absolute;
    right: 0;
    bottom: -100px;
}

/*---------------------------------------------------- */
/*Category area*/
/*----------------------------------------------------*/
.category-section {
    padding: 95px 0px 100px;
}

.category-img-text {
    border-radius: 10px;
    background-color: #fff;
    padding: 25px 40px 0px;
    margin-bottom: 35px;
    border: 2px solid #eee;
}

.category-img-text .category-img img {
    transition: 0.3s all ease-in-out;
}

.category-img-text .category-text-icon {
    margin-bottom: 15px;
}

.category-img-text .category-text-icon .category-icon {
    line-height: 1;
    margin-right: 15px;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.category-img-text .category-text-icon .category-icon i {
    font-size: 45px;
    color: #ff5520;
}

.category-img-text .category-text h3 {
    font-size: 24px;
    font-weight: 700;
}

.category-img-text .category-text span {
    color: #ababab;
}

.category-img-text .category-hover-icon {
    width: 60px;
    left: 0;
    right: 0;
    height: 60px;
    bottom: 0px;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    line-height: 60px;
    position: absolute;
    border-radius: 100%;
    background-color: var(--thm-primary);
    transition: 0.3s all ease-in-out;
}

.category-img-text .category-hover-icon i {
    color: #fff;
    transition: 0.3s all ease-in-out;
}

.category-img-text .category-hover-icon:hover i {
    transform: rotate(130deg);
}

.category-img-text:hover .category-hover-icon {
    opacity: 1;
    bottom: -30px;
    visibility: visible;
}

.category-img-text:hover .category-img img {
    filter: grayscale(1);
}

.category-img-text:hover .category-icon {
    transform: rotateY(360deg);
}

.category-slide-content {
    margin-top: 40px;
}

.category-slide-content .owl-nav {
    display: none;
}

.category-slide-content .owl-dots {
    margin-top: 15px;
    text-align: center;
}

.category-slide-content .owl-dots .owl-dot {
    width: 28px;
    height: 5px;
    margin: 0px 5px;
    cursor: pointer;
    border-radius: 30%;
    display: inline-block;
    background-color: #ececed;
    transition: 0.3s all ease-in-out;
}

.category-slide-content .owl-dots .owl-dot.active {
    width: 38px;
    background-color: var(--thm-primary);
}

.category-section-2 {
    padding: 110px 0px 90px;
    background: rgba(245, 245, 245, 0.7);
    background: #f9f6f6;
    /*#fbf5f3 #fff5f1*/
    background-image: url(../img/bg/category-bg.png);
    background-repeat: repeat;
}

.category-section-2 .section-title.title-style-two .title-watermark {
    opacity: 0.1;
}

.category-content-2 {
    margin-top: 40px;
}

.category-innerbox-2 {
    padding: 35px;
    border-radius: 10px;
    margin-bottom: 30px;
    background-color: #fff9f7;
    border: 10px solid #fff;
}

.category-innerbox-2:hover {
    background-color: #fff;
    box-shadow: 0px 14px 29px 0px rgba(12, 12, 12, 0.08);
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.category-innerbox-2 .category-icon {
    background: #fff;
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 20px;
    border-radius: 50px;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.category-innerbox-2 .category-icon i {
    color: #ff5520;
    font-size: 60px;
}

.category-innerbox-2 .category-text h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.category-innerbox-2:hover .category-icon {
    transform: rotateY(360deg);
}

.top-category-section-4 {
    z-index: 3;
    overflow: visible;
    position: relative;
    margin-bottom: -100px;
    background-color: #f9f8f8;
}

.top-category-content {
    top: -100px;
    position: relative;
    padding: 100px 60px 0px;
}

.top-category-content .section-title {
    margin-bottom: 25px;
}

.top-category-content .section-title span {
    color: #ff3614;
}

.top-category-content .section-title span:before,
.top-category-content .section-title span:after {
    background-color: #ff3614;
}

.top-category-content .section-title h2 {
    color: #fff;
}

.top-category-slide-item {
    height: 220px;
    padding-top: 38px;
    background-color: #1a2847;
    transition: 0.4s all ease-in-out;
}

.top-category-slide-item .top-category-slide-icon {
    line-height: 1;
    margin-bottom: 20px;
    transition: all 0.35s cubic-bezier(0.38, 3, 0.57, 1.6);
    transform: translate3d(0px, 0, 0);
}

.top-category-slide-item .top-category-slide-icon i {
    color: #ff3614;
    font-size: 50px;
    transition: 0.3s all ease-in-out;
}

.top-category-slide-item .top-category-slide-text h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 5px;
}

.top-category-slide-item .top-category-slide-text span {
    color: #fff;
    transition: 0.3s all ease-in-out;
}

.top-category-slide-item .top-category-slide-text .top-cat-more-icon {
    left: 0;
    right: 0px;
    opacity: 0;
    bottom: 40px;
    color: #fff;
    font-size: 18px;
    position: absolute;
    visibility: hidden;
    transition: 0.3s all ease-in-out;
}

.top-category-slide-item:hover {
    background-color: #ff3614;
}

.top-category-slide-item:hover .top-category-slide-icon i {
    color: #fff;
}

.top-category-slide-item:hover span {
    opacity: 0;
    margin-right: 20px;
    visibility: hidden;
}

.top-category-slide-item:hover .top-cat-more-icon {
    opacity: 1;
    visibility: visible;
}

.top-category-slide-item:hover .top-category-slide-icon {
    transition: all 0.35s cubic-bezier(0.38, 3, 0.57, 1.6);
    transform: translate3d(0px, -6px, 0);
}

.top-category-slider .owl-nav .owl-next,
.top-category-slider .owl-nav .owl-prev {
    top: 50%;
    width: 50px;
    color: #fff;
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    position: absolute;
    text-align: center;
    border-radius: 100%;
    background-color: #1a2847;
    transform: translateY(-50%);
    transition: 0.3s all ease-in-out;
}

.top-category-slider .owl-nav .owl-next:hover,
.top-category-slider .owl-nav .owl-prev:hover {
    background-color: #ff3614;
}

.top-category-slider .owl-nav .owl-next {
    right: -80px;
}

.top-category-slider .owl-nav .owl-prev {
    left: -80px;
}

/*---------------------------------------------------- */
/*breadcrumb area*/
/*----------------------------------------------------*/
.breadcrumb-section {
    padding: 60px 0px 80px;
}

.breadcrumb-section .breadcrumb-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #030014;
}

.breadcrumb-content {
    z-index: 1;
    position: relative;
}

.breadcrumb-content h1 {
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    padding-bottom: 10px;
}

.breadcrumb-content .breadcrumb-item .breadcrumb {
    justify-content: center;
    background-color: transparent;
}

.breadcrumb-content .breadcrumb-item .breadcrumb li {
    color: #fff;
    font-weight: 700;
}

.breadcrumb-content .breadcrumb-item .breadcrumb .breadcrumb-item.active {
    font-weight: 500;
}

.breadcrumb-content .breadcrumb-item .breadcrumb-item + .breadcrumb-item::before {
    content: "";
    font-weight: 900;
    color: var(--thm-primary);
    font-family: "Font Awesome 5 Pro";
}

/*---------------------------------------------------- */
/*Agent area*/
/*----------------------------------------------------*/
.agent-section {
    padding: 100px 0px;
}

.agent-content {
    padding-top: 30px;
}

.agent-img-text {
    transition: 0.3s all ease-in-out;
    margin-bottom: 30px;
    padding: 18px;
    border-radius: 10px;
    border: 2px solid #eee;
}

.agent-img-text:hover {
    transform: translateY(-15px);
}

.agent-img-text:after {
    top: 0;
    left: 0;
    width: 0;
    z-index: 1001;
    content: "";
    height: 100%;
    position: absolute;
    border-bottom: 4px solid var(--thm-primary);
    transition: all 300ms ease-in-out;
}

.agent-img-text:hover:after {
    width: 100%;
}

.agent-img-text .agent-img {
    z-index: 3;
    margin: 0 auto;
    overflow: hidden;
}

.agent-img-text .agent-text {
    border-radius: 6px;
    padding: 25px 30px 30px;
    background-color: #fbf8f8;
    z-index: 2;
    transition: 0.4s all ease-in-out;
}

.agent-img-text .team-social {
    z-index: 5;
    position: absolute;
    bottom: -20%;
    left: 0;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    padding: 10px 0;
    transition: all 0.3s ease-in-out;
    background-color: var(--thm-primary);
}

.agent-img-text:hover .team-social {
    visibility: visible;
    bottom: 0;
    opacity: 100%;
}

.agent-img-text .team-social li {
    display: inline-block;
    margin-right: 9px;
    text-align: center;
}

.agent-img-text .team-social li a {
    color: #fff;
    font-size: 16px;
    z-index: 5;
    display: block;
    transition: all 0.3s ease-in-out;
}

.agent-img-text .team-social li a i {
    transition: all 0.3s ease-in-out;
}

.agent-img-text .agent-text h3 {
    color: #1f2230;
    font-size: 22px;
    font-weight: 700;
    transition: 0.4s all ease-in-out;
}

.agent-img-text .agent-text span {
    display: block;
    font-size: 14px;
    transition: 0.4s all ease-in-out;
}

.agent-img-text .agent-text .agent-title {
    color: var(--thm-primary);
    padding: 5px 0px;
    font-size: 16px;
}

.agent-img-text .agent-text .agent-has {
    color: #888888;
}

.agent-details-section {
    padding: 100px 0px;
    background-color: #f2f2f4;
}

.agent-details-section-2 {
    padding: 100px 0px 0px;
    background-color: #f2f2f4;
}

.agent-details-content {
    padding: 35px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 6px 38px 0px rgba(0, 0, 0, 0.04);
}

.agent-details-img {
    width: 370px;
    height: 370px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 100%;
    box-shadow: 0px 6px 42px 0px rgba(4, 4, 97, 0.12);
}

.agent-details-text {
    padding-top: 10px;
}

.agent-details-name-social h3 {
    color: #1f2230;
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 5px;
}

.agent-details-name-social span {
    font-size: 18px;
    font-weight: 700;
    font-family: var(--thm-headline-font);
}

.agent-details-name-social .agent-details-social {
    margin-top: 10px;
}

.agent-details-name-social .agent-details-social a {
    font-size: 18px;
    margin-right: 15px;
}

.agent-details-name-social .agent-details-social a:nth-child(1) {
    color: #3b5998;
}

.agent-details-name-social .agent-details-social a:nth-child(2) {
    color: #1da1f2;
}

.agent-details-name-social .agent-details-social a:nth-child(3) {
    color: #0077b5;
}

.agent-details-name-social .agent-details-social a:nth-child(4) {
    color: #5973e9;
}

.agent-details-profile {
    width: 270px;
    padding: 18px;
    border-radius: 3px;
    border: 2px solid #d1d1d3;
}

.agent-details-profile span {
    display: block;
    margin-bottom: 5px;
}

.agent-details-profile span b {
    color: #000;
}

.agent-details-profile span:last-child {
    margin-bottom: 0;
}

.agent-details-profile span i {
    margin-right: 10px;
    color: var(--thm-primary);
}

.agent-details-content-text {
    margin-top: 35px;
}

.agent-details-content-text p {
    margin-bottom: 30px;
}

.agent-details-content-text .agent-details-btn {
    color: #000;
    height: 45px;
    width: 150px;
    font-size: 15px;
    font-weight: 700;
    line-height: 40px;
    border-radius: 30px;
    border: 2px solid #ff5520;
    transition: 0.3s all ease-in-out;
}

.agent-details-content-text .agent-details-btn i {
    font-size: 14px;
}

.agent-details-content-text .agent-details-btn a {
    width: 100%;
    display: block;
}

.agent-details-content-text .agent-details-btn:hover {
    color: #fff;
    background-color: #ff5520;
}

.agent-contact-section {
    padding: 40px 0px;
    background-color: #f2f2f4;
}

.agent-contact-form-wrap h3 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 35px;
}

.agent-popular-section {
    background-color: #f2f2f4;
    padding-bottom: 100px;
}

.agent-popular-content h1 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 35px;
}

.agent-popular-content h2 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 30px;
}

.agent-section-2 {
    padding: 120px 0px;
    background-color: #fff;
}

.agent-section-2 .section-title h2 {
    padding-bottom: 0;
}

.agent-section-2 .section-title span:after,
.agent-section-2 .section-title span:before {
    background-color: var(--thm-primary);
}

#agent-slider {
    padding-top: 40px;
}

#agent-slider .owl-item {
    opacity: 0;
    transition: opacity 500ms;
}

#agent-slider .owl-item.active {
    opacity: 1;
}

#agent-slider .owl-nav {
    right: 0;
    top: -60px;
    cursor: pointer;
    position: absolute;
}

#agent-slider .owl-nav .owl-next,
#agent-slider .owl-nav .owl-prev {
    width: 60px;
    height: 60px;
    font-size: 18px;
    line-height: 60px;
    margin-left: 25px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    border: 2px solid #ff5520;
    transition: 0.3s all ease-in-out;
}

#agent-slider .owl-nav .owl-next:hover,
#agent-slider .owl-nav .owl-prev:hover {
    color: #fff;
    background-color: #ff5520;
}

/*--------------------------------------------------------------
# Agent Details 2
--------------------------------------------------------------*/
.agent-detail-right {
    position: relative;
    display: block;
    margin-left: 40px;
    padding-top: 10px;
}

.agent-detail-right-title h3 {
    font-size: 30px;
    color: var(--thm-black);
    font-weight: 700;
    line-height: 40px;
}

.agent-detail-right-title p {
    font-size: 18px;
    margin: 0;
    font-weight: 400;
}

.agent-detail-right-text {
    padding: 30px 0;
}

.agent-detail-right-text p {
    font-size: 18px;
    margin: 0;
    font-weight: 400;
    line-height: 38px;
}

.agent-contact-info.agent-detail-contact-info {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 48px;
    width: 270px;
    padding: 18px;
    border-radius: 3px;
    border: 2px solid #d1d1d3;
    position: absolute;
    float: right;
    right: 0;
}

.agent-detail-right .rating-overview {
    background: #f7f8fb;
}

.skill-progress-bar {
    position: relative;
    display: block;
}

.progress-levels {
    position: relative;
    display: block;
}

.progress-levels .progress-box {
    position: relative;
    display: block;
    margin-bottom: 24px;
}

.progress-box.marb {
    margin-bottom: 0;
}

.progress-levels .progress-box .text {
    position: relative;
    color: #8d96ad;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 9px;
    text-transform: capitalize;
}

.progress-levels .progress-box.last-child {
    margin-bottom: 0;
}

.progress-levels .progress-box .inner {
    position: relative;
    display: block;
}

.progress-levels .progress-box .bar {
    position: relative;
    display: block;
}

.progress-levels .progress-box .bar .bar-innner {
    position: relative;
    width: 100%;
    height: 8px;
    background: #eff1f6;
    border-radius: 10px;
}

.progress-levels .progress-box .bar .bar-fill {
    position: absolute;
    top: 0%;
    left: 0px;
    bottom: 0%;
    width: 0px;
    height: 8px;
    border-radius: 10px;
    background: var(--thm-primary);
    transition: all 2000ms ease 300ms;
}

.progress-levels .progress-box .bar .bar-innner .skill-percent {
    position: absolute;
    top: -35px;
    right: 0px;
    width: 50px;
    height: 25px;
    display: block;
    text-align: center;
    padding: 0;
    z-index: 1;
}

.progress-levels .progress-box .inner .count-text {
    position: relative;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    display: inline-block;
    float: none;
}

.progress-levels .progress-box .inner .percent {
    position: relative;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    display: inline-block;
    float: none;
    margin-left: -2px;
}

.agent-detail-social {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 20px;
}

.agent-detail-social a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    width: 35px;
    height: 35px;
    background-color: #eff1f6;
    font-size: 15px;
    border-radius: 4px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.agent-detail-social a + a {
    margin-left: 10px;
}

.agent-detail-social a:hover {
    background: var(--thm-primary);
    color: #ffffff;
}

.agent-contact-info {
    position: relative;
    display: block;
    border-bottom: 1px solid #e0e3eb;
    margin-bottom: 40px;
    padding-bottom: 33px;
}

.agent-contact-info li {
    position: relative;
    display: block;
    padding-left: 30px;
}

.agent-contact-info li + li {
    margin-top: 10px;
}

.agent-contact-info li .icon {
    position: absolute;
    top: 0;
    left: 0;
}

.agent-contact-info li .icon i {
    font-size: 17px;
    color: var(--thm-primary);
}

.agent-contact-info li .text a {
    font-size: 16px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.agent-contact-info li .text a:hover {
    color: var(--thm-primary);
}

/*---------------------------------------------------- */
/*Location area*/
/*----------------------------------------------------*/

.location-section {
    padding: 95px 0px 100px;
    background-color: rgba(245, 245, 245, 0.7);
}

.location-section .location-content {
    padding-top: 35px;
}

.list-bunch {
    margin-bottom: -2rem;
}

.list-bunch .location-item {
    margin-bottom: 2rem;
}

.location-box {
    position: relative;
    font-size: 1.225rem;
    overflow: hidden;
    display: block;
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 15px 25px 0px rgb(0 0 0 / 10%);
}

.location-box a {
    color: #fff;
}

.location-box .location-title {
    position: absolute;
    top: 35px;
    left: 20px;
    z-index: 1;
    padding: 0 12px 0 0;
    font-family: var(--thm-headline-font);
    font-size: 15px;
    font-weight: 700;
    background: #fff;
    color: #677283;
    border-radius: 24px;
    transition: 0.3s all ease-in-out;
}

.location-box:hover .location-title {
    background: var(--thm-primary);
    color: #fff;
}

.location-box .location-title i {
    background: var(--thm-primary);
    border-radius: 24px;
    line-height: 30px;
    height: 30px;
    width: 30px;
    font-size: 12px;
    text-align: center;
    margin-right: 2px;
}

.location-box .location-content {
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: 0.2s all ease;
}

.location-box:hover .location-content {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.location-box .location-content {
    position: absolute;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 3rem 1rem 1rem;
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0, rgba(0, 0, 0, 0) 100%);
}

.location-box .location-content h6 {
    margin-bottom: 0.25rem;
    font-family: var(--thm-headline-font);
    font-size: 12px;
    color: var(--thm-black);
    font-weight: 700;
    display: inline-block;
    background: #ffffff;
    padding: 4px 15px;
    border-radius: 12px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.location-box .location-content p {
    font-size: 15px;
    color: #fff;
    float: right;
    line-height: 36px;
    font-weight: 500;
}

p:last-child {
    margin-bottom: 0;
}

/*---------------------------------------------------- */
/*FAQ area*/
/*----------------------------------------------------*/
.faq-main-section {
    padding: 120px 0px;
}

.tab-pane-btn {
    text-align: center;
    padding-bottom: 45px;
}

.tab-pane-btn .nav {
    display: inherit;
}

.tab-pane-btn .nav-tabs .nav-item {
    margin: 0;
}

.tab-pane-btn .nav-tabs .nav-item.show .nav-link,
.tab-pane-btn .nav-tabs .nav-link.active,
.tab-pane-btn .nav-tabs,
.tab-pane-btn .nav-tabs .nav-link {
    border: none;
}

.tab-pane-btn .nav-tabs .nav-link {
    border-radius: 0;
}

.tab-pane-btn .nav-tabs .nav-link:hover {
    border-radius: 0;
    color: var(--thm-primary);
}

.tab-pane-btn .nav-tabs .nav-link i {
    font-size: 14px;
    padding-right: 5px;
}

.tab-pane-btn .nav-tabs .nav-link.active {
    color: #fff;
    background-color: var(--thm-primary);
}

.tab-pane-btn .tab-pane-btn-area {
    overflow: hidden;
    display: inline-block;
    border: 1px solid #e5e5e5;
}

.tab-pane-btn li {
    float: left;
}

.tab-pane-btn li a {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 40px;
    position: relative;
}

.tab-pane-btn li a:after {
    top: 0;
    right: 0;
    width: 1px;
    content: "";
    height: 100%;
    position: absolute;
    background-color: #e5e5e5;
}

.tab-pane-btn li:last-child a:after {
    display: none;
}

.faq-content {
    margin-bottom: 15px;
    border-bottom: 1px solid #d1d1d5;
    padding-bottom: 15px;
}

.faq-content:last-child {
    border-bottom: none;
}

.faq-content h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 16px;
}

.faq-content p {
    color: #555555;
}

/*---------------------------------------------------- */
/*gallery area*/
/*----------------------------------------------------*/
.photo-gallery-section {
    padding: 120px 0px;
}

.photo-filter-btn {
    margin-bottom: 45px;
}

.photo-filter-btn button {
    padding: 0;
    color: #000;
    border: none;
    font-weight: 700;
    border: 1px solid #ddd;
    padding: 5px 10px;
    min-width: 120px;
    background-color: transparent;
}

.photo-filter-btn button.is-checked {
    background: var(--thm-primary);
    color: #fff;
}

.grid-size-33 {
    width: 33.33%;
}

.photo-gallery-area {
    margin-bottom: 50px;
}

.photo-gallery-area .grid-sizer {
    width: 33.33%;
}

.photo-gallery-area .activity-filter-area {
    margin: 0px -5px;
}

.gallery-innerbox {
    padding: 5px;
}

.gallery-innerbox .gallery-img {
    overflow: hidden;
    border-radius: 10px;
}

.gallery-innerbox .gallery-popup {
    height: 100%;
    width: 100%;
    position: absolute;
}

.gallery-innerbox .gallery-img:hover:before {
    opacity: 1;
}

.gallery-innerbox .gallery-img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(21, 23, 26, 0.4)
        url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38.5 22C38.5 31.1127 31.1127 38.5 22 38.5C12.8873 38.5 5.5 31.1127 5.5 22C5.5 12.8873 12.8873 5.5 22 5.5C31.1127 5.5 38.5 12.8873 38.5 22Z' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M42 42L34 34' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.25s ease-in-out;
    opacity: 0;
    width: 4px;
}

.gallery-innerbox:hover .gallery-popup:after {
    width: 60px;
}

.gallery-innerbox:hover .gallery-popup:before {
    height: 60px;
}

.gallery-innerbox:hover .gallery-img:before {
    right: 0;
    left: auto;
    width: 100%;
}

.not-found-section {
    padding: 120px 0px;
}

.not-found-content {
    margin: 0 auto;
    max-width: 600px;
}

.not-found-content span {
    color: #000;
    font-size: 36px;
    display: block;
    font-weight: 700;
    line-height: 1.333;
    margin-bottom: 20px;
}

.not-found-content a {
    color: #fff;
    font-weight: 700;
}

.not-found-content a i {
    color: #fff;
    margin-left: 5px;
}

.not-found-content .error-img {
    margin-top: 60px;
}

.login-modal .modal-content {
    border: none;
    overflow: hidden;
    border-radius: 24px;
}

.login-modal .modal-dialog {
    max-width: 480px;
}

.login-modal .modal-body {
    padding: 20px;
}

.modal-header {
    z-index: 1;
    padding: 10px 0px;
    background: var(--thm-primary);
    color: white;
}

.modal-header p {
    color: #fff;
    font-size: 15px;
    padding-top: 15px;
}
.modal-signup-login-tab .tab-pane-btn {
    padding-bottom: 20px;
}

.modal-signup-login-tab p {
    color: #555555;
}

.modal-signup-login-tab p a {
    color: #000;
    font-weight: 700;
}

.login-content,
.sign-up-content {
    max-width: 370px;
    margin: 0 auto;
}

.login-content input,
.sign-up-content input {
    width: 100%;
    height: 40px;
    border: none;
    padding-left: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #efeff0;
}

.login-content input:placeholder,
.sign-up-content input:placeholder {
    color: #555555;
}

.login-content .login-label,
.sign-up-content .login-label {
    text-align: left;
}

.login-content .login-label span,
.sign-up-content .login-label span {
    float: left;
    color: #555555;
}

.login-content .login-label a,
.sign-up-content .login-label a {
    color: #000;
    float: right;
    font-weight: 500;
}

.login-content .login-label input,
.sign-up-content .login-label input {
    width: 15px;
    height: 15px;
    margin-right: 12px;
}

.login-content button,
.sign-up-content button {
    color: #fff;
    width: 100%;
    height: 50px;
    border: none;
    font-weight: 700;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: var(--thm-primary);
}

.sign-up-content {
    max-width: 470px;
}

.sign-up-content button {
    margin-top: 20px;
}

.sign-up-content .login-label input {
    margin: 5px 10px 0px 10px;
}

.login-content hr,
.sign-up-content hr {
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
}

.login-content hr:before,
.sign-up-content hr:before {
    background-color: #ffffff;
    bottom: 0;
    color: #006c70;
    content: "Or";
    font-size: 14px;
    position: absolute;
    left: 0;
    margin: 0 auto;
    right: 0;
    text-align: center;
    top: -12px;
    width: 10%;
}

.login-content button.btn-fb,
.sign-up-content button.btn-fb {
    background-color: #ffffff;
    border: 1px solid #1877f2;
    border-radius: 8px;
    color: #1877f2;
    font-size: 14px;
    height: 52px;
    margin: 0;
    margin-bottom: 20px;
    padding-left: 10px;
}

.login-content button i,
.sign-up-content button i {
    font-size: 16px;
    padding-right: 5px;
    display: contents;
}

.login-content button.btn-googl,
.sign-up-content button.btn-googl {
    background-color: #ffffff;
    border: 1px solid #000;
    border-radius: 8px;
    color: #000;
    font-size: 14px;
    height: 52px;
    margin: 0;
    margin-bottom: 10px;
    padding-left: 10px;
}

/*---------------------------------------------------- */
/*contact area*/
/*----------------------------------------------------*/
.contact-content-section {
    padding: 25px 0px;
    background-color: #f2f2f4;
}

.contact-content {
    margin-top: 40px;
}

.contact-content-inner {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 50px 30px 47px 30px;
    border-radius: 10px;
    box-shadow: 0px 10px 30px 0px rgb(6 26 58 / 6%);
    display: flex;
}

.contact-content-inner .contact-content-icon {
    margin-bottom: 28px;
    height: 90px;
    width: 90px;
    color: var(--thm-base);
    font-size: 40px;
    background: var(--thm-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-content-inner .contact-content-text.headline {
    padding: 10px 0 0 20px;
    text-align: left;
    width: 70%;
}

.contact-content-inner .contact-content-text h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 10px;
}

.contact-content-inner .contact-content-text span {
    display: block;
    font-size: 16px;
}

.contact-form-wrap {
    margin-top: 90px;
}

.contact-form-wrap h3 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 35px;
}

.contact-form-area {
    padding: 40px;
    background-color: #fff;
}

.contact-form-area .contact-form-input {
    flex-wrap: wrap;
    margin: 0px -15px;
}

.contact-form-area .contact-form-input input {
    height: 50px;
    width: 340px;
    border: none;
    margin: 0px 15px;
    padding-left: 20px;
    border-radius: 8px;
    background-color: #efeff0;
}

.contact-form-area textarea {
    width: 100%;
    border: none;
    height: 170px;
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;
    background-color: #efeff0;
}

.contact-form-area button {
    color: #fff;
    width: 100%;
    border: none;
    height: 50px;
    margin-top: 20px;
    border-radius: 8px;
    font-weight: 700;
    background-color: var(--thm-primary);
}

.contact-form-area button i {
    margin-left: 10px;
}

/*Footer section 2*/
/*--------------------------*/

.footer-section {
    padding-top: 80px;
}

.footer-section.style-two {
    background: #000a1f;
}

.footer-item {
    margin-bottom: 30px;
}

.footer-item .footer-logo .top {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #f4f4f4;
}

.footer-section.style-two .footer-item .footer-logo .top {
    border-bottom: 1px solid rgb(255 255 255 / 13%);
}

.footer-item .footer-logo .top .logo {
    display: block;
    margin-bottom: 10px;
    max-width: 200px;
}

.footer-item .footer-logo .top p {
    margin-bottom: 12px;
}

.footer-section.style-two .footer-item .footer-logo .top p {
    color: #999;
}

.footer-item .footer-logo .top ul {
    margin: 0;
    padding: 0;
}

.footer-item .footer-logo .top ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 5px;
}

.footer-item .footer-logo .top ul li a {
    display: block;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 5px;
    color: #18253e;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 25px -7px #e1e0e0;
    box-shadow: 0px 0px 25px -7px #e1e0e0;
    font-size: 16px;
    line-height: 30px;
    transition: 0.5s;
}

.footer-section.style-two .footer-item .footer-logo .top ul li a {
    color: #8b92a2;
    background-color: rgb(255 255 255 / 18%);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.footer-item .footer-logo .top ul li a:hover {
    color: #fff;
    background-color: #f93e45;
}

.footer-section.style-two .footer-item .footer-logo .top ul li a:hover {
    color: #fff;
    background-color: var(--thm-primary);
}

.footer-item .footer-logo .bottom ul {
    margin: 0;
    padding: 0;
}

.footer-item .footer-logo .bottom ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
    max-width: 75px;
}

.footer-item .footer-logo .bottom ul li a {
    display: block;
    position: relative;
}

.footer-item .footer-logo .bottom ul li a:before {
    position: absolute;
    content: "";
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--thm-primary);
    opacity: 0.8;
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.footer-item .footer-logo .bottom ul li a:hover:before {
    width: 100%;
}

.footer-item .footer-logo .bottom ul li a i {
    color: #fff;
    font-size: 30px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 70%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.footer-item .footer-logo .bottom ul li a:hover i {
    left: 50%;
    opacity: 1;
    visibility: visible;
}

.footer-item .footer-logo .bottom ul li a img {
    display: block;
    border-radius: 5px;
}

.footer-item h3 {
    font-size: 22px;
    margin-bottom: 30px;
}

.footer-section.style-two .footer-item h3 {
    color: #fff;
    font-weight: 700;
    position: relative;
    margin-bottom: 25px;
}

.footer-item .footer-links .top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.footer-item .footer-links .bottom {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #f4f4f4;
}

.footer-section.style-two .footer-item .footer-links .bottom {
    border-top: 1px solid rgb(255 255 255 / 13%);
}

.footer-item .footer-links .top .left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.footer-item .footer-links .top ul {
    margin: 0;
    padding: 0;
}

.footer-item .footer-links .top ul li {
    list-style-type: none;
    display: block;
    margin-bottom: 5px;
}

.footer-item .footer-links .top ul li a {
    display: block;
    color: #5d6678;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    padding-left: 18px;
}

.footer-item .footer-links .top ul li a:hover {
    color: #f93e45;
}

.footer-section.style-two .footer-item .footer-links .top ul li a {
    color: #9d9d9d;
    font-weight: 400;
}

.footer-section.style-two .footer-item .footer-links .top ul li a:hover {
    color: #f93e45;
}

.footer-item .footer-links .top ul li a:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    left: 0;
    top: 8px;
    background-color: #f93e45;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.footer-item .footer-links .bottom h4 {
    font-size: 18px;
    margin-bottom: 15px;
}

.footer-section.style-two .footer-item .footer-links .bottom h4 {
    color: #fff;
}

.footer-item .footer-links .bottom ul {
    margin: 0;
    padding: 0;
}

.footer-item .footer-links .bottom ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 5px;
    margin-top: 8px;
}

.footer-item .footer-links .bottom ul li a {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #5d6678;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 25px -7px #e1e0e0;
    box-shadow: 0px 0px 25px -7px #e1e0e0;
    padding: 6px 15px;
    border-radius: 5px;
}

.footer-section.style-two .footer-item .footer-links .bottom ul li a {
    font-weight: 400;
    color: #acb0b9;
    background-color: rgb(255 255 255 / 15%);
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: 0.5s;
}

.footer-item .footer-links .bottom ul li a:hover {
    color: #fff;
    background-color: #18253e;
}

.footer-section.style-two .footer-item .footer-links .bottom ul li a:hover {
    color: #fff;
    background-color: var(--thm-primary);
}

.footer-item .footer-blog .inner {
    margin-bottom: 25px;
}

.footer-item .footer-blog .inner ul {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.footer-item .footer-blog .inner ul li {
    list-style-type: none;
    display: inline-block;
}

.footer-item .footer-blog .inner ul li:nth-child(1) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 28%;
    flex: 0 0 28%;
    max-width: 28%;
}

.footer-item .footer-blog .inner ul li img {
    border-radius: 5px;
    width: 100%;
}

.footer-item .footer-blog .inner ul li:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 650%;
    flex: 0 0 650%;
    max-width: 65%;
    margin-left: 12px;
}

.footer-item .footer-blog .inner ul li a {
    display: block;
    color: #18253e;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
}

.footer-section.style-two .footer-item .footer-blog .inner ul li a {
    display: block;
    color: #95999e;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
    transition: 0.5s;
}

.footer-section.style-two .footer-item .footer-blog .inner ul li a:hover {
    color: var(--thm-primary);
}

.footer-item .footer-blog .inner ul li span {
    display: block;
    font-size: 15px;
}

.footer-section.style-two .footer-item .footer-blog .inner ul li span {
    color: #999;
}

.footer-item .footer-contact .top {
    margin-bottom: 10px;
}

.footer-item .footer-contact .top ul {
    margin: 0;
    padding: 0;
}

.footer-item .footer-contact .top ul li {
    list-style-type: none;
    display: block;
    position: relative;
    margin-bottom: 10px;
    padding-left: 40px;
}

.footer-item .footer-contact .top ul li i {
    display: inline-block;
    width: 34px;
    height: 34px;
    line-height: 30px;
    text-align: center;
    color: var(--thm-primary);
    font-size: 16px;
    border: 1px solid var(--thm-primary);
    border-radius: 50px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    top: 5px;
    left: 0;
}

.footer-item .footer-contact .top ul li:hover i {
    color: #fff;
    background-color: var(--thm-primary);
    border: 1px solid var(--thm-primary);
}

.footer-item .footer-contact .top ul li a {
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: #18253e;
}

.footer-section.style-two .footer-item .footer-contact .top ul li a {
    color: #fff;
}

.footer-item .footer-contact .top ul li p {
    margin-bottom: 0;
}

.footer-section.style-two .footer-item .footer-contact .top ul li p {
    color: #fff;
}

.footer-item .footer-contact .top ul li span {
    display: block;
    font-size: 14px;
}

.footer-section.style-two .footer-contact .top ul li span {
    color: #dccfcf;
}

.footer-item .footer-contact .bottom {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 25px -7px #e1e0e0;
    box-shadow: 0px 0px 25px -7px #e1e0e0;
    padding: 15px;
    text-align: center;
    border-radius: 5px;
}

.footer-section.style-two .footer-item .footer-contact .bottom {
    background-color: rgb(255 255 255 / 15%);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.footer-item .footer-contact .bottom i {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #f93e45;
    font-size: 16px;
    background-color: #ffe5e6;
    border-radius: 50%;
    margin-bottom: 10px;
}

.footer-section.style-two .footer-item .footer-contact .bottom i {
    color: rgb(255 255 255 / 47%);
    background-color: rgb(255 255 255 / 14%);
}

.footer-item .footer-contact .bottom a {
    display: block;
    color: #f93e45;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 3px;
}

.footer-section.style-two .footer-item .footer-contact .bottom a {
    font-weight: 700;
}

.footer-item .footer-contact .bottom span {
    font-size: 15px;
    display: block;
}

.footer-section.style-two .footer-item .footer-contact .bottom span {
    color: #fff9;
}

.footer-item .footer-subscribe-widget p {
    color: #9d9d9d;
}

.footer-item .footer-subscribe-widget form {
    margin-top: 30px;
    position: relative;
}

.footer-item .footer-subscribe-widget input {
    height: 45px;
    width: 100%;
    padding-left: 20px;
    border: 2px solid #313131;
    background-color: transparent;
}

.footer-item .footer-subscribe-widget input::placeholder {
    color: #fff;
    font-size: 14px;
}

.footer-item .footer-subscribe-widget button {
    top: 0;
    right: 0;
    width: 60px;
    padding: 0;
    color: #fff;
    height: 45px;
    border: none;
    position: absolute;
    background-color: var(--thm-primary);
    transition: 0.3s all ease-in-out;
}

.footer-item .footer-subscribe-widget button:hover {
    background-color: #fff;
    color: #000;
}

.footer-item .footer-subscribe-widget .office-open-hour {
    margin-top: 20px;
}

.footer-item .footer-subscribe-widget .office-open-hour span {
    color: #fff;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
    font-weight: 700;
    text-decoration: underline;
}

.footer-item .footer-subscribe-widget .office-open-hour p {
    font-size: 14px;
    color: #9d9d9d;
    max-width: 180px;
}

.copyright-area {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #f4f4f4;
    text-align: center;
    margin-top: 70px;
}

.footer-section.style-two .copyright-area {
    border-top: 1px solid rgb(244 244 244 / 12%);
}

.copyright-area p {
    margin-bottom: 0;
}

.activity-block {
    display: block;
    width: 100%;
    background: #fff;
    padding: 0;
    border-radius: 4px;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #e6eaf3;
}

.activity-block.style-2 {
    border-radius: 6px;
    border: none;
    border-radius: 6px;
    border: none;
}

.activity-block.style-2 .activity-block-header {
    padding: 30px 40px;
    border: none;
}

.activity-block.style-2 .activity-block-header h4 {
    margin: 0;
    font-family: var(--thm-headline-font);
    font-weight: 500;
    font-size: 22px;
    color: #000;
}

.activity-block.style-2 .activity-block-header h4:after {
    font-family: "Font Awesome 5 Pro";
    content: "\f107";
    position: relative;
    float: right;
    margin-right: 0;
    transition: 0.2s ease-out;
    background: #f5f7fb;
    width: 26px;
    height: 26px;
    font-weight: 600;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-body {
    line-height: 1.7;
    display: inline-block;
    width: 100%;
}

.activity-block.style-2 .block-body {
    padding: 0 40px 30px;
}

ul.activity-detail-features {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

ul.activity-detail-features li {
    width: 33.333333%;
    flex: 0 0 33.333333%;
    display: inline-block;
    color: #747474;
    margin-bottom: 1.2rem;
    line-height: 1.5;
}

ul.activity-detail-features li strong {
    color: #000;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    margin-right: 10px;
}

#agent-block .activity-agent-content {
    margin-top: 0;
}

.activity-amenities {
    margin: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.activity-amenities li {
    display: block;
    list-style: none;
    position: relative;
    margin: 10px 0;
    padding-left: 30px;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.activity-amenities li:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    position: absolute;
    color: var(--thm-primary);
    width: 20px;
    height: 20px;
    background: rgb(227 0 42 / 10%);
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    left: 0;
    top: 5px;
    font-size: 10px;
}

.accordion {
    border: 1px solid #e2e6ea;
    border-radius: 4px;
}

.accordion .card {
    border: none;
}

.card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
    background: #fff;
    border: none;
    border-top: 1px solid #e9edf3;
    border-radius: 0;
}

.card-header {
    border-bottom: 1px solid #e2e6ea;
}

.btn.btn-link.collapsed,
.btn.btn-link:focus {
    text-decoration: none !important;
    box-shadow: none;
}

.accordion .btn-link {
    color: #5c657f;
    text-decoration: none;
    width: 100%;
    text-align: left;
    padding: 3px 0;
    font-size: 15px;
    font-weight: 600;
    overflow: hidden;
}

.accordion .btn-link > span {
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
    background: #e8ecf1;
    padding: 6px 15px;
    border-radius: 2px;
}

.accordion .btn-link > span i {
    color: #7690b0;
}

ul.list-gallery-inline {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
}

ul.list-gallery-inline li {
    width: 33.333333%;
    flex: 0 0 33.33333%;
    padding: 5px;
}

ul.list-gallery-inline li a {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
}

.sides-widget-header {
    position: relative;
    display: flex;
    background: #ff5520;
    padding: 1.5rem 1.4rem;
    color: #fff;
    align-items: center;
    font-family: var(--thm-headline-font);
}

.simple-form .form-control,
.modal-dialog-centered .form-control {
    background: #efeff0;
    box-shadow: none;
}

.input-with-icon {
    position: relative;
    width: 100%;
}

.input-with-icon input {
    height: 50px;
    border: none;
    border-radius: 8px;
    background-color: #a0a2a7;
    padding-left: 45px;
}

.input-with-icon i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 18px;
    color: #a2a9bf;
    font-style: normal;
    cursor: normal;
    pointer-events: none;
}

.sides-widget-header .agent-photo {
    float: left;
    display: block;
}

.sides-widget-header .agent-photo img {
    border-radius: 50%;
    max-width: 60px;
}

.sides-widget-details {
    padding-left: 20px;
    display: block;
    position: relative;
}

.sides-widget-details h4 {
    margin-bottom: 2px;
    color: #fff !important;
    font-size: 20px;
}

.sides-widget-details h4 > a {
    color: #fff !important;
}

.sides-widget-details span {
    color: #eee;
    font-size: 14px;
}

.sides-widget-body {
    margin-top: 28px;
}

.simple-form button {
    color: #fff;
    width: 100%;
    height: 50px;
    border: none;
    font-weight: 700;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #ff5520;
}

.activity-contact-info {
    position: relative;
    display: block;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.activity-contact-info-list li {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eef1fe;
}

.activity-contact-info-list li:last-child {
    border-bottom: none;
}

.sidebar-map-contact-info .contact-info {
    position: relative;
}

.activity-contact-info-list li i {
    font-size: 15px;
    color: #ff5520;
    padding-right: 12px;
}

.activity-contact-info-social {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 20px;
}

.activity-contact-info-social a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    width: 40px;
    height: 40px;
    background-color: #fafafa;
    color: #ff5520;
    font-size: 15px;
    border-radius: 50%;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.activity-contact-info-social a:hover {
    background: #ff5520;
    color: #fff;
}

.activity-contact-info-social a + a {
    margin-left: 10px;
}

.activity-contact-info .direction-btn {
    background-color: #ff5520;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
    transition: all 500ms ease;
    position: absolute;
    top: -60px;
    right: 0;
    padding: 10px 20px;
    line-height: 1;
}

.header-style-two.fixed {
    position: relative;
    display: block;
}

/*================================================
Activity Filter Section
==================================================*/

.activity-list-wrap {
    position: relative;
    display: block;
    overflow: hidden;
}

.activity-list-wrap .container-full-width {
    position: relative;
    width: 100%;
}

.activity-list-content {
    position: relative;
    display: block;
    width: 45%;
    padding: 40px 40px 0;
    float: left;
    background-color: #f2f2f4;
}

.activity-list-left-form {
    padding: 30px;
    background-color: #fff;
    margin-bottom: 30px;
}

.activity-list-left-form .input-box {
    margin-bottom: 30px;
}

.activity-list-left-form .input-box input[type="text"] {
    height: 50px;
    width: 100%;
    border: none;
    padding-left: 20px;
    border-radius: 8px;
    background-color: #efeff0;
}

.activity-list-left-form .input-box .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    height: 65px;
}

.activity-list-left-form .input-box .bootstrap-select > .dropdown-toggle {
    width: 100%;
    height: 65px;
    line-height: 65px;
    border: 0px solid #f5f0e9;
    padding: 0 30px;
    outline: none;
    border-radius: 4px;
    background: #f0f3f6;
    color: #aaa;
    font-size: 16px;
    font-weight: 500;
}

.activity-list-left-form .input-box .nice-select {
    height: 50px;
    width: 100%;
    border: none;
    font-size: 16px;
    padding-left: 20px;
    border-radius: 8px;
    background-color: #efeff0;
    line-height: 50px;
}

.filter-by {
    position: relative;
    display: block;
    margin-bottom: 30px;
    padding: 30px;
    border: none;
    background-color: #fff;
}

.filter-by h3 {
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 24px;
    font-family: var(--thm-headline-font);
    color: #000;
    font-size: 24px;
    font-weight: 700;
}

.single-tags-check-box + .single-tags-check-box {
    margin-top: 10px;
}

.single-tags-check-box label {
    position: relative;
    display: inline-block;
    padding-left: 40px;
    margin-right: 0px;
    margin-bottom: 0;
    font-size: 16px;
    color: #828892;
    line-height: 30px;
    font-weight: 500;
    cursor: pointer;
}

.single-tags-check-box input[type="checkbox"] {
    display: none;
}

.single-tags-check-box input[type="checkbox"] + label span {
    position: absolute;
    display: block;
    top: 4px;
    left: 0;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    background-color: #efeff0;
    cursor: pointer;
    border-radius: 4px;
    transition: all 300ms ease;
}

.single-tags-check-box label span:before {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    color: #ffffff;
    font-size: 10px;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    opacity: 0;
    font-weight: 900;
    background-color: transparent;
    transition: all 300ms ease;
}

.single-tags-check-box input[type="checkbox"]:checked + label span {
    border-color: #16232a;
    background: #ff5520;
}

.single-tags-check-box input[type="checkbox"]:checked + label span:before {
    opacity: 1;
}

.activity-list-three-page.one .activity-list-three-page-single .activity-list-three-page-content .title p {
    font-size: 16px;
    line-height: 25px;
}

.activity-list-map {
    position: relative;
    display: block;
    width: 55%;
    background: #ddd;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    float: right;
}

.activity-list-map .google-map {
    width: 100%;
    height: 100%;
    border: none;
}

.activity-block.filter-by .activity-block-header {
    padding: 0;
    border: none;
}

.filter-by .panel-collapse {
    padding-top: 24px;
}

.activity-list-btn button {
    color: #fff;
    width: 100%;
    border: none;
    height: 50px;
    border-radius: 8px;
    font-weight: 700;
    background-color: var(--thm-primary);
}

.activity-list-btn button:hover {
    background-color: #ff5520;
    transition: 0.3s all ease-in-out;
}

/*================================================
Filter Section
==================================================*/

.filter-inner-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid #dee2e8;
    padding-bottom: 30px;
    padding-top: 30px;
}

.filter-inner-content.pt-0 {
    border-top: none;
    padding-top: 0;
}

.filter-inner-content .left {
    display: flex;
    align-items: center;
}

.filter-inner-content .left .left-icon {
    display: flex;
    align-items: center;
}

.filter-inner-content .left .left-icon a {
    font-size: 21px;
    color: #dee2e8;
    line-height: 30px;
}

.filter-inner-content .left .left-icon a.active {
    color: #fff;
}

.filter-inner-content .left .left-icon a.list-icon {
    font-size: 18px;
    position: relative;
    margin-left: 10px;
    background: #ccd3e0;
    padding: 5px 10px;
    border-radius: 4px;
}

.filter-inner-content .left .left-text {
    margin-left: 30px;
}

.filter-inner-content .left .left-text h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
}

.filter-inner-content .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 265px !important;
    height: 65px;
}

.filter-inner-content .bootstrap-select > .dropdown-toggle {
    max-width: 265px;
    width: 100%;
    height: 65px;
    line-height: 65px;
    border: 0px solid #f5f0e9;
    padding: 0 30px;
    outline: none;
    border-radius: 4px;
    background: #f0f3f6;
    color: #aaa;
    font-size: 14px;
    font-weight: 500;
}

/*================================================
Activity Types Section
==================================================*/

.activity-details-section-slide {
    position: relative;
    display: block;
    padding-top: 90px;
}

.owl-carousel {
    touch-action: manipulation;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: none;
}

.activity-block.style-3 {
    display: flex;
    align-items: center;
    border: none;
}

.block-flex-thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f5fd;
    border-radius: 6px;
    padding: 1rem;
}

.block-flex {
    padding-left: 2rem;
}

.activity-types {
    font-size: 12px;
    padding: 4px 12px;
    margin-top: 5px;
    border-radius: 50px;
    background: #efefef;
    display: inline-block;
}

.activity-types.sale {
    background: rgb(227 0 42 / 10%);
    color: var(--thm-primary);
}

.activity-price-fix {
    line-height: 1.4;
    color: #0fca98;
}

.activity-price-fix sub {
    font-size: 15px;
    font-weight: 400;
    color: #929fc5;
    bottom: 0;
}

.activity-features {
    display: flex;
    width: 100%;
    right: 3px;
    padding-top: 5px;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    justify-content: inherit;
}

.activity-features .icon-text {
    display: inline-block;
    padding-left: 0;
    position: relative;
    vertical-align: top;
    width: 33.333333%;
    flex: 0 0 33.333333%;
    margin-bottom: 0;
    font-size: 14px;
    color: #616e96;
    font-weight: 600;
}

.activity-features .icon-text {
    width: auto;
    flex: inherit;
    display: inline-flex;
    margin-right: 1.2rem;
    align-items: center;
}

.activity-features .icon-text .icon {
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.activity-features .icon-text .icon.two {
    background: none;
}

.activity-features .icon-text .icon.two i {
    color: var(--thm-primary);
}

.activity-features .icon-text .icon i {
    font-size: 14px;
}

.activity-detail-title-desc h3 {
    font-family: var(--thm-headline-font);
    font-size: 26px;
    font-weight: 700;
    margin: 0;
    padding-bottom: 5px;
}

.activity-price-fix {
    display: inline-table;
    text-align: right;
    padding-left: 10px;
    vertical-align: middle;
}

.activity-detail-title-desc span i {
    color: #929eb3;
    font-weight: 600;
}

.act-location {
    display: inline-block;
    line-height: 18px;
    vertical-align: middle;
    padding-left: 10px;
}

/*================================================
About
==================================================*/

.about-video {
    padding-top: 20px;
}

.about-text .about-video-area {
    width: 150px;
    height: 90px;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 25px;
}

.about-text .about-video-area a {
    top: 50%;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
    color: #737373;
    margin: 0 auto;
    line-height: 42px;
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    border: 4px solid #fff;
    transform: translateY(-50%);
    font-size: 30px;
}

.about-text .about-video-text {
    padding-top: 8px;
    max-width: 280px;
    display: inline-block;
}

.about-text .about-video-text h3 {
    color: #1f2230;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 8px;
}

.about-text .about-video-text p {
    font-size: 15px;
    line-height: 1.6;
}

/*================================================
Pricing Section
==================================================*/

.pricing-section {
    padding: 100px 0px;
}

.single-price-item {
    background: #fff;
    text-align: center;
    padding: 30px 50px;
    margin-top: 50px;
    border: 7px solid #eee;
    transition: 0.3s;
}

.single-price-item h5 {
    font-size: 22px;
    font-weight: 800;
    color: #000;
    position: relative;
    margin: 20px 0;
}

.single-price-item h6 {
    font-size: 17px;
    line-height: 22px;
    color: #777;
}

.single-price-item h5:before {
    position: absolute;
    content: "";
    width: 54px;
    height: 2px;
    bottom: -10px;
    left: 0;
    right: 0;
    background: var(--thm-primary);
    margin: 0 auto;
}

.single-price-item p b {
    font-size: 35px;
    color: var(--thm-primary);
    margin-right: 10px;
}

.single-price-item p {
    font-size: 18px;
    margin: 40px 0px;
}

.price-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.price-list ul li {
    display: block;
    margin: 30px 0;
    color: #000;
    font-size: 15px;
    position: relative;
    padding-left: 20px;
    transition: 0.3s;
}

.single-price-item .main-btn {
    display: inline-block;
    margin: 30px 0 0;
    padding: 15px 30px;
    text-transform: uppercase;
    color: var(--thm-primary);
    border: 2px solid var(--thm-primary);
    border-radius: 50px;
}

.single-price-item.active {
    border: 7px solid var(--thm-primary);
}

/*================================================
FAQ 2 Section
==================================================*/

.faq-v2 {
    padding: 95px 0px;
    background-color: #f9f8f8;
}

.faq-v2 .accordion {
    border: none;
}

.faq-v2 .faq-wrapper .card {
    background-color: #fff;
    box-shadow: 0px 8px 25px 0px rgb(172 176 181 / 20%);
    border: none;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid #eaeaea;
    margin-bottom: 20px;
    border-radius: 24px !important;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.faq-v2 .faq-wrapper .card:hover,
.faq-v2 .faq-wrapper .card:not(:first-of-type):not(:last-of-type):hover {
    border-color: #e11d07;
}

.faq-v2 .faq-wrapper .card:not(:first-of-type):not(:last-of-type) {
    border: 2px solid #eaeaea;
}

.faq-v2 .faq-wrapper .card .card-header {
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: #828892;
    padding: 21px 30px;
}

.faq-v2 .faq-img {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 20%);
}

.faq-v2 .faq-wrapper .card .card-header .toggle_btn {
    float: left;
    margin-right: 15px;
}

.faq-v2 .faq-wrapper .card .card-header .toggle_btn:after {
    content: "";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    display: inline-block;
    font-size: 15px;
    color: #0e1b2c;
    transition: all 0.3s ease-out 0s;
}

.faq-v2 .faq-wrapper .card .card-body {
    padding-top: 0px;
}

.faq-v2 .faq-wrapper .card .card-header[aria-expanded="true"] .toggle_btn:after {
    content: "";
    color: var(--thm-primary);
}

.faq-v2 .faq-wrapper .card .card-header[aria-expanded="true"] {
    color: var(--thm-primary);
}

.mb-30 {
    margin-bottom: 30px;
}

/*================================================
Submit Section
==================================================*/

.submit-activity-section {
    padding: 100px 0px;
    background-color: #f2f2f4;
}

.submit-activity-2-section {
    padding: 100px 0px;
    background-color: #f2f2f4;
}

.submit-activity-2-section .activity-block:first-child {
    margin: 0;
}

.submit-activity-section .submit-activity-form-area {
    padding: 35px;
    background-color: #fff;
}

.submit-activity-form {
    position: relative;
}

.submit-activity-form .activity-form-input {
    flex-wrap: wrap;
    margin: 0px -7px;
}

.submit-activity-form .activity-form-input-field {
    padding: 0px 7px;
}

.submit-activity-form input,
.payment-form input[type="text"],
.payment-form input[type="email"],
.payment-form .nice-select {
    height: 45px;
    border: none;
    padding-left: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #efeff0;
    width: 100%;
}

.submit-activity-form input:focus {
    background-color: #fafafa;
}

.submit-activity-form .nice-select {
    height: 50px;
    width: 100%;
    border: none;
    font-size: 16px;
    padding-left: 20px;
    border-radius: 8px;
    background-color: #efeff0;
}

.submit-activity-form textarea {
    width: 100%;
    border: none;
    height: 165px;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #efeff0;
}

.submit-activity-form button {
    color: #fff;
    width: 170px;
    height: 50px;
    border: none;
    font-weight: 700;
    border-radius: 40px;
    background-color: var(--thm-primary);
}

.submit-activity-form button i {
    margin-left: 5px;
}

.submit-activity-section .activity-widget-item .activity-table-widget .activity-table-item:hover {
    background: #fafafa;
    padding-left: 15px;
    position: relative;
    transition: 0.4s all ease-in-out;
}

.submit-activity-section .activity-widget-item .activity-table-widget .activity-table-item {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eef1fe;
}

.submit-activity-section .activity-widget-item .activity-table-widget .activity-table-item:last-child {
    border-bottom: none;
}

.form-submit:first-child {
    padding-top: 0;
}

.form-submit {
    padding-top: 15px;
}

.no-ul-list {
    padding: 0;
}

.no-ul-list.first-row,
.no-ul-list {
    display: table;
    width: 100%;
    margin-bottom: 15px;
    list-style: none;
}

ul:last-child {
    margin: 0;
}

ul:last-child {
    margin: 0;
}

.no-ul-list.third-row li {
    max-width: 50%;
    float: left;
    margin-bottom: 7px;
    flex: 0 0 50%;
}

.checkbox-custom,
.radio-custom {
    opacity: 0;
    position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
    display: flex;
    align-items: center;
    margin: 5px;
    cursor: pointer;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
    display: flex;
    align-items: center;
    margin: 5px;
    cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
    position: relative;
}

.checkbox-custom + .checkbox-custom-label:before {
    content: "";
    background: #fff;
    border: 2px solid #e0e0e0;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    background: #efeff0 !important;
    border-radius: 2px;
    box-shadow: none;
    color: #888;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-weight: 600;
}

/*================================================
Fact Section
==================================================*/

@media (min-width: 992px) {
    * + .group-fact-counter {
        margin-top: 40px;
    }
}

* + .group-fact-counter {
    margin-top: 30px;
}

.group-fact-counter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -25px;
    margin-left: -33px;
}

.group-fact-counter > * {
    margin-top: 0;
    margin-bottom: 25px;
    margin-left: 33px;
}

.fact-counter-main {
    display: flex;
    font-size: 48px;
    font-weight: 900;
    line-height: 1;
    letter-spacing: -0.02em;
}

.fact-counter-secondary .fact-counter-main {
    color: #d95a5a;
}

* + .fact-counter-title {
    margin-top: 4px;
}

.fact-counter-title {
    color: #9399a9;
}

.fact-counter-primary .fact-counter-main {
    color: #273252;
}

.text-primary {
    color: var(--thm-primary) !important;
}

.fact-counter-corporate.bg-white .fact-counter-main {
    color: #d95a5a;
}

.fact-counter-corporate .fact-counter-main {
    font-size: 52px;
}

.figure-image {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 20%);
}

.counter-section {
    padding: 170px 0;
    overflow: visible;
}

@media (min-width: 1200px) {
    .fact-counter-corporate {
        padding-left: 33px;
        padding-right: 33px;
    }
}

.fact-counter-corporate {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 2px 2px 10px rgb(47 47 47 / 5%);
    padding: 35px 20px 29px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.bg-white {
    background-color: #ffffff !important;
}

.bg-primary {
    background-color: rgba(244, 37, 52, 0.1) !important;
}

.fact-counter-icon {
    color: #273252;
}

.fact-counter-icon i {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #fff;
    color: var(--thm-primary);
    font-size: 60px;
    line-height: 90px;
    text-align: center;
    display: inline-block;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.fact-counter-corporate * + .fact-counter {
    margin-top: 20px;
}

.bg-decorative-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 75vw;
    width: auto;
}

/*================================================
Features Section
==================================================*/

.features-section-2 {
    position: relative;
    padding: 100px 0px;
    background: #fafafa;
}

.features-section .title-column {
    position: relative;
    margin-bottom: 30px;
}

.features-section .title-column .inner-column {
    position: relative;
    padding-right: 20px;
}

.features-section .title-column .sec-title {
    margin-bottom: 20px;
}

.features-section .title-column .text {
    position: relative;
}

.features-section .title-column .text p {
    position: relative;
    color: #555555;
    font-size: 16px;
    line-height: 1.8em;
    margin-bottom: 15px;
}

.features-section .title-column .text p:last-child {
    margin-bottom: 0px;
}

.features-section .blocks-column {
    position: relative;
    margin-bottom: 30px;
}

.features-section .blocks-column .inner-column {
    position: relative;
}

.features-section-2 .feature-block {
    position: relative;
    margin-bottom: 50px;
}

.features-section-2 .feature-block .inner-box {
    position: relative;
    padding: 22px 22px;
    background-color: #fff;
    border-radius: 12px;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 2%);
}

.features-section-2 .feature-block .inner-box:hover {
    border-color: var(--thm-primary);
}

.features-section-2 .feature-block .inner-box .icon {
    position: absolute;
    left: -20px;
    top: -20px;
    width: 90px;
    height: 90px;
    color: var(--thm-primary);
    font-size: 60px;
    line-height: 90px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.features-section-2 .feature-block .inner-box:hover .icon {
    -webkit-transform: scale(-1) rotate(180deg);
    -moz-transform: scale(-1) rotate(180deg);
    -ms-transform: scale(-1) rotate(180deg);
    -o-transform: scale(-1) rotate(180deg);
    transform: scale(-1) rotate(180deg);
}

.features-section-2 .feature-block .inner-box .content {
    position: relative;
    padding-left: 70px;
}

.features-section-2 .feature-block .inner-box h5 {
    position: relative;
    font-weight: 700;
    line-height: 1.4em;
    font-size: 22px;
}

.features-section-2 .feature-block .inner-box h5 a {
    position: relative;
    color: #222222;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.features-section-2 .feature-block .inner-box:hover h5 a {
    color: var(--thm-primary);
}

.features-section-2 .feature-block .inner-box .text {
    position: relative;
    font-weight: 400;
    line-height: 1.4em;
    font-size: 16px;
    color: #555555;
    margin-top: 10px;
}

.bg-white {
    background-color: #fff !important;
}

.agent-top {
    margin-bottom: 25px;
}

.agent-top .agent-title-text {
    margin-top: 30px;
    padding-left: 40px;
}

/*================================================
Attribute Section
==================================================*/

.attribute-box {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.attribute-box .attribute-box-columns {
    padding: 10px 0;
    min-width: 25%;
}

.attribute-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.attribute_style .attribute-icon {
    color: rgba(67, 195, 112, 1);
}

.attribute_style .attribute-icon {
    display: block;
    text-align: center;
    margin-bottom: 5px;
    line-height: 24px;
    font-size: 27px;
}

.attribute-content .attribute-icon {
    margin-bottom: 0;
    font-size: 34px;
    color: var(--thm-primary);
    background: rgb(35 77 212 / 6%);
    width: 48px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    border-radius: 12px;
}

.attribute-content .attribute-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 14px;
}

.attribute-content .attribute-item .attribute-value {
    font-weight: 600;
}

#facilities-block .attribute-content .attribute-item .attribute-value {
    font-size: 14px;
}

.activity-agent-content .activity-agent-text .agent-details-social {
    margin-top: 10px;
}

.activity-agent-content .activity-agent-text .agent-details-social a {
    font-size: 18px;
    margin-right: 15px;
}

.activity-agent-content .activity-agent-text .agent-details-social a:nth-child(1) {
    color: #3b5998;
}

.activity-agent-content .activity-agent-text .agent-details-social a:nth-child(2) {
    color: #1da1f2;
}

.activity-agent-content .activity-agent-text .agent-details-social a:nth-child(3) {
    color: #0077b5;
}

.activity-agent-content .activity-agent-text .agent-details-social a:nth-child(4) {
    color: #5973e9;
}

.featured-activity-section {
    padding: 95px 0px 100px;
    background-color: #fff;
}

/*================================================
Categories Section
==================================================*/

.categories-item {
    background: #ffffff;
    overflow: hidden;
    padding: 30px;
    display: block;
    color: #505050;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 6%);
}

.categories-item .icon-part {
    float: left;
    margin-right: 25px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 100%;
    background: rgba(245, 245, 245, 0.7);
    text-align: center;
    transition: all 0.3s ease;
}

.categories-item .icon-part i {
    -webkit-transition: all 0.4s ease;
    transform: scale(1);
    font-size: 40px;
    color: var(--thm-primary);
}

.categories-item:hover {
    -webkit-transition: all 0.4s ease;
    transform: scale(1);
    background: #fafafa;
}

.categories-item .content-part .title {
    color: #000;
    margin-bottom: 5px;
    font-size: 22px;
    font-weight: 700;
    transition: all 0.3s ease;
}

.contact-area {
    padding: 100px 0;
}

.casaroyal-promo-block {
    padding: 60px;
    border-radius: 4px;
    background-color: var(--thm-primary);
}

.casaroyal-promo-block h2 {
    line-height: 1.06em;
    font-size: 3.474em;
    color: #fff;
    font-weight: 800;
}

.casaroyal-promo-block p {
    color: #fafafa;
    padding-top: 10px;
    padding-bottom: 20px;
}

.casaroyal-promo-block .casaroyal-button-container {
    margin-bottom: 0;
}

.casaroyal-button-container a.btn-casaroyal {
    padding: 16px 40px;
    font-size: 16px;
    line-height: 24px;
    background: #000;
    color: #fff;
}

/*================================================
Payment Section
==================================================*/

.submit-payment-section {
    padding: 100px 0px;
    background-color: #f2f2f4;
}

.submit-payment-content {
    padding: 35px;
    background-color: #fff;
}

.payment-info {
    background-color: #ff5520;
    color: #fff;
    padding: 20px;
    font-size: 16px;
    font-weight: 300;
    margin: 0px 0px 30px 0px;
}

.payment-info strong {
    color: #fff;
    font-weight: 400;
}

.payment-form {
    margin: 0px 0px 30px 0px;
}

.payment-form:after {
    clear: both;
    content: "";
    display: block;
}

.payment-form .nice-select {
    clear: inherit;
}

.gateway {
    margin: 0px 0px 30px 0px;
}

.gateway-header {
    background-color: rgba(0, 0, 0, 0.04);
    padding: 15px 30px;
}

.gateway-content,
.gateway-info {
    padding: 30px;
    border: 1px solid #eee;
}

.gateway-header label {
    margin: 0px;
}

.gateway-content label {
    display: block;
    font-size: 15px;
}

.gateway-content:after {
    clear: both;
    content: "";
    display: block;
}

.submission-payment-back {
    float: left;
    display: block;
    padding-top: 7px;
}

.submission-payment-back a {
    border-bottom: 1px solid #25b45b;
    text-decoration: none;
}

.submission-payment-back a:hover {
    color: #25b45b;
    text-decoration: none !important;
}

.submission-payment-process {
    float: right;
}

.gateway-paypal-credit-card-first-name {
    clear: left;
    float: left;
    width: 48%;
}

.gateway-paypal-credit-card-last-name {
    clear: right;
    float: right;
    width: 48%;
}

.gateway-paypal-credit-card-number {
    float: left;
    width: 48%;
}

.gateway-paypal-credit-card-cvv {
    float: left;
    margin-left: 4%;
    width: 15%;
}

.gateway-paypal-credit-card-expires {
    float: left;
    margin-left: 4%;
    width: 29%;
}

.gateway-paypal-credit-card-expires-month {
    float: left !important;
    width: 48% !important;
    line-height: 34px;
    font-size: 16px;
}

.gateway-paypal-credit-card-expires-year {
    float: right !important;
    width: 48% !important;
    line-height: 34px;
    font-size: 16px;
}

.wire-transfer-info {
    margin-bottom: 20px;
}

.wire-transfer-info dt {
    font-size: 15px;
    font-weight: 600;
}

.wire-transfer-section-one {
    float: left;
    width: 45%;
}

.wire-transfer-section-two {
    float: right;
    width: 45%;
}

.alert-info {
    color: #0c5460;
    background-color: #e3002a21;
    border-color: #e3002a21;
}

.submission-payment-process {
    display: inline-block;
    width: 170px;
    color: #fff;
    margin: 0 auto;
    font-weight: 700;
    line-height: 50px;
    background-color: var(--thm-primary);
    text-align: center;
    border: none;
}

.gateway-header input[type="radio"] {
    display: none;
}

.gateway-header input[type="radio"] + label {
    font-weight: 400;
    font-size: 14px;
}

.gateway-header input[type="radio"] + label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: -2px 10px 0 0;
    vertical-align: middle;
    cursor: pointer;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #ffffff;
}

.gateway-header input[type="radio"] + label span {
    background-color: #fff;
}

.gateway-header input[type="radio"]:checked + label {
    color: #333;
    font-weight: 700;
}

.gateway-header input[type="radio"]:checked + label span {
    background-color: #ff8800;
    border: 2px solid #ffffff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.gateway-header input[type="radio"] + label span,
input[type="radio"]:checked + label span {
    -webkit-transition: background-color 0.24s linear;
    -o-transition: background-color 0.24s linear;
    -moz-transition: background-color 0.24s linear;
    transition: background-color 0.24s linear;
}

/*================================================
Blog Detail Section
==================================================*/

.blog-details-thumb-section {
    padding: 180px 0px 40px;
}

.blog-details-thumb-section .blog-details-thumb-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #030014;
}

.blog-details-thumb-content {
    z-index: 1;
    position: relative;
}

.blog-details-thumb-content h1 {
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
    padding-bottom: 10px;
}

.blog-details-thumb-content .blog-details-thumb-item .blog-details-thumb {
    justify-content: center;
    background-color: transparent;
}

/*================================================
Lang TopBar
==================================================*/

.lang-quote {
    position: relative;
    display: inline-block;
}

.lang-quote .language {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}

.lang-quote .language i {
    font-size: 20px;
    margin-right: 5px;
    color: #0c2957;
}

.lang-quote .language .nice-select {
    padding: 0px;
    border: none;
    text-transform: uppercase;
    color: #000;
    padding-right: 25px;
    padding-left: 5px;
    height: auto !important;
    line-height: 1;
}

.lang-quote .language .nice-select .current {
    font-size: 16px;
}

.lang-quote .language .nice-select .list {
    border-radius: 0px;
    margin-left: -17px;
    box-shadow: 0 0 10px 3px rgb(0 0 0 / 5%);
}

.lang-quote .language .nice-select:after {
    height: 6px;
    width: 6px;
}

.lang-quote:after {
    top: 0;
    right: -3px;
    width: 2px;
    content: "";
    height: 18px;
    position: absolute;
    background-color: #d9d9d9;
}

/*================================================
Featured Activity
==================================================*/

.activity-featured-section {
    padding: 95px 0px 100px;
    background-color: #f9f8f8;
}

.activity-featured-slide .owl-item {
    opacity: 0;
    transition: opacity 500ms;
}

.activity-featured-slide .owl-item.active {
    opacity: 1;
}

.activity-featured-slide .owl-nav {
    right: 0;
    top: -110px;
    position: absolute;
}

.activity-featured-slide .owl-nav .owl-next,
.activity-featured-slide .owl-nav .owl-prev {
    width: 60px;
    height: 60px;
    cursor: pointer;
    line-height: 60px;
    margin-left: 12px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    border: 2px solid var(--thm-primary);
    transition: 0.3s all ease-in-out;
}

.activity-featured-slide .owl-nav .owl-next:hover,
.activity-featured-slide .owl-nav .owl-prev:hover {
    color: #fff;
    background-color: var(--thm-primary);
}

.activity-featured-section .activity-featured-btn {
    height: 50px;
    width: 170px;
    color: #fff;
    margin: 0 auto;
    margin-top: 40px;
    font-weight: 700;
    line-height: 50px;
    border-radius: 50px;
    background-color: var(--thm-primary);
}

.activity-featured-img:before {
    content: "";
    opacity: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    visibility: hidden;
    position: absolute;
    transform: scale(0.5);
    transition: 0.4s all ease-in-out;
    background-color: rgba(0, 0, 0, 0.8);
}

.activity-featured-innerbox:hover .activity-featured-img:before {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
}

.activity-featured-innerbox .activity-featured-img .activity-hover-icon {
    left: 0;
    top: 40%;
    right: 0;
    opacity: 0;
    z-index: 101;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    line-height: 40px;
    visibility: hidden;
    text-align: center;
    position: absolute;
    border-radius: 100%;
    display: inline-block;
    transform: translateY(-50%);
    transition: 0.3s all ease-in-out;
    background-color: rgba(255, 255, 255, 0.8);
}

/*================================================
Explore Section
==================================================*/

.explore-section {
    position: relative;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0;
}

.explore-section:after {
    top: 0;
    right: -30px;
    width: 54%;
    content: "";
    height: 100%;
    z-index: -1;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../img/bg1.jpg);
}

.explore-section:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000c22;
    opacity: 0.8;
}

.explore-section-left {
    position: relative;
    display: block;
}

.explore-section-left .block-title {
    margin-bottom: 30px;
}

.explore-section-left .block-title h2 {
    color: #ffffff;
    font-size: 60px;
    font-weight: 700;
}

.explore-section-left .block-title h4 {
    color: var(--thm-base);
    top: -60px;
    color: rgb(255 255 255 / 5%);
    line-height: 1;
    font-size: 100px;
    font-weight: 900;
    margin-bottom: 0;
    text-align: right;
    position: absolute;
}

.thm-btn {
    display: inline-block;
    vertical-align: middle;
    border: none;
    outline: none;
    background-color: var(--thm-primary);
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    padding: 14px 40px;
    border-radius: 4px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.explore-section-btn .thm-btn:hover {
    background-color: #fff;
    color: var(--thm-primary);
}

.explore-section-boxes {
    position: relative;
    display: block;
}

.explore-section-boxes ul {
    position: relative;
    display: block;
    float: right;
}

.explore-section-boxes ul li {
    position: relative;
    display: inline-block;
    max-width: 120px;
    text-align: center;
    width: 100%;
}

.explore-section-boxes ul li + li {
    margin-left: 16px;
}

.explore-section-boxes ul li .explore-section-icon {
    height: 120px;
    width: 120px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 10px solid #ffffff6e;
    margin-bottom: 23px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.explore-section-boxes ul li .explore-section-icon:hover {
    background: var(--thm-primary);
}

.explore-section-boxes ul li .explore-section-icon i {
    font-size: 55px;
    color: var(--thm-primary);
    line-height: 55px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.explore-section-boxes ul li .explore-section-icon:hover i {
    color: #ffffff;
}

.explore-section-boxes ul li .explore-section-boxes_text p {
    font-size: 18px;
    color: #ffffff;
    margin: 0;
    line-height: 30px;
    text-transform: uppercase;
}

/*================================================
Table
==================================================*/

table {
    width: 100%;
}

table > tbody tr td,
table > tbody tr th,
table > tfoot tr td,
table > tfoot tr th,
table > thead tr td,
table > thead tr th {
    padding: 10px;
    vertical-align: sub !important;
    border: 1px solid #f1f1f1;
}

.pink {
    color: #f56961 !important;
}

table > tbody tr:nth-child(even),
table > tfoot tr:nth-child(even),
table > thead tr:nth-child(even) {
    background: #fbfbfb;
}

/*================================================
Service Section
==================================================*/
.service-section {
    padding: 15px 0px 100px;
}

.service-content {
    margin-top: 25px;
}

.services-area-three {
    position: relative;
    padding: 110px 0;
}

.service_section > div:nth-last-child(-n + 3) {
    margin-top: 60px;
}

.service-part {
    padding-top: 30px;
}

.service_section .service-wrap {
    text-align: center;
    padding: 50px 30px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-radius: 15px;
}

.service_section .service-wrap .service-icon {
    text-align: center;
}

.service_section .service-wrap .service-icon img {
    height: 80px;
    padding: 18px 20px;
    border-radius: 20px;
    background-color: #ef3f3e;
    -webkit-box-shadow: 7.5px 12.99px 40px 0px rgba(239, 63, 62, 0.25);
    box-shadow: 7.5px 12.99px 40px 0px rgba(239, 63, 62, 0.25);
    margin-bottom: 30px;
}

.service_section .service-wrap h5 {
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 8px;
    letter-spacing: 0.001em;
}

.service_section .service-wrap p {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #b3b3b3;
    margin-bottom: -6px;
    line-height: 1.5;
    letter-spacing: 0.01em;
}

.service_section .service-wrap:hover {
    -webkit-box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.service_section .service-wrap:hover .service-icon img {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.service-part .service-wrapper {
    -webkit-box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 0 7px rgb(158 158 158 / 15%);
    padding: 40px;
    border-radius: 20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.service-part .service-wrapper h3 {
    font-size: 22px;
    font-weight: 700;
    color: #000;
    margin-bottom: 10px;
    letter-spacing: 0.01em;
    margin-top: -4px;
    position: relative;
}

.service-part .service-wrapper h3 i {
    position: absolute;
    font-size: 16px;
    right: 0;
    top: 0;
    color: #e1002c;
    background-color: rgba(225, 0, 44, 0.1);
    padding: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 100%;
}

.service-part .service-wrapper h3 i .effect {
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    -webkit-transition: height 5s ease, width 5s ease;
    transition: height 5s ease, width 5s ease;
    z-index: 0;
    opacity: 0;
}

.service-part .service-wrapper h3 i .effect:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid #e1002c;
    -webkit-animation: effect 2s linear 1s infinite;
    animation: effect 2s linear 1s infinite;
}

.service-part .service-wrapper h6 {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 500;
    color: #677283;
    padding: 4px 12px 4px 6px;
    background-color: #eef1f5;
    display: inline-block;
    transition: all 500ms ease;
    border-radius: 24px;
    line-height: 20px;
    text-align: center;
}

.service-part .service-wrapper p {
    margin-bottom: 30px;
}

.service-part .service-wrapper .service-btn {
    text-align: right;
}

.service-part .service-wrapper .service-btn .btn {
    letter-spacing: 0.05em;
}

.service-part .service-wrapper:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.15);
}

.service-part .service-wrapper:hover h3 .effect {
    opacity: 0.5;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.service-part .service-wrapper:hover .service-btn .btn {
    -webkit-box-shadow: 2.5px 4.33px 25px 0px rgba(239, 63, 62, 0.5);
    box-shadow: 2.5px 4.33px 25px 0px rgba(239, 63, 62, 0.5);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.service-part .service-wrapper .service-btn a {
    background-color: var(--thm-primary);
    border-radius: 100px;
    color: white;
    padding: 8px 20px;
}

/*================================================
Provide Section
==================================================*/

.provide-area {
    padding: 105px 0px 50px;
    background-color: #fff;
}

.provide-area .provide-content {
    margin-bottom: 30px;
}

.provide-area .provide-content .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 30px;
}

.provide-area .provide-content .inner .left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.provide-area .provide-content .inner .right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.provide-area .provide-content .inner ul {
    margin: 0;
    padding: 0;
}

.provide-area .provide-content .inner ul li {
    list-style-type: none;
    display: block;
    margin-bottom: 25px;
}

.provide-area .provide-content .inner ul li i {
    display: inline-block;
    font-size: 55px;
    color: #f93e45;
    margin-right: 5px;
    vertical-align: middle;
    line-height: 55px;
}

.provide-area .provide-content .inner ul li h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
}

/*================================================
Author Widget
==================================================*/

.widget-author {
    padding: 35px 30px;
}

.widget-author .thumb {
    margin-bottom: 15px;
}

.widget-author .thumb img {
    height: 115px;
    width: 115px;
    border-radius: 50%;
    display: inline-block;
}

.widget-author .details h5 {
    color: #000;
    font-weight: 600;
    font-size: 20px;
}

.widget-author .details p {
    margin-bottom: 20px;
}

.widget-author .details ul {
    margin: 0;
    padding: 0;
}

.widget-author .details ul li {
    display: inline-block;
    list-style: none;
    margin: 0 8px;
    color: #9ba1b0;
    line-height: 1;
}

.widget-author .details ul li a {
    font-size: 15px;
}

.brochure {
    border: 2px solid #e8ecf1;
    background: #eff2f5;
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px 10px 80px;
    position: relative;
    font-weight: 500;
    color: #444;
}

.brochure a {
    color: #000;
}

.brochure:hover {
    color: #566b86;
    background: #e8ecf1;
}

.brochure i {
    background: #ffffff;
    color: var(--thm-primary);
    font-size: 40px;
    height: 100%;
    left: 0;
    line-height: 68px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 60px;
}

.brochure span {
    font-weight: 400;
    font-size: 15px;
}

.ask-agent-content {
    margin-top: 28px;
}

.ask-agent-content input,
.ask-agent-content textarea {
    width: 100%;
    height: 50px;
    border: none;
    padding-left: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #efeff0;
}

.ask-agent-content input:placeholder {
    color: #555555;
}

.ask-agent-content button {
    color: #fff;
    width: 100%;
    height: 50px;
    border: none;
    font-weight: 700;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #ff5520;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.ask-agent-content button:hover {
    background-color: #000;
    color: #fff;
}

/* Icon Box Css Code  */
.border-bottom {
    border-bottom: 1px solid #ebebeb;
}

.feature-activity {
    position: relative;
    z-index: 1;
    overflow: visible;
}

.feature-content-2 {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 50px;
    margin-top: -90px;
    padding: 0 30px;
    position: relative;
}

.icon-box {
    background: #fff8f5;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 20px 0;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.icon-box .icon i {
    color: var(--thm-primary);
    font-size: 44px;
}

.icon-box:hover {
    background: var(--thm-primary);
    cursor: pointer;
}

.icon-box:hover .icon i,
.icon-box:hover .content-details .title {
    color: #fff;
}

.icon-box .content-details .title {
    color: #222222;
    font-size: 16px;
}

.icon-card {
    border: 2px solid #e8ecf1;
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px 10px 80px;
    position: relative;
    font-weight: 500;
    color: #444;
}

.icon-card a {
    color: #000;
}

.icon-card:hover {
    color: #566b86;
    background: #e8ecf1;
}

.icon-card i {
    background: #ffffff;
    color: var(--thm-primary);
    font-size: 40px;
    height: 100%;
    left: 0;
    line-height: 68px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 60px;
}

.icon-card span {
    font-weight: 400;
    font-size: 15px;
}

/*================================================
About Section
==================================================*/

.about-section-2 {
    position: relative;
    padding: 100px 0px 0 0px;
}

.about-image .image-box {
    position: relative;
    display: block;
    padding: 0px 120px 270px 0px;
}

.about-image .image-box .image {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.2);
}

.about-image .image-box .image img {
    width: 100%;
}

.about-image .image-box .image-2 {
    position: absolute;
    right: 0px;
    bottom: 100px;
}

.about-image .image-box .video-content {
    position: absolute;
    left: 40px;
    bottom: 115px;
    width: 160px;
}

.about-image .image-box .video-content h3 {
    display: block;
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 13px;
}

.about-image .image-box .video-content .video-btn a {
    position: relative;
    display: inline-block;
    font-size: 60px;
    line-height: 60px;
}

.about-image .image-box .video-content .video-btn a i {
    color: var(--thm-primary);
}

.about-image .image-box .image-1 {
    position: relative;
    overflow: hidden;
}

.about-image .image-box:hover .image-1:before {
    -webkit-animation: shine 1s;
    animation: shine 1s;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

.border-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    animation: squares 2.9s linear 0s infinite;
    -webkit-animation: squares 2.9s linear 0s infinite;
    -ms-animation: squares 2.9s linear 0s infinite;
    -o-animation: squares 2.9s linear 0s infinite;
    -webkit-animation-play-state: running;
    -moz-animation-play-state: running;
    -o-animation-play-state: running;
    animation-play-state: running;
    opacity: 0;
}

.border-animation.border-2 {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}

.border-animation.border-3 {
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
}

.about-section .about-content .content-box {
    position: relative;
    margin-left: 40px;
}

.about-content .content-box .text {
    position: relative;
    margin-bottom: 25px;
}

.about-content .content-box .list li {
    position: relative;
    display: block;
    padding-left: 28px;
    margin-bottom: 8px;
}

.about-content .content-box .list li:last-child {
    margin-bottom: 0px;
}

.about-content .content-box .list li:before {
    position: absolute;
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    font-weight: 700;
    left: -20px;
    top: 0px;
    color: #36b37e;
    background: #fef9f3;
    border-radius: 24px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.about-content .content-box .list {
    position: relative;
    margin-bottom: 32px;
}

.about-content .content-box .sec-title {
    margin-bottom: 26px;
}

/*================================================
Testimanial Section 3
==================================================*/

.testimonial.testimonial-3 {
    padding: 130px 0;
    margin-bottom: 0;
}

.testimonial.testimonial-3 .heading .heading-title {
    font-size: 43px;
    line-height: 50px;
    margin-bottom: 34px;
}

.testimonial.testimonial-3 .heading .heading-subtitle {
    font-size: 18px;
    font-weight: 700;
    color: var(--thm-primary);
    position: relative;
}

.testimonial.testimonial-3 .carousel-dots .owl-dots {
    margin-top: 14px;
}

.testimonial {
    border-radius: 4px;
    padding: 54px 0 57px 80px;
    margin-bottom: 63px;
    background-image: url(../img/testimonial/test-bg-aliments.png);
    background-repeat: no-repeat;
    background-position: center;
}

.testimonial .heading {
    margin-bottom: 23px;
}

.testimonial .heading .heading-title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    color: #000;
    margin-bottom: 0;
}

.testimonial .testimonial-item .testimonial-item-inner {
    padding: 58px 50px 52px;
    border-radius: 24px;
    margin-bottom: 20px;
    background-color: #fff;
    position: relative;
    border: 3px solid #eee;
}

.testimonial .testimonial-item .testimonial-item-inner::after {
    content: "";
    position: absolute;
    right: 40px;
    bottom: -19px;
    width: 25px;
    height: 20px;
    background-color: #f7f7f7;
    -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
}

.testimonial .testimonial-item .testimonial-icon {
    position: absolute;
    bottom: 40px;
    color: var(--thm-primary);
    right: 40px;
    font-size: 62px;
}

.testimonial .testimonial-item .testimonial-body {
    position: relative;
}

.testimonial .testimonial-item .testimonial-body .testimonial-img {
    position: absolute;
    top: 45px;
    left: 0;
    width: 70px;
    height: 70px;
    padding: 3px;
    border: 3px solid var(--thm-primary);
    border-radius: 50%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.testimonial .testimonial-item .testimonial-body .testimonial-img img {
    max-width: 100%;
    border-radius: 50%;
}

.testimonial .testimonial-item .testimonial-body .testimonial-content .testimonial-rating {
    margin-bottom: 18px;
    color: var(--thm-primary);
}

.testimonial .testimonial-item .testimonial-body .testimonial-content .testimonial-rating i {
    display: inline-block;
    margin-right: 5px;
}

.testimonial .testimonial-item .testimonial-body .testimonial-content .testimonial-rating i:last-child {
    margin-right: 0;
}

.testimonial .testimonial-item .testimonial-body .testimonial-content p {
    font-size: 18px;
    margin-bottom: 13px;
    color: #222;
}

.testimonial .testimonial-item .testimonial-body .testimonial-content .testimonial-meta {
    position: relative;
}

.testimonial .testimonial-item .testimonial-body .testimonial-content .testimonial-meta h4 {
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 0;
}

.testimonial .testimonial-item .testimonial-body .testimonial-content .testimonial-meta p {
    color: #616161;
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.bg-section {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.bg-parallax {
    background-attachment: fixed;
}

.bg-overlay:before {
    content: "";
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.btn {
    letter-spacing: 0.26px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

/* Individual effect = play-video-button */
.animated-video-popup {
    position: relative;
    display: block;
}

.animated-video-popup .hover-link {
    z-index: 14;
}

.animated-video-popup .thumb {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.animated-video-popup:hover .thumb {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.animated-video-popup .text-holder {
    opacity: 1;
}

.animated-video-popup .text-holder-middle {
    top: 50%;
    margin-top: -36px;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.animated-video-popup:hover .text-holder-middle {
    top: 50%;
    -webkit-transform: scale(1.07);
    -moz-transform: scale(1.07);
    -ms-transform: scale(1.07);
    transform: scale(1.07);
}

.animated-video-popup.animated-video-popup-button-over-image {
    overflow: hidden;
    border-radius: 15px;
}

.animated-video-popup.animated-video-popup-button-over-image:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2);
}

.animated-video-popup.animated-video-popup-button-over-image .video-button-holder {
    position: absolute;
    opacity: 0.8;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.animated-video-popup.animated-video-popup-button-over-image .video-button-text {
    color: #fff;
    position: absolute;
    bottom: 12%;
    z-index: 10;
    text-align: center;
    width: 100%;
}

.animated-video-popup.animated-video-popup-button-over-image .animated-css-play-button {
    z-index: 11;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 1;
    color: #fff;
    font-size: 16px;
    transition: all 0.4s ease 0s;
}

@media (prefers-reduced-motion: reduce) {
    .animated-video-popup.animated-video-popup-button-over-image .animated-css-play-button {
        transition: none;
    }
}

.animated-video-popup.animated-video-popup-button-over-image .animated-css-play-button .play-icon,
.animated-video-popup.animated-video-popup-button-over-image .animated-css-play-button .play-icon:before,
.animated-video-popup.animated-video-popup-button-over-image .animated-css-play-button .play-icon:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    -webkit-animation: videoplayanimation 3s infinite;
    -moz-animation: videoplayanimation 3s infinite;
    -ms-animation: videoplayanimation 3s infinite;
    -o-animation: videoplayanimation 3s infinite;
    animation: videoplayanimation 3s infinite;
}

.animated-video-popup.animated-video-popup-button-over-image .animated-css-play-button .play-icon:before {
    -webkit-animation-delay: 0.9s;
    -moz-animation-delay: 0.9s;
    -ms-animation-delay: 0.9s;
    -o-animation-delay: 0.9s;
    animation-delay: 0.9s;
    content: "";
    position: absolute;
}

.animated-video-popup.animated-video-popup-button-over-image .animated-css-play-button .play-icon:after {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    -ms-animation-delay: 0.6s;
    -o-animation-delay: 0.6s;
    animation-delay: 0.6s;
    content: "";
    position: absolute;
}

@-webkit-keyframes videoplayanimation {
    60% {
        box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes videoplayanimation {
    60% {
        box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.animated-video-popup.animated-video-popup-button-over-image .animated-css-play-button i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: #fff;
}

.animated-video-popup.animated-video-popup-button-over-image:hover .animated-css-play-button {
    background: rgba(255, 51, 51, 0.9);
}

.animated-video-popup.animated-video-popup-button-over-image:hover .video-button-holder {
    opacity: 0.95;
    top: 50%;
    left: 50%;
}

.animated-video-popup.animated-video-popup-button-with-text-right {
    display: inline-block;
}

.animated-video-popup.animated-video-popup-button-with-text-right .thumb {
    margin-right: 10px;
}

.animated-video-popup.animated-video-popup-button-with-text-right .video-button-text {
    line-height: 70px;
}

.animated-video-popup.animated-video-popup-button-with-text-right:hover .thumb {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

.animated-video-popup.animated-video-popup-css-button {
    display: inline-block;
}

.animated-video-popup.animated-video-popup-css-button .animated-css-play-button {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #ff8b5b;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 1;
    margin: 40px;
    color: #fff;
    font-size: 16px;
    transition: all 0.4s ease 0s;
}

@media (prefers-reduced-motion: reduce) {
    .animated-video-popup.animated-video-popup-css-button .animated-css-play-button {
        transition: none;
    }
}

.animated-video-popup.animated-video-popup-css-button .animated-css-play-button:before {
    content: " ";
    display: block;
    position: absolute;
    border-radius: 100%;
    z-index: -1;
    opacity: 0.3;
    width: 110px;
    height: 110px;
    transition: all 0.4s ease 0s;
}

@media (prefers-reduced-motion: reduce) {
    .animated-video-popup.animated-video-popup-css-button .animated-css-play-button:before {
        transition: none;
    }
}

.animated-video-popup.animated-video-popup-css-button .animated-css-play-button:after {
    content: " ";
    display: block;
    position: absolute;
    border-radius: 100%;
    background-color: #ff3333;
    z-index: -1;
    transition: all 0.4s ease 0s;
    animation: video-play-button-animate 1.2s ease-in infinite forwards;
}

@media (prefers-reduced-motion: reduce) {
    .animated-video-popup.animated-video-popup-css-button .animated-css-play-button:after {
        transition: none;
    }
}

.animated-video-popup.animated-video-popup-css-button .animated-css-play-button i {
    font-size: 30px;
    color: #fff;
}

.animated-video-popup.animated-video-popup-css-button:hover .animated-css-play-button {
    width: 82px;
    height: 82px;
}

@keyframes video-play-button-animate {
    0% {
        width: 10px;
        height: 10px;
        opacity: 0.8;
    }

    100% {
        width: 160px;
        height: 160px;
        opacity: 0;
    }
}

.box-hover-effect .effect-wrapper .hover-link {
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: none !important;
    color: transparent !important;
    z-index: 11;
}

.animated-video-popup.animated-css-play-button .animated-css-play-button:before,
.animated-video-popup.animated-video-popup-css-button .animated-css-play-button:before {
    background-color: rgb(253 145 100);
}

.animated-video-popup.animated-css-play-button .animated-css-play-button:after,
.animated-video-popup.animated-video-popup-css-button .animated-css-play-button:after {
    background-color: rgb(214 68 7);
}

/*================================================
Footer CTA
==================================================*/

.footer-cta {
    z-index: 1;
    position: relative;
    overflow: hidden;
}

.footer-cta:before {
    left: 0;
    bottom: 0;
    z-index: -1;
    content: "";
    width: 100%;
    height: 70px;
    position: absolute;
    background-color: #000a1f;
}

.footer-cta-box {
    background-color: var(--thm-primary);
    display: flex;
    position: relative;
}

.footer-cta-box:after {
    content: "";
    height: 100%;
    width: 240px;
    background: rgba(250, 84, 10, 0.2);
    position: absolute;
    bottom: 0;
    z-index: 5;
    left: 400px;
    transform: rotate(360deg);
    clip-path: polygon(17% 0, 29% 0, 100% 100%, 69% 100%);
}

.footer-cta-box .item-img img {
    clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 102%);
}

.footer-cta-box .bannar-details {
    padding: 45px 50px 34px 50px;
}

.footer-cta-box .bannar-details .heading-title {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}

.footer-cta-box .bannar-details .contact-box2 {
    display: flex;
    justify-content: center;
}

.footer-cta-box .bannar-details .contact-box2 .item-icon-box {
    display: flex;
    align-items: center;
}

.footer-cta-box .bannar-details .contact-box2 .item-icon-box .item-icon {
    color: #fff;
    font-size: 30px;
    margin-right: 10px;
    position: relative;
    background: rgb(255 255 255 / 26%);
    border-radius: 24px;
    width: 50px;
    text-align: center;
}

.footer-cta-box .bannar-details .contact-box2 .item-icon-box .banner-content {
    margin-left: 10px;
}

.footer-cta-box .bannar-details .contact-box2 .item-icon-box .banner-content .contact-title {
    color: #fff;
    font-size: 16px;
    margin-bottom: -10px;
}

.footer-cta-box .bannar-details .contact-box2 .item-icon-box .banner-content .contact-info {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}

.footer-cta-box .bannar-details .contact-box2 .item-icon-box2 {
    padding-left: 57px;
    position: relative;
}

.footer-cta-box .bannar-details .contact-box2 .item-icon-box2:after {
    content: "";
    width: 2px;
    height: 46px;
    background-color: #fb5e3b;
    position: absolute;
    bottom: 3px;
    left: 28px;
    border: none;
}

/*================================================
Destination Section
==================================================*/

.destination-section {
    position: relative;
    display: block;
    background-color: #f5f5f5;
    padding: 120px 0 120px;
}

#destination-slider {
    padding-top: 40px;
}

.destination-single {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    display: block;
}

.destination-img {
    position: relative;
    display: block;
    transform: translateY(0px);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.destination-single:hover .destination-img {
    transform: translateY(-10px);
}

.destination-single:hover .destination-content {
    background: #ffece6;
}

.destination-img img {
    width: 100%;
    border-radius: 10px;
}

.destination-text {
    position: absolute;
    bottom: 29px;
    left: 30px;
}

.destination-text p {
    font-size: 20px;
    color: #ffffff;
    margin: 0;
    font-weight: 700;
}

.destination-text p a {
    color: inherit;
    transition: all 500ms ease;
}

.destination-text p a:hover {
    color: var(--thm-base);
}

.destination-btn {
    position: absolute;
    top: 30px;
    left: 30px;
    opacity: 0;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.destination-single:hover .destination-btn {
    opacity: 1;
}

.destination-btn a {
    font-size: 12px;
    color: var(--thm-black);
    font-weight: 700;
    display: inline-block;
    background: #ffffff;
    padding: 4px 15px;
    border-radius: var(--border-radius);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.destination-btn a:hover {
    background: var(--thm-base);
    color: #ffffff;
}

.destination-inner-content {
    position: relative;
    display: block;
    margin-right: -360px;
    z-index: 10;
}

#destination-slider .owl-nav {
    right: 0;
    top: -60px;
    cursor: pointer;
    position: absolute;
}

#destination-slider .owl-nav .owl-next,
#destination-slider .owl-nav .owl-prev {
    width: 60px;
    height: 60px;
    font-size: 18px;
    line-height: 60px;
    margin-left: 25px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    border: 2px solid #ff5520;
    transition: 0.3s all ease-in-out;
}

#destination-slider .owl-nav .owl-next:hover,
#destination-slider .owl-nav .owl-prev:hover {
    color: #fff;
    background-color: #ff5520;
}

.destination-content {
    background: #fff;
    padding: 20px;
    overflow: hidden;
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    border-radius: 10px;
    transition: all 0.3s;
}

.content-left {
    float: left;
}

.content-right {
    float: right;
}

.content-left h5 {
    color: #283a5e;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0;
}

.content-left small {
    color: #687693;
    font-size: 12px;
}

.content-right h5 {
    color: var(--thm-primary);
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
}

.content-right p {
    color: #616f8c;
    font-size: 14px;
}

.destination-right {
    margin-top: -100px;
}

/*================================================
Features Section
==================================================*/

.features-section {
    padding: 55px 0px 100px;
}

.features-section .item-single {
    position: relative;
    padding: 50px 30px;
    overflow: hidden;
    text-align: center;
    background: #fff;
    border-radius: 3px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border: 2px solid #eee;
    border-radius: 10px;
}

.features-section .item-single:hover {
    background: var(--thm-primary);
}

.features-section .item-single::before {
    position: absolute;
    content: "";
    top: 15px;
    right: -10px;
    width: 35px;
    height: 50px;
    background: #9794a8;
    border-radius: 3px;
    z-index: 1;
    opacity: 0.5;
    -webkit-transform: skewX(20deg);
    transform: skewX(2deg);
    background: #ffede6;
}

.features-section .item-single i {
    color: #fff;
    font-size: 34px;
    margin-top: -3px;
    background-color: #ff6525;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    height: 60px;
    margin-bottom: 8px;
    width: 60px;
    text-align: center;
    display: inline-block;
    line-height: 60px;
}

.features-section .item-single:hover i {
    background: #fff;
    color: var(--thm-primary);
}

.features-section .item-single h3 {
    font-size: 22px;
    font-weight: 700;
    color: #000;
    margin-top: 15px;
    margin-bottom: 10px;
}

.features-section .item-single:hover h3 a {
    color: #fff;
}

.features-section .item-single p {
    margin-bottom: 20px;
    color: #090031;
}

.features-section .item-single .btn-primary {
    color: var(--thm-primary);
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.btn-primary {
    text-align: center;
    display: inline-block;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 1;
    color: #fff;
    background-color: var(--thm-primary);
    border: 1px solid var(--thm-primary);
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0 20px 20px -5px rgb(253 80 86 / 20%);
    box-shadow: 0 20px 20px -5px rgb(253 80 86 / 20%);
}

.features-section .item-single:hover .btn-primary {
    color: #fff;
    border: 1px solid #fff;
}

.features-section .item-single:hover .btn-primary:hover {
    color: #000;
    background-color: #fff;
    border: 1px solid #fff;
}

.main-slider-content .slider-main-img:before {
    opacity: 0.6;
    filter: alpha(opacity=30);
    -ms-filter: "alpha(opacity=30)";
    background: -webkit-linear-gradient(0, transparent 0, transparent 24%, #fff 48%, transparent 76%, transparent 100%);
    background: -moz-linear-gradient(0, transparent 0, transparent 24%, #fff 48%, transparent 76%, transparent 100%);
    background: linear-gradient(0, transparent 0, transparent 24%, #fff 48%, transparent 76%, transparent 100%);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
}

/*================================================
Featured Section
==================================================*/
.services-section {
    padding: 100px 0;
    background-color: #fafafa;
}

.featured-icon {
    position: absolute;
}

.featured-icon-box {
    overflow: visible;
    border-radius: 15px;
    text-align: left;
    font-size: 16px;
    background-color: #fff;
    border-radius: 15px;
    padding: 0 25px 30px;
    position: relative;
    margin-top: 55px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.02));
}

.featured-icon-box:before {
    display: block;
    content: "";
    position: absolute;
    border-top: 4px solid;
    border-top-color: transparent;
    top: -15px;
    left: 0px;
    right: 0;
    height: 50px;
    transform: rotate(-5deg);
    background: #fff;
    border-radius: 19px;
    transition: all 0.4s;
    z-index: -1;
}

.featured-icon-box:hover:before {
    transform: rotate(5deg);
}

.featured-icon-box {
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    transition: all 0.4s;
}

.featured-icon-box .icon {
    height: 63px;
    width: 63px;
    line-height: 63px;
    margin-top: -40px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    background-color: var(--thm-primary);
}

.featured-icon-box:hover .icon {
    -webkit-animation: pulse 1s infinite;
    animation: pulse 1s infinite;
    animation-duration: 2s;
}

.featured-icon-box .featured-desc p {
    margin-bottom: 10px;
}

.featured-icon-box .icon i {
    font-size: 35px;
}

.featured-icon-box.icon-align-top-content .featured-content {
    padding-top: 42px;
}

.featured-icon-box .featured-content a {
    border-bottom: 2px double #ff6525;
    padding-bottom: 5px;
}

.featured-icon-box .featured-title h3 {
    font-size: 22px;
    font-weight: 700;
}

/*================================================
Flight Section
==================================================*/

.flight-deals {
    padding: 100px 0;
}

.section-tab .nav-tabs {
    border-bottom: none;
}

.section-tab.style-two .nav-tabs .nav-item {
    margin-right: 5px;
    margin-bottom: 5px;
}

.section-tab .nav-tabs .nav-link {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 8px 12px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 500;
}

.section-tab.style-two .nav-tabs .nav-link {
    padding: 5px 45px;
    color: #000;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px rgb(82 85 90 / 10%);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgb(82 85 90 / 10%);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 600;
}

.section-tab .nav-tabs .nav-link.active {
    color: #287dfa;
    background-color: #fff;
}

.section-tab.style-two .nav-tabs .nav-link.active {
    color: #fff;
    background-color: var(--thm-primary);
}

.section-tab.style-two .nav-tabs .nav-item {
    margin-right: 5px;
    margin-bottom: 5px;
}

.flight-deal-card {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px rgb(82 85 90 / 10%);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgb(82 85 90 / 10%);
    padding: 25px;
    position: relative;
    border: 1px solid transparent;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 30px;
}

.flight-deal-card:hover {
    box-shadow: -3.285px 20.741px 51px 0px rgb(0 0 47 / 20%);
}

.flight-deal-card .deal-title img {
    width: 25px;
    margin-right: 12px;
}

.flight-deal-card .flight-deal-title {
    font-size: 18px;
    color: #0d233e;
}

.flight-deal-card .flight-deal-title a {
    color: #0d233e;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.flight-deal-card .flight-deal-meta {
    font-size: 14px;
    padding-left: 36px;
    font-weight: 500;
}

.flight-deal-card .deal-action-box {
    padding-top: 10px;
}

.flight-deal-card .price-box {
    font-size: 15px;
}

.flight-deal-card .price-box .flight-price-num {
    color: #0d233e;
    font-size: 17px;
    font-weight: 700;
}

.btn-text {
    color: #0d233e;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 14px;
    height: 30px;
    min-width: 30px;
    text-align: center;
    background-color: #fff;
    transition: 0.3s all ease-in-out;
    border: 1px solid #ccc;
    border-radius: 50px;
    padding: 0 10px;
    line-height: 30px;
}

.btn-text:hover {
    color: #fff;
    background-color: var(--thm-primary);
    border: 1px solid var(--thm-primary);
}

.accordion.b-none {
    border: none;
}

.accordion.b-none .card.b-none {
    border: none;
}

.accordion.b-none .card-header {
    border: none;
    border-bottom: 1px solid #e2e6ea;
    padding: 0;
}

.accordion.b-none .btn-link > span {
    font-size: 15px;
    font-weight: 400;
    margin-right: 0;
    background: #e8ecf1;
    padding: 6px 15px;
    border-radius: 24px;
}

.accordion.b-none .card-body {
    font-size: 15px;
}

/*================================================
Booking Widget
==================================================*/

.widget-booking,
.author-content,
.form-fields {
    border-radius: 10px;
    margin-bottom: 0;
    list-style-type: none;
}

.widget_book .default-btn,
.widget_book .default-btn-two {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
}

.widget_book .default-btn-three {
    background-color: rgba(128, 137, 150, 0.1);
    color: #0d233e;
    font-size: 16px;
    padding: 0.375rem 0.75rem;
    border-radius: 25px;
}

.sidebar-book-title-wrap h3 {
    font-size: 15px;
    font-weight: 700;
    color: #0d233e;
    text-transform: uppercase;
    margin-bottom: 14px;
}

.sidebar-book-title-wrap .text-value {
    color: var(--thm-primary);
    font-size: 28px;
    font-weight: 800;
    float: right;
}

.sidebar-book .text-from {
    float: left;
}

.submit-form-action .form-group {
    position: relative;
}

.submit-form-action label {
    color: #39325a;
    font-size: 15px;
    font-weight: 600;
}

.submit-form-action .form-group .form-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    color: #5d646d;
    font-size: 18px;
    z-index: 1;
}

.submit-form-action .form-group .search-btn,
.submit-form-action .form-group .submit-btn {
    position: absolute;
    top: 7px;
    right: 8px;
}

.submit-form-action .form-group .search-btn {
    background-color: transparent;
    border: none;
    padding: 3px 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    color: #0d233e;
}

.submit-form-action .form-group .search-btn:hover {
    color: #287dfa;
}

.submit-form-action .form-control {
    height: auto;
    line-height: inherit;
    padding: 10px 15px 10px 40px;
    font-size: 13px;
    color: #0d233e;
    border-color: rgba(128, 137, 150, 0.2);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #fff;
}

.submit-form-action .form-control:focus {
    border-color: #287dfa;
}

.submit-form-action .message-control {
    height: 130px;
}

.qty-box label {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
}

.qty-box label span {
    display: block;
    font-size: 13px;
    color: #5d646d;
    font-weight: 400;
}

.qty-inc,
.qty-dec {
    width: 28px;
    height: 28px;
    line-height: 29px;
    font-size: 12px;
    background-color: rgba(128, 137, 150, 0.08);
    color: #0d233e;
    display: inline-block;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.qty-inc:hover,
.qty-dec:hover {
    background-color: var(--thm-primary);
    color: #fff;
}

.qty-btn input {
    width: 35px;
    border: none;
    text-align: center;
    color: #0d233e;
    font-weight: 500;
    background-color: transparent;
}

.sidebar-widget-item.qty {
    border: 1px solid #d2e0e0;
    margin-bottom: 5px;
    background: #fff;
}

.qty-box {
    padding: 10px 10px 10px 15px;
}

.qty-box:nth-child(2) {
    background: #f1f8fb;
}

.before-price {
    text-decoration: line-through;
    color: #5d646d !important;
}

.default-btn {
    color: #fff;
    width: 100%;
    height: 50px;
    border: none;
    font-weight: 700;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #ff5520;
    position: relative;
    display: block;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.default-btn:hover {
    background-color: #000;
    color: #fff;
}

.sidebar-book-title-wrap {
    line-height: 60px;
}

.daterangepicker .drp-buttons .btn {
    line-height: 40px;
    border-radius: 50px;
}

/*================================================
Contact Page
==================================================*/

.contact-us-area {
    background-image: url(../img/contact-bg-1.jpg);
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 100px 0;
}

.contact-us-wrapper {
    background: #fff;
    position: relative;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
}

.contact-us-inner {
    text-align: center;
    padding: 40px 30px;
    border-right: 1px solid #eee;
}

.contact-us-inner:nth-last-child(4) {
    border-right: none;
}

.contact-us-inner h5 {
    font-size: 22px;
    font-weight: 700;
    margin: 30px 0 20px;
}

.contact-us-inner a {
    text-transform: uppercase;
    color: var(--thm-primary);
    font-size: 17px;
    font-weight: 500;
    margin: 20px 0 10px;
}

#contact-us .info-i {
    position: relative;
    z-index: 1;
}

#contact-us .info-i i:before {
    font-size: 50px;
    color: var(--thm-primary);
}

#contact-us .contact-form .main-btn {
    margin-top: 30px;
}

.contact-section .section-title h2 {
    color: #fff;
}

.contact-form .main-btn {
    border-radius: 0;
    border: none;
    margin: 40px 0;
}

.booking-cart-page-area {
    padding: 100px 0;
}

/*================================================
Cart
==================================================*/

.cart-table-left h3,
.order-summury-box h3 {
    font-size: 22px;
    color: #000;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-weight: 700;
    line-height: 45px;
}

.cart-table-left thead {
    background: #f4f4f5 none repeat scroll 0 0;
    border: medium none;
}

.cart-table-left thead th {
    border: medium none;
    color: #001238;
}

.cart-preview {
    width: 115px;
    height: 100px;
    text-align: center;
}

.cart-preview img {
    width: 100%;
    height: 100%;
}

.table th,
.table td {
    vertical-align: baseline;
    border-top: 0px;
    border-bottom: 1px solid #f0f0ff;
}

.cart-table-left table td p {
    color: #001238;
    text-transform: capitalize;
}

.cart-quantity > input {
    border: 1px solid #ddd;
    padding: 5px;
    width: 70px;
}

.cart-close {
    text-align: center;
}

.cart-total {
    font-weight: 500;
}

.cart-close a {
    color: #333;
    width: 35px;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.cart-clear a {
    display: inline-block;
    font-weight: 500;
    margin-right: 15px;
}

.cart-clear {
    margin-top: 20px;
}

.cart-clear a:last-child {
    margin-right: 1px;
}

.cart-clear a:hover {
    color: #ec3323;
}

.order-summury-box {
    padding: 30px;
    background: #f4f4f5 none repeat scroll 0 0;
}

.order-summury-box table {
    width: 100%;
}

.order-summury-box table tr {
    border-bottom: 1px solid #ddd;
}

.order-summury-box table tr:last-child {
    border-bottom: 0px solid #ddd;
}

.order-summury-box table td {
    padding: 10px 0;
    color: #001238;
}

.checkout-action {
    text-align: right;
    margin-top: 30px;
}

.checkout-action a.payment-btn {
    color: #ec3323;
    margin: 0;
}

.checkout-action a.payment-btn:hover {
    color: #fff;
}

/*================================================
Booking / Payment
==================================================*/

.booking-section {
    padding: 100px 0 80px;
    background: #fbfbfb none repeat scroll 0 0;
}

.booking-form-section {
    padding: 80px 0;
}

.booking-tag {
    display: inline-block;
    padding: 5px 15px;
    line-height: 20px;
    text-transform: uppercase;
    background: #ec3323;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border-radius: 3px;
    margin-bottom: 5px;
}

.activity-booking-right h3 {
    font-size: 32px;
    color: #000;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-weight: 700;
    line-height: 45px;
}

.activity-booking-right h4 {
    color: #020202;
    font-size: 18px;
    text-transform: capitalize;
    margin-top: 0px;
    display: inline-block;
}

.activity-booking-right h4 span {
    text-transform: capitalize;
    color: #ec3323;
    font-size: 16px;
}

.activity-booking-image {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 5%);
}

.price-activity {
    display: inline-block;
    margin-right: 15px;
}

.activity-rating {
    display: inline-block;
}

.activity-rating ul {
    display: inline-block;
}

.activity-rating ul li {
    display: inline-block;
    margin-right: 1px;
    color: #ffcd00;
}

.activity-rating p {
    display: inline-block;
    margin-left: 5px;
    color: #001238;
    text-transform: capitalize;
}

.activity-features ul {
    width: 32%;
    float: left;
    padding: 0;
}

.activity-features ul li {
    list-style-type: none;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0;
    line-height: 28px;
}

.activity-features ul li i {
    margin-right: 5px;
    color: #ec3323;
    width: 20px;
}

.single-booking h3,
.booking-right h3 {
    font-size: 22px;
    color: #000;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-weight: 700;
    line-height: 28px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0ff;
}

.single-booking form label {
    display: block;
    color: #001238;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
}

.single-booking form p {
    margin-top: 15px;
}

.single-booking form p input,
.single-booking form p textarea,
.single-booking form .nice-select {
    height: 45px;
    border: none;
    padding-left: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #efeff0;
    width: 100%;
    font-size: 16px;
    line-height: 45px;
}

.single-booking form p input:focus {
    border: 2px solid #ec3323;
}

.single-booking {
    margin-bottom: 30px;
}

.single-booking:last-child {
    margin-bottom: 0px;
}

.payment-options .payment {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.payment-options .payment input[type="radio"] {
    position: absolute;
    visibility: hidden;
}

.payment-options .payment label {
    display: inline-block;
    position: relative;
    padding: 0 0 0 30px;
    margin: 10px auto;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    color: #020202;
    font-weight: 500;
}

.payment-options .payment .check {
    display: block;
    position: absolute;
    border: 3px solid #aaaaaa;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 14px;
    left: 0;
    z-index: 5;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;
}

.payment-options .payment .check:before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

.payment img {
    float: right;
    margin-top: 15px;
}

.action-btn {
    text-align: right;
}

.action-btn a.payment-btn {
    margin: 30px 0 0 0;
}

.action-btn a.payment-btn:hover {
    color: #fff;
}

.addReadMore.showlesscontent .SecSec,
.addReadMore.showlesscontent .readLess {
    display: none;
}

.addReadMore.showmorecontent .readMore {
    display: none;
}

.addReadMore .readMore,
.addReadMore .readLess {
    font-weight: bold;
    margin-left: 2px;
    color: var(--thm-primary);
    cursor: pointer;
}

.addReadMoreWrapTxt.showmorecontent .SecSec,
.addReadMoreWrapTxt.showmorecontent .readLess {
    display: block;
}

/* OWL Carousel Partners */
.owl-carousel-partners .owl-item {
    background-color: #f5f5f5;
}

.owl-carousel-partners .owl-item img {
    width: 160px !important;
}

.owl-carousel-partners .owl-dots {
    margin-top: 20px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-carousel-partners .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
}

.owl-carousel-partners .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.owl-carousel-partners .owl-dots .owl-dot.active span,
.owl-carousel-partners .owl-dots .owl-dot:hover span {
    background: #869791;
}

#notificationCard {
    width: 350px;
    position: fixed;
    bottom: 15px;
    right: 10px;
    display: none;
    transition: all 0.8s ease-out;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1000;
}

#notificationCard.active {
    display: block;
    transform: translateY(0);
}

#notificationCard .text {
    font-size: 14px;
}

#notificationCard .time {
    font-size: 12px;
}

#notificationCard .btn-cancel {
    position: relative;
    margin-top: -35px;
    float: right;
}

.swal2-popup .swal2-styled{
    margin-top: 0 !important;
}

.swal2-styled.swal2-confirm{
    color: #000 !important;
    background: none !important;
    border: 2px solid #000 !important;
}

.country-card {
    border-radius: 0.8rem !important;
    overflow: hidden;
}

.country-card .main-image {
    border-radius: 0.8rem !important;
    object-fit: contain;
    transition: transform 0.2s;
}

.country-card .main-image:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.country-card .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    left: 12px;
}

.country-card .country-name {
    background-color: var(--thm-primary);
    color: #fff;
    display: inline-block;
    position: absolute;
    bottom: 12px;
    left: 12px;
    padding: 2px 10px;
    border-radius: 10px;
    font-size: 14px;
}